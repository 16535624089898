import styled from 'styled-components';
import {
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  BarChart,
} from 'recharts';
import { CHART_COLORS } from '@/constants/report';
import {
  countToPercent,
  getKoreanDayValue,
  getTimeRange,
  getTimeRangeShortly,
} from '@/utils/report';
import { Fragment } from 'react/jsx-runtime';
import { ReportDayTime } from '@/types/report';

type Props = {
  data?: ReportDayTime;
};

function Time({ data = {} }: Props) {
  const details: { dayValue: string; hour: number; count: number }[] =
    Object.entries(data).flatMap(([day, hoursData]) => {
      return Object.entries(hoursData).flatMap(([hour, count]) => {
        return {
          dayValue: day,
          hour: Number(hour),
          count: Number(count),
        };
      });
    });

  const days = ['MON', 'TUE', 'WED', 'THU', 'FRI', 'SAT', 'SUN'];
  const openHours = [...new Set(details.map((item) => item.hour))];

  let totalCount = 0;
  let totalOfDay = days.reduce((acc, day) => {
    (acc as any)[day] = 0;
    return acc;
  }, {});

  const timeList = openHours.map((hour, index) => ({
    value: hour,
    label: getTimeRange({
      time: hour,
      isLast: !!(index === openHours.length - 1),
    }),
  }));

  const chartData = days.map((day) => {
    const data = details
      .filter((item) => item.dayValue === day)
      .reduce((acc, { hour, count }) => {
        (acc as any)[hour] = count;
        return acc;
      }, {});

    return {
      name: getKoreanDayValue(day),
      ...data,
    };
  });

  const percentPerDay = days.map((day) => {
    const total = details
      .filter((item) => item.dayValue === day)
      .reduce((acc, item) => acc + item.count, 0);
    (totalOfDay as any)[day] = total;
    totalCount += total;

    return total;
  });

  const tableRow = timeList.map(({ value }, index) => {
    let ratioSum = 0;
    const valueOfDay = days.reduce((acc, day, index) => {
      const data = details.find(
        (item) => item.dayValue === day && item.hour === value,
      );
      const ratio = countToPercent(
        (data as any)?.count || 0,
        percentPerDay[index],
      );
      ratioSum += ratio;
      (acc as any)[day] = ratio;

      return acc;
    }, {});

    return {
      time: getTimeRange({
        time: value,
        isLast: index === timeList.length - 1,
      }),
      ...valueOfDay,
      avg: Number((ratioSum / days.length).toFixed(2)),
    };
  });

  const sortedOnDay = Object.entries(totalOfDay)
    .sort((a, b) => (b[1] as any) - (a[1] as any))
    .map(([day, totalCount]) => ({ day, totalCount }));

  const sortedOnTime = tableRow
    .map(({ avg, time }) => {
      const hour = Number(time.split('~')[0].split(':')[0]);
      return { time: hour, avg };
    })
    .sort((a, b) => b.avg - a.avg)
    .slice(0, 7);

  return (
    <Section>
      <TableWrapper>
        <TableTitle>요일 · 시간별</TableTitle>
        <ChartWrapper>
          <BarChart
            barGap={0}
            width={690}
            height={420}
            data={chartData}
            margin={{
              top: 15,
              right: 5,
              left: 10,
              bottom: 15,
            }}
          >
            <CartesianGrid stroke='#f1f1f1' vertical={false} />
            <XAxis dataKey='name' fontSize={12} />
            <YAxis fontSize={12} />
            <Tooltip />
            <Legend
              wrapperStyle={{
                left: '50%',
                transform: 'translateX(-50%)',
                fontSize: 12,
              }}
              formatter={(value) => {
                return timeList.find((item) => item.value === value)?.label;
              }}
            />
            {timeList.map(({ value }, index) => (
              <Bar
                key={`time-bar-${value}`}
                dataKey={value}
                fill={CHART_COLORS[index]}
                activeBar={false}
                barSize={8}
              />
            ))}
          </BarChart>
        </ChartWrapper>
        <TableList>
          <table>
            <thead>
              <tr>
                <th></th>
                {days.map((day) => (
                  <th key={day}>{getKoreanDayValue(day)}</th>
                ))}
                <th>평균</th>
              </tr>
            </thead>
            <tbody>
              {tableRow.map((data, index) => (
                <tr key={index}>
                  <td>{data.time}</td>
                  {days.map((day) => (
                    <td key={day} style={{ width: '72px' }}>
                      {(data as any)[day]}%
                    </td>
                  ))}
                  <td style={{ width: '72px' }}>{(data as any).avg}%</td>
                </tr>
              ))}
              <tr>
                <th rowSpan={2}>합계</th>
                {days.map((day) => (
                  <td key={day}>{(totalOfDay as any)[day]}건</td>
                ))}
                <td>{totalCount}건</td>
              </tr>
              <tr>
                <td>100%</td>
                <td>100%</td>
                <td>100%</td>
                <td>100%</td>
                <td>100%</td>
                <td>100%</td>
                <td>100%</td>
                <td>100%</td>
              </tr>
            </tbody>
          </table>
        </TableList>
      </TableWrapper>
      <TableWrapper>
        <TableTitle>ANALYSIS</TableTitle>
        <AnalysisTableList>
          <table>
            <tr style={{ borderTop: '1px solid lightgray' }}>
              <th rowSpan={2} style={{ borderRight: '1px solid lightgray' }}>
                요일별 순위
              </th>
              {sortedOnDay.map(({ day }, index) => (
                <Fragment key={`day-${day}`}>
                  <td style={{ width: '60px' }}>{getKoreanDayValue(day)}</td>
                  {index < 6 && (
                    <td rowSpan={2} style={{ width: '20px' }}>
                      &gt;
                    </td>
                  )}
                </Fragment>
              ))}
            </tr>
            <tr style={{ borderBottom: '1px solid lightgray' }}>
              {sortedOnDay.map(({ totalCount }, index) => (
                <td key={`day-${totalCount}-${index}`}>{totalCount}</td>
              ))}
            </tr>
            <tr style={{ borderTop: '1px solid lightgray' }}>
              <th rowSpan={2} style={{ borderRight: '1px solid lightgray' }}>
                시간별 순위
              </th>
              {sortedOnTime.map(({ time }, index) => (
                <Fragment key={`day-${time}`}>
                  <td style={{ width: '60px' }}>
                    {getTimeRangeShortly(Number(time))}
                  </td>
                  {index < sortedOnTime.length - 1 && (
                    <td rowSpan={2} style={{ width: '20px' }}>
                      &gt;
                    </td>
                  )}
                </Fragment>
              ))}
            </tr>
            <tr style={{ borderBottom: '1px solid lightgray' }}>
              {sortedOnTime.map(({ avg }, index) => (
                <td key={`time-${avg}-${index}`}>{avg}%</td>
              ))}
            </tr>
          </table>
        </AnalysisTableList>
        <Message>
          &#8251; 해당 통계자료는 당월 요일별 평균값을 나타낸 수치입니다.
        </Message>
      </TableWrapper>
    </Section>
  );
}

const Section = styled.section`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 24px;
`;

const TableWrapper = styled.div`
  width: 100%;
`;

const TableTitle = styled.h2`
  display: inline-flex;
  padding: 0 40px;
  align-items: center;
  justify-content: center;
  height: 20px;
  font-size: 14px;
  color: #404040;
  background-color: #bfbfbf;

  span {
    font-size: 12px;
    font-weight: 500;
    margin-left: 4px;
  }
`;

const ChartWrapper = styled.div`
  width: 690px;
  height: 420px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #ffffff;
  border: 1px solid lightgray;
`;

const TableList = styled.div`
  width: 100%;
  display: flex;
  text-align: center;

  table {
    border-collapse: collapse;
    width: 100%;
  }

  tr {
    > :is(th, td):first-child {
      border-left: 0;
    }
    > :is(th, td):last-child {
      border-right: 0;
    }
  }

  th,
  td {
    font-size: 14px;
    height: 22px;
    border: 1px solid lightgray;
  }
`;

const AnalysisTableList = styled.div`
  width: 100%;
  display: flex;
  text-align: center;

  table {
    border-collapse: collapse;
    width: 100%;
  }

  th,
  td {
    font-size: 14px;
    height: 22px;
  }
`;

const Message = styled.p`
  font-weight: 500;
  font-size: 14px;
  margin-top: 4px;
  color: #5f6165;
`;

export default Time;
