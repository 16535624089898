import styled from 'styled-components';
import ICON_SORT_ALT from '@/assets/medical/icon_sort_alt.png';
import ICON_RADIO from '@/assets/medical/icon_radio.png';
import ICON_RADIO_OK from '@/assets/medical/icon_radio_ok.png';
import { useEffect } from 'react';

type Props = {
  onChange?: (v: any) => void;
  value?: string;
  onChangeSorting: () => void;
  hasRefund?: boolean;
};

function FeedbackSortingFilter({
  onChange,
  value,
  onChangeSorting,
  hasRefund = true,
}: Props) {
  const changeSorting = (value: string) => {
    onChange && onChange(value);
  };

  const switchSorting = () => {
    onChangeSorting();
  };

  useEffect(() => {
    if (!hasRefund) {
      onChange && onChange('SIGNUP_DATE');
    }
  }, [hasRefund]);

  return (
    <Wrapper>
      <FilterButton type='button' onClick={switchSorting}>
        <IconSort src={ICON_SORT_ALT} alt='' width={24} height={24} />
      </FilterButton>
      <RadioGroup>
        <RadioWrapper isOk={value === 'SIGNUP_DATE'}>
          {value === 'SIGNUP_DATE' ? (
            <img src={ICON_RADIO_OK} alt='' width={24} height={24} />
          ) : (
            <img src={ICON_RADIO} alt='' width={24} height={24} />
          )}
          <RadioNone
            type='radio'
            name='date_sorting'
            onChange={(e) => changeSorting(e.target.value)}
            value='SIGNUP_DATE'
            checked={value === 'SIGNUP_DATE'}
          />
          가입일
        </RadioWrapper>
        {hasRefund && (
          <RadioWrapper isOk={value === 'LAST_REFUND_DATE'}>
            {value === 'LAST_REFUND_DATE' ? (
              <img src={ICON_RADIO_OK} alt='' width={24} height={24} />
            ) : (
              <img src={ICON_RADIO} alt='' width={24} height={24} />
            )}
            <RadioNone
              type='radio'
              name='date_sorting'
              value='LAST_REFUND_DATE'
              onChange={(e) => changeSorting(e.target.value)}
              checked={value === 'LAST_REFUND_DATE'}
            />
            최종 환급일
          </RadioWrapper>
        )}
        <RadioWrapper isOk={value === 'FEEDBACK_DUE_DATE'}>
          {value === 'FEEDBACK_DUE_DATE' ? (
            <img src={ICON_RADIO_OK} alt='' width={24} height={24} />
          ) : (
            <img src={ICON_RADIO} alt='' width={24} height={24} />
          )}
          <RadioNone
            type='radio'
            name='date_sorting'
            value='FEEDBACK_DUE_DATE'
            onChange={(e) => changeSorting(e.target.value)}
            checked={value === 'FEEDBACK_DUE_DATE'}
          />
          조치 만료일
        </RadioWrapper>
      </RadioGroup>
    </Wrapper>
  );
}

export default FeedbackSortingFilter;

type RadioStyle = {
  isOk: boolean;
};

const Wrapper = styled.div`
  display: inline-flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #cbccce;
  border-radius: 4px;
  padding: 14px 16px;
  margin-bottom: 16px;
`;

const FilterButton = styled.button`
  display: block;
  margin-right: 16px;
  padding: 0;
`;

const IconSort = styled.img`
  display: block;
`;

const RadioNone = styled.input`
  display: none;
`;

const RadioWrapper = styled.label<RadioStyle>`
  display: flex;
  align-items: center;
  gap: 6px;
  font-weight: 600;
  color: ${({ isOk }) => (isOk ? '#246CF6' : '#80848a')};
  cursor: pointer;
`;

const RadioGroup = styled.div`
  display: flex;
  gap: 24px;
`;
