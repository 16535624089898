import Portal from '@/components/common/Portal';
import React from 'react';
import styled from 'styled-components';

type Props = {
  onClose: () => void;
};

function SuccessConfirmModal({ onClose }: Props) {
  return (
    <Portal isOpaqueBackdrop zIndex={150}>
      <Popover>
        <Header>
          <Title>출국 확인 완료</Title>
        </Header>
        <Content>항공권 확인이 처리되었습니다.</Content>
        <Button onClick={onClose}>확인</Button>
      </Popover>
    </Portal>
  );
}

const Popover = styled.aside`
  display: flex;
  flex-direction: column;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 32px;
  width: 400px;
  background-color: #ffffff;
  border-radius: 16px;
  box-shadow: 0px 0px 30px 0px #00000014;
  z-index: 150;
`;

const Header = styled.header`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 12px;
`;

const Title = styled.h3`
  font-size: 21px;
  font-weight: 700;
  color: #030303;
`;

const Button = styled.button`
  width: 100%;
  height: 52px;
  border-radius: 8px;
  font-size: 16px;
  font-weight: 600;
  background-color: #246cf6;
  color: #ffffff;
`;

const Content = styled.p`
  font-size: 14px;
  font-weight: 500;
  color: #5f6165;
  margin-bottom: 24px;
`;

export default SuccessConfirmModal;
