import { useMutation } from 'react-query';

import { deleteKioskTransaction } from '@/api/kiosk';

export const useKioskTransactionDelete = () => {
  return useMutation({
    mutationKey: ['kioskTransactionDelete'],
    mutationFn: deleteKioskTransaction,
    retry: false,
    onSuccess: () => {
      alert('내역이 삭제되었습니다.');
    },
    onError: () => {
      alert('내역 삭제가 실패하였습니다.');
    },
  });
};
