import React, { ChangeEvent, FormEventHandler, useState } from 'react';
import {
  Card,
  FormControl,
  FormControlLabel,
  makeStyles,
  Radio,
  RadioGroup,
} from '@material-ui/core';

const styles = makeStyles({
  root: {
    padding: '8px 20px',
    position: 'absolute',
    top: '280px',
    boxShadow: '2px 3px 10px lightgray',
  },
});
interface IProps {
  valueArray: Array<{ value: any; label: string }>;
  defaultValue: string;
  type: string;
  onFilter: FormEventHandler<HTMLFieldSetElement> | undefined;
  style?: any;
}
function CheckboxForm({
  valueArray,
  defaultValue,
  type,
  onFilter,
  style,
}: IProps) {
  const [value, setValue] = useState(defaultValue);
  const classes = styles();

  const radioValueChange = (e: ChangeEvent<HTMLInputElement>) => {
    setValue(e.target.value);
  };

  return (
    <Card variant='outlined' style={style} className={classes.root}>
      <FormControl component='fieldset' onChange={onFilter}>
        <RadioGroup
          name={type}
          value={value}
          onChange={radioValueChange}
          row={true}
        >
          {valueArray.map((item) => (
            <FormControlLabel
              key={item.label}
              value={String(item.value)}
              control={<Radio disableRipple={true} />}
              label={item.label}
            />
          ))}
        </RadioGroup>
      </FormControl>
    </Card>
  );
}

export default CheckboxForm;
