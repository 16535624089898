import React, { useCallback } from 'react';
import { useQuery } from 'react-query';

import { getHospitalStatusDetail } from '@/api/medical';
import DetailContent from '@/components/medical/hospitalStatus/DetailContent';
import Modal from '@/components/common/Modal';

function DetailModal({ onClose, selected }) {
  const { hospitalIndex, hospitalStatus, createdDate } = selected;

  const { isLoading, data, isError, isSuccess } = useQuery(
    ['hospitalDetail', hospitalIndex],
    () => getHospitalStatusDetail(hospitalIndex),
  );

  const onModalClose = useCallback(() => {
    onClose('DETAIL');
  }, [onClose]);

  return (
    <Modal
      error={
        isError && '가맹 상세보기 조회에 실패했습니다. 관리자에게 문의해주세요.'
      }
      onClose={onModalClose}
      isLoading={isLoading}
    >
      {isSuccess && data.detailInfo && data.uploadInfo && (
        <DetailContent
          detailInfo={data.detailInfo}
          uploadInfo={data.uploadInfo}
          hospitalIndex={hospitalIndex}
          hospitalStatus={hospitalStatus}
          createdDate={createdDate}
        />
      )}
    </Modal>
  );
}

export default React.memo(DetailModal);
