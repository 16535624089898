import { useState } from 'react';
import { Container, TableBody } from '@material-ui/core';
import { useQuery } from 'react-query';
import styled from 'styled-components';

import {
  getKioskFranchiseeKeywords,
  getKioskFranchiseeStatus,
} from '@/api/kiosk';
import useKioskReducer from '@/hooks/kiosk/useKioskReducer';
import {
  GetKioskFranchiseeStatusResponse,
  GetKioskStatusPayload,
} from '@/types/api/kiosk';

import DataTable from '@/components/common/DataTable';
import PageIndicator from '@/components/common/PageIndicator';
import { kioskFranchiseeStatusData } from '@/constants/kiosk';
import LoadingView from '@/components/common/LoadingView';
import KioskTitle from '@/components/kiosk/kioskStatus/KioskTitle';
import KioskFranchiseeList from '@/components/kiosk/kioskStatus/KioskFranchiseeList';
import Search from '@/components/common/Search';
import ViewDateSelection from '@/components/common/ViewDataSelection';
import { useLocation } from 'react-router-dom';
import Pagination from '@/components/common/Pagination';

function KtpKioskStatus() {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const currentPage = searchParams.get('page') || '0';
  const keyword = searchParams.get('search') || '';
  const size = searchParams.get('size') || '10';
  const [pageList, setPageList] = useState<Array<number>>([0]);
  const [totalPage, setTotalPage] = useState<number>(0);
  const [isFirstPage, setIsFirstPage] = useState(false);
  const [isLastPage, setIsLastPage] = useState(false);
  const { columns } = kioskFranchiseeStatusData;

  const [dispatch] = useKioskReducer();

  // 키워드 검색
  const { data: keywords } = useQuery(
    'kioskFranchiseeKeywords',
    getKioskFranchiseeKeywords,
    {
      retry: false,
    },
  );

  const { data: ktpKioskData, isLoading } = useQuery<
    GetKioskFranchiseeStatusResponse,
    GetKioskStatusPayload
  >(
    ['kioskFranchiseeStatus', currentPage, keyword, size],
    () =>
      getKioskFranchiseeStatus({
        page: currentPage,
        size: size,
        keyword,
      }),
    {
      onSuccess: ({ totalPages, first, last }) => {
        setTotalPage(totalPages);
        setIsFirstPage(first);
        setIsLastPage(last);
      },
      retry: false,
    },
  );

  return (
    <StyledContainer maxWidth='xl'>
      {isLoading ? (
        <LoadingView />
      ) : (
        <>
          <KioskTitle title='가맹점 현황' />
          {keywords && <Search data={keywords} />}
          <Flex>
            <ViewDateSelection initialSize={size} />
          </Flex>
          <DataTable
            columns={columns}
            dispatch={dispatch}
            contentComponent={
              <TableBody>
                {ktpKioskData?.content.map((item, idx) => (
                  <KioskFranchiseeList
                    key={idx}
                    idx={idx}
                    data={item}
                    size={ktpKioskData.size}
                    number={ktpKioskData.number}
                    totalElements={ktpKioskData.totalElements}
                  />
                ))}
              </TableBody>
            }
          />
          <BottomSection>
            {totalPage > 0 && (
              <Pagination
                initialPage={currentPage}
                totalPage={totalPage}
                isLastPage={isLastPage}
                isFirstPage={isFirstPage}
                displaySize={5}
              />
            )}
          </BottomSection>
        </>
      )}
    </StyledContainer>
  );
}

const StyledContainer = styled(Container)`
  padding-top: 16px;
`;
const BottomSection = styled.div`
  margin: 20px 0;
`;
const Flex = styled.div`
  display: flex;
  justify-content: flex-end;
  margin: 12px 0;
`;

export default KtpKioskStatus;
