import { useRef, useState } from 'react';
import styled from 'styled-components';
import StatusLabel from './StatusLabel';
import ArrowDropDown from '../../assets/common/arrow_drop_down.png';
import Portal from '@/components/common/Portal';

type Props = {
  isActive: boolean;
  options: any[];
  selectedOption: any;
  setSelectedOption: (value: any) => void;
};

function AlertTalkTemplate({
  isActive,
  options,
  selectedOption,
  setSelectedOption,
}: Props) {
  const containerRef = useRef(null);
  const popoverRef = useRef(null);
  const [isOpenPopover, setIsOpenPopover] = useState(false);

  const onTogglePopover = () => {
    return setIsOpenPopover((prev) => !prev);
  };

  return (
    <Container>
      <Field ref={containerRef} disabled={!isActive}>
        <Label>알림톡 템플릿</Label>
        <Selector onClick={onTogglePopover} disabled={!isActive}>
          {selectedOption.name && (
            <StatusLabel width={200}>{selectedOption.name}</StatusLabel>
          )}
          <ArrowImage
            src={ArrowDropDown}
            width='24px'
            height='24px'
            alt={`알림톡 템플릿 옵션 ${isOpenPopover ? '닫기' : '열기'}`}
            isOpenPopover={isOpenPopover}
          />
        </Selector>
      </Field>

      {isOpenPopover && options.length > 0 && (
        <Portal
          container={containerRef}
          onClickBackdrop={() => setIsOpenPopover(false)}
        >
          <Popover ref={popoverRef} position='bottom'>
            {options.map(({ code, name, contents }) => (
              <Radio key={code} tabIndex={0}>
                <RadioInput
                  type='radio'
                  onClick={() => {
                    setSelectedOption({ code, name });
                    setIsOpenPopover(false);
                  }}
                  value={code}
                  id={`template-${code}`}
                />
                <Label htmlFor={`template-${code}`}>
                  <StatusLabel width={320}>
                    {name} - {contents}
                  </StatusLabel>
                </Label>
              </Radio>
            ))}
          </Popover>
        </Portal>
      )}
    </Container>
  );
}

const Container = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  max-width: 400px;
`;

const Field = styled.div<{ disabled: boolean }>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 100%;
  padding: 16px;
  border-radius: 10px;
  border: 1px solid #e5e6e8;
  ${({ disabled }) => disabled && 'background-color: rgba(239, 239, 239, 0.3);'}
`;

const Selector = styled.button`
  display: flex;
  gap: 16px;
`;

const Label = styled.label`
  color: #3a3b3e;
  font-size: 14px;
  font-weight: 600;
`;

const Popover = styled.aside<{ position: 'top' | 'bottom' }>`
  position: absolute;
  right: 4px;
  display: flex;
  flex-direction: column;
  gap: 12px;
  padding: 16px 24px;
  background-color: #ffffff;
  border-radius: 12px;
  box-shadow: 0px 0px 30px 0px #00000014;
  z-index: 50;

  ${({ position = 'top' }) => `${position}: calc(100% + 4px)`}
`;

const ArrowImage = styled.img<{ isOpenPopover: boolean }>`
  transform: ${({ isOpenPopover }) =>
    `rotate(${isOpenPopover ? '180deg' : '0deg'})`};
  transition: transform 200ms ease-in-out;
`;

const Radio = styled.div`
  position: relative;

  &:focus-within {
    outline: 2px solid #4d90fe;
    outline-offset: 2px;
  }
`;

const RadioInput = styled.input`
  position: absolute;
  opacity: 0;
  width: 100%;
  height: 100%;
  margin: 0;
  cursor: pointer;
`;

export default AlertTalkTemplate;
