import { postQnaAnswer } from '@/api/ktp';
import { postQnaMedicalAnswer } from '@/api/medical';
import useGetRequestPath from '@/hooks/common/useGetRequestPath';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useMutation, useQueryClient } from 'react-query';
import styled from 'styled-components';
import { QNA_MAX_IMAGE_LENGTH } from '@/constants/qna';
import SelectedImage from './SelectedImage';
import { convertHEICToJPEG, resizeFile } from '@/utils/file.util';

type Props = {
  id?: number;
};

function QnaType({ id = 0 }: Props) {
  const [content, setContent] = useState('');
  const [imageFiles, setImageFiles] = useState<File[]>([]);
  const [images, setImages] = useState<string[]>([]);
  const imagesInput = useRef<HTMLInputElement>(null);

  const isMedical = useGetRequestPath(/medical/);
  const queryClient = useQueryClient();

  const getFormData = useCallback(
    async (id: number, content: string) => {
      const requestBlob = new Blob(
        [
          JSON.stringify(
            isMedical
              ? { touristId: id, content }
              : { customerId: id, content },
          ),
        ],
        {
          type: 'application/json',
        },
      );

      const formData = new FormData();
      formData.append('request', requestBlob);
      const resizePromise = imageFiles.map(async (file) => {
        return await resizeFile(file);
      });
      const resizedImageFiles = await Promise.all(resizePromise);

      resizedImageFiles.forEach((file: any) => {
        formData.append('files', file, `${file.name}.jpg`);
      });

      return formData;
    },
    [imageFiles, isMedical],
  );

  const { mutate: sendAnswer } = useMutation(
    async () => {
      const formData = await getFormData(id, content);
      return isMedical
        ? postQnaMedicalAnswer(formData)
        : postQnaAnswer(formData);
    },
    {
      onSuccess: () => {
        setImageFiles([]);
        setImages([]);
        queryClient.invalidateQueries('qnaDetail');
        setContent('');
        alert('문의 답변을 발송하였습니다.');
      },
    },
  );

  const parseImages = (files: File[]) => {
    return Promise.all(
      files.map((file) => {
        return new Promise<string>((resolve) => {
          const reader = new FileReader();
          reader.onload = () => resolve(reader.result as string);
          reader.readAsDataURL(file);
        });
      }),
    );
  };

  const onUploadImage = async () => {
    if (imagesInput.current?.files) {
      const files = imagesInput.current.files;

      const remainingSlots = QNA_MAX_IMAGE_LENGTH - imageFiles.length;

      if (files.length > remainingSlots) {
        alert(
          '최대치만큼 사진을 선택하셨습니다.\n최대 4개까지 선택할 수 있습니다.',
        );
      } else {
        const convertPromise = [...files].map(async (file) => {
          return file.type === 'image/heic'
            ? await convertHEICToJPEG(file)
            : file;
        });
        const convertedImages = await Promise.all(convertPromise);
        setImageFiles((prev) => [...prev, ...convertedImages]);
        const uploadedImages = await parseImages([...convertedImages]);
        setImages((prev) => [...prev, ...uploadedImages]);

        imagesInput.current.files = null;
      }
    }
  };

  const handleDeleteImage = (index: number) => {
    setImageFiles((prev) => prev.filter((_, i) => i !== index));
    setImages((prev) => prev.filter((_, i) => i !== index));
  };

  useEffect(() => {
    const handlePopstate = () => {
      setImages([]);
    };
    window.addEventListener('popstate', handlePopstate);
    return () => {
      window.removeEventListener('popstate', handlePopstate);
    };
  }, []);

  const hasImages = images.length > 0 && imageFiles.length > 0;

  return (
    <Wrapper>
      <Title>문의 유형</Title>
      <ContentsWrapper>
        <TypeWrapper>
          <label htmlFor='normal_qna'>일반 문의</label>
          <InputRadio id='normal_qna' type='radio' defaultChecked />
        </TypeWrapper>
        <TextareaStyle
          name=''
          id=''
          placeholder='문의 답변을 입력해주세요.'
          maxLength={2000}
          value={content}
          onChange={(e) => setContent(e.target.value)}
        />
        <ImageUploadButton htmlFor='image-input'>
          <span>첨부파일 선택</span>
          <ImageInput
            id='image-input'
            type='file'
            accept='.heic, image/*'
            onChange={onUploadImage}
            multiple
            ref={imagesInput}
            disabled={images.length === QNA_MAX_IMAGE_LENGTH}
          />
        </ImageUploadButton>
        <ImageList>
          {hasImages ? (
            images.map((image: string, index: number) => (
              <SelectedImage
                image={image}
                fileName={imageFiles[index].name}
                key={imageFiles[index].name + index.toString()}
                onDelete={() => handleDeleteImage(index)}
              />
            ))
          ) : (
            <NoImageMessage>첨부 파일이 없습니다.</NoImageMessage>
          )}
        </ImageList>
      </ContentsWrapper>
      <SendButton
        type='button'
        onClick={() => sendAnswer()}
        disabled={content.length === 0 && !hasImages}
      >
        발송하기
      </SendButton>
    </Wrapper>
  );
}

export default QnaType;

const Wrapper = styled.section`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 690px;
`;

const Title = styled.h6`
  margin: 0 0 16px;
  color: #000;
  font-size: 18px;
  font-weight: 700;
`;

const ContentsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  height: 100%;
`;

const TypeWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 12px;
  width: 210px;
  padding: 12px;
  color: #246cf6;
  background-color: #f4f8ff;
`;

const InputRadio = styled.input`
  margin-bottom: 3px;
`;

const TextareaStyle = styled.textarea`
  border: 1px solid #e5e6e8;
  border-radius: 20px;
  height: 320px;
  padding: 20px;
  resize: none;
`;

const SendButton = styled.button`
  border-radius: 6px;
  padding: 10px 24px;
  margin: 16px 0 0;
  font-size: 16px;
  color: #fff;
  background: #246cf6;
  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
`;

const ImageUploadButton = styled.label`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 48px;
  background-color: #f5f6f7;
  border-radius: 12px;
  cursor: pointer;
  & > span {
    font-size: 14px;
    line-height: 21px;
    color: #030303;
  }
  &:has(:disabled) {
    background-color: #e5e6e8;
    cursor: default;
    & > span {
      color: #ffffff;
    }
  }
`;

const ImageInput = styled.input`
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0;
`;

const ImageList = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 26px;
  width: 100%;
  height: 140px;
  border-radius: 12px;
  border: 1px solid #e5e6e8;
  padding: 16px;
`;

const NoImageMessage = styled.p`
  font-size: 14px;
  font-weight: 500;
  color: #cbccce;
  width: 100%;
  text-align: center;
`;
