import { getLatestYear, monthData } from '@/utils/common';
import { Dropdown } from '@successmode-ktp/kui';
import styled from 'styled-components';

type Props = {
  year: string;
  month: string;
  onChangeYear: (year: string) => void;
  onChangeMonth: (month: string) => void;
  size?: 'sm' | 'md' | 'lg';
  isFull?: boolean;
};

const DateSelection = ({
  year,
  month,
  onChangeYear,
  onChangeMonth,
  size = 'md',
  isFull = false,
}: Props) => {
  return (
    <Wrapper>
      <Dropdown
        options={getLatestYear(2024, new Date().getFullYear())}
        selectedValue={year}
        setSelectedValue={onChangeYear}
        size={size}
        style={
          isFull
            ? { width: '50%' }
            : {
                width: '100px',
              }
        }
        variant='solid'
      />
      <Dropdown
        options={monthData}
        selectedValue={month}
        setSelectedValue={onChangeMonth}
        size={size}
        style={
          isFull
            ? { width: '50%' }
            : {
                width: '80px',
              }
        }
        variant='solid'
      />
    </Wrapper>
  );
};
export default DateSelection;

const Wrapper = styled.div`
  display: flex;
  gap: 12px;
  margin-bottom: 30px;
`;
