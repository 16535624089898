import { ReactNode } from 'react';
import { useLocation } from 'react-router';
import styled from 'styled-components';
import FlexWrap from '@components/common/FlexWrap';
import Header from '@components/common/Header';
import SideMenu from '@components/common/SideMenu';
import store from '@/store';

interface IProps {
  children: ReactNode;
}
function CommonLayout({ children }: IProps) {
  const { pathname } = useLocation();

  if (pathname.includes('test')) {
    store.dispatch({ type: 'SET_SERVER_TEST' });
  } else {
    store.dispatch({ type: 'SET_SERVER_PROD' });
  }

  return (
    <Container dir='column'>
      {pathname === '/' || pathname.match(/\/ktp\/images/) ? (
        <>{children}</>
      ) : (
        <>
          <Header />
          <SideMenuAndContent>
            <SideMenu />
            <ContentWrap>{children}</ContentWrap>
          </SideMenuAndContent>
        </>
      )}
    </Container>
  );
}
const Container = styled(FlexWrap)`
  position: relative;
  min-width: 1280px;
  height: 100%;
  @media screen and (min-width: 1280px) {
    height: 100vh;
  }
`;
const SideMenuAndContent = styled(FlexWrap)`
  flex: 1;
  width: 100%;
  height: 100%;
  @media screen and (min-width: 1280px) {
    overflow-y: auto;
  }
`;
const ContentWrap = styled.main`
  flex: 1;
  background-color: #f7f9fc;
  padding: 40px;
  height: 100%;
  @media screen and (min-width: 1280px) {
    overflow-y: auto;
  }
`;

export default CommonLayout;
