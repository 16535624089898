import { format } from 'date-fns';
import styled from 'styled-components';

import Typography from '@/components/common/Typography';
import { GetWithdrawalDetailResponse } from '@/types/api/kiosk';
import FlexWrap from '@/components/common/FlexWrap';
import { comma } from '@/utils/kiosk';
import { formatKioskCustomerInfo, formatKioskRefundInfo } from '@/utils/kiosk';
import DeleteModal from '@/components/common/Modal/DeleteModal';

import useModal from '@/hooks/common/useModal';
import { useKioskTransactionDelete } from '@/hooks/kiosk/useKioskTransactionDelete';

type WithdrawalModalContentProps = {
  data: GetWithdrawalDetailResponse;
  kioskWithdrawalIndex: number;
  onClose: () => void;
};

function WithdrawalModalContent({
  data,
  kioskWithdrawalIndex,
  onClose,
}: WithdrawalModalContentProps) {
  const { customerInfo, refundInfo } = data;
  const {
    isOpenModal: isOpenDeleteModal,
    openModal: openDeleteModal,
    closeModal: closeDeleteModal,
  } = useModal();

  const { mutate: deleteMutation } = useKioskTransactionDelete();

  const handleRefundDelete = () => {
    if (kioskWithdrawalIndex) {
      deleteMutation(kioskWithdrawalIndex, {
        onSuccess: () => {
          closeDeleteModal();
          onClose();
        },
      });
    }
  };

  if (customerInfo === null || refundInfo === null) {
    return (
      <Typography fontSize='30px' lineHeight='38px' fontWeight='700'>
        환급번호가 존재하지 않습니다.
      </Typography>
    );
  }
  const formattedCustomerInfo = formatKioskCustomerInfo(customerInfo);
  const formattedRefundInfo = formatKioskRefundInfo(refundInfo);
  return (
    <StyledContainer>
      <Typography fontSize='30px' lineHeight='38px' fontWeight='700'>
        환급 현황 상세보기
      </Typography>
      <Container>
        <Typography
          fontSize='18px'
          lineHeight='26px'
          fontWeight='500'
          margin='24px 0 24px 0'
        >
          고객 정보
        </Typography>
        <Table>
          {formattedCustomerInfo.map((item, idx) => (
            <TableContainer dir='row' key={idx} width='50%'>
              <TableHead>{item.label ? item.label : ''}</TableHead>
              <TableContent>
                {typeof item.option?.value === 'number'
                  ? comma(item.option?.value) + '원'
                  : item.option?.value}
              </TableContent>
            </TableContainer>
          ))}
        </Table>
        <>
          <Typography
            fontSize='18px'
            lineHeight='26px'
            fontWeight='500'
            margin='24px 0 24px 0'
          >
            환급 정보
          </Typography>
          <Table dir='column'>
            <ServiceInfoWrapper>
              <TableContainer dir='column'>
                {formattedRefundInfo.map((items, itemsIdx) => (
                  <TableContainer key={itemsIdx}>
                    {items.map(
                      ({ label, option: { name, value } }, idx: number) => (
                        <TableContainer dir='column' key={idx}>
                          {itemsIdx === 0 && <TableHead>{label}</TableHead>}
                          <TableContent>
                            {typeof value === 'number'
                              ? comma(value) + '원'
                              : name === 'createdDate'
                              ? format(new Date(value), 'yyyy-MM-dd HH:mm:ss')
                              : value}
                          </TableContent>
                        </TableContainer>
                      ),
                    )}
                  </TableContainer>
                ))}
              </TableContainer>
            </ServiceInfoWrapper>
          </Table>
        </>
        {/* 출금내역 완전 삭제 */}
        <FlexWrap justifyContent='flex-end' margin='0 0 32px 0'>
          <DeleteButton onClick={openDeleteModal}>삭제하기</DeleteButton>
        </FlexWrap>
        {isOpenDeleteModal && (
          <DeleteModal
            title='출금 건 삭제'
            desc={`삭제를 원하실 경우 취소 코드를 입력해주세요.\n삭제된 출금내역은 복구할 수 없습니다.`}
            isOpen={isOpenDeleteModal}
            onCancel={closeDeleteModal}
            onDelete={handleRefundDelete}
          />
        )}
      </Container>
    </StyledContainer>
  );
}

const StyledContainer = styled.div`
  width: 100%;
`;

const Container = styled.div`
  margin-top: 20px;
`;
const Table = styled(FlexWrap)`
  width: 100%;
  align-items: center;
  margin-bottom: 36px;
  flex-wrap: wrap;
  border-top: 1px solid #2f2f2f;
  border-right: 1px solid #2f2f2f;
`;
const TableContainer = styled(FlexWrap)<{ width?: string }>`
  width: ${(props) => props.width || '100%'};
`;
const TableHead = styled(FlexWrap)`
  background-color: #e0e0e0;
  border-left: 1px solid #2f2f2f;
  border-bottom: 1px solid #2f2f2f;
  padding: 8px 16px;
  width: 100%;
`;
const TableContent = styled(FlexWrap)`
  padding: 8px 16px;
  border-left: 1px solid #2f2f2f;
  border-bottom: 1px solid #2f2f2f;
  width: 100%;
  height: 36px;
  white-space: nowrap;
`;
const ServiceInfoWrapper = styled(FlexWrap)`
  width: 100%;
  flex-direction: column;
`;

const DeleteButton = styled.button`
  min-width: 140px;
  height: 44px;
  padding: 6px 32px;
  background-color: #3a3b3e;
  color: #ffffff;
  font-size: 14px;
  line-height: 150%;
  font-weight: 600;
  border-radius: 6px;
  cursor: pointer;
`;

export default WithdrawalModalContent;
