import { useReducer } from 'react';

const initialState = {
  filter: {
    transferView: '',
  },
};

function reducer(state: any, action: any) {
  switch (action.type) {
    case 'SET_TRANSFERVIEW':
      return {
        ...state,
        filter: {
          transferView: action.payload,
        },
      };
    case 'OPEN_MODAL':
      return {
        ...state,
        selected: action.payload.selected,
        ...(action.payload.type === 'FEEDBACK' && { isOpenFeedback: true }),
        ...(action.payload.type === 'REFUND' && { isOpenRefund: true }),
        ...(action.payload.type === 'DETAIL' && { isOpenDetail: true }),
      };
    case 'CLOSE_MODAL':
      return {
        ...state,
        selected: null,
        ...(action.payload.type === 'FEEDBACK' && { isOpenFeedback: false }),
        ...(action.payload.type === 'REFUND' && { isOpenRefund: false }),
        ...(action.payload.type === 'DETAIL' && { isOpenDetail: false }),
      };
  }
}

const useRemittanceReducer = () => {
  return useReducer(reducer, initialState);
};

export default useRemittanceReducer;
