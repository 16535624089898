import { TableBody } from '@material-ui/core';
import FranchiseeStatusItem from './FranchiseeStatusItem';

function FranchiseeStatusList({
  franchisees,
  onOpen,
  checkedFranchisee,
  onCheck,
}) {
  return (
    <TableBody>
      {franchisees.map((franchisee, idx) => (
        <FranchiseeStatusItem
          key={franchisee.franchiseeIndex}
          idx={idx}
          franchisee={franchisee}
          onOpen={onOpen}
          checkedFranchisee={checkedFranchisee}
          onCheck={onCheck}
        />
      ))}
    </TableBody>
  );
}

export default FranchiseeStatusList;
