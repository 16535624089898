import React, { ReactNode, RefObject } from 'react';
import ReactDOM from 'react-dom';
import styled from 'styled-components';

type Props = {
  children: ReactNode | ReactNode[];
  onClickBackdrop?: () => void;
  isOpaqueBackdrop?: boolean;
  container?: RefObject<HTMLElement>;
  zIndex?: number;
};

const Portal = ({
  children,
  onClickBackdrop,
  isOpaqueBackdrop = false,
  container,
  zIndex = 30,
}: Props) => {
  return ReactDOM.createPortal(
    <>
      <Backdrop
        role='presentation'
        isOpaqueBackdrop={isOpaqueBackdrop}
        onClick={onClickBackdrop}
        zIndex={zIndex}
      />
      {children}
    </>,
    container?.current || document.body,
  );
};

const Backdrop = styled.div<{ isOpaqueBackdrop: boolean; zIndex: number }>`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: ${({ zIndex }) => zIndex};
  background-color: ${({ isOpaqueBackdrop }) =>
    isOpaqueBackdrop ? 'rgba(0,0,0,0.3)' : 'transparent'};
`;

export default Portal;
