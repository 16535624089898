import React, { MouseEvent, useEffect, useState } from 'react';
import Field from './Field';
import { FieldValues, UseFormRegister } from 'react-hook-form';
import styled from 'styled-components';
import MapImage from '@/assets/common/Map.png';
import MapModal from './MapModal';
import RemoveIcon from '@/assets/common/Subtract.png';
import AddressSearchModal from './AddressSearchModal';

type Props<T extends FieldValues> = {
  register: ReturnType<UseFormRegister<T>>;
  label: string;
  watch: (name: keyof T) => any;
};

function Address<T extends FieldValues>({ label, register, watch }: Props<T>) {
  const [isOpenAddressModal, setIsOpenAddressModal] = useState(false);
  const [isOpenMapModal, setIsOpenMapModal] = useState(false);
  const [mainAddress, setMainAddress] = useState('');

  const handleRemoveAddress = (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    setMainAddress('');
  };

  useEffect(() => {
    register.onChange({
      target: {
        value: mainAddress,
        name: register.name,
      },
    });
  }, [mainAddress]);

  useEffect(() => {
    setMainAddress(watch(register.name));
  }, [watch, register]);

  return (
    <>
      <Field label={label}>
        <AddressWrapper
          style={{ justifyContent: mainAddress ? 'space-between' : 'flex-end' }}
        >
          {mainAddress ? (
            <>
              <InputWrapper>
                <AddressText>{mainAddress}</AddressText>
                <button onClick={handleRemoveAddress}>
                  <img src={RemoveIcon} alt='주소 지우기' width={20} />
                </button>
              </InputWrapper>
              <button
                onClick={(e) => {
                  e.preventDefault();
                  setIsOpenMapModal(true);
                }}
              >
                <img src={MapImage} alt='주소 위치 확인하기' width={24} />
              </button>
            </>
          ) : (
            <SearchButton
              onClick={(e) => {
                e.preventDefault();
                setIsOpenAddressModal(true);
              }}
            >
              주소 검색
            </SearchButton>
          )}
        </AddressWrapper>
      </Field>
      {isOpenAddressModal && (
        <AddressSearchModal
          onChange={setMainAddress}
          onClose={() => setIsOpenAddressModal(false)}
        />
      )}
      {isOpenMapModal && (
        <MapModal
          onClose={() => setIsOpenMapModal(false)}
          address={mainAddress}
        />
      )}
    </>
  );
}

const AddressWrapper = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
`;

const SearchButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 4px 12px;
  border-radius: 8px;
  font-size: 14px;
  font-weight: 600;
  line-height: 21px;
  color: #3a3b3e;
  border: 1px solid #e5e6e8;
  background-color: #f5f6f7;
`;

const InputWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 8px;
  margin-right: 8px;
`;

const AddressText = styled.p`
  width: 100%;
  padding: 0;
  border: 0;
  font-size: 14px;
  font-weight: 600;
  line-height: 21px;
  color: #030303;
`;

export default Address;
