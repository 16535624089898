import styled from 'styled-components';
import RenderTableData from './RenderTableData';
import Icon from '@/components/common/Icon';
import checkIcon from '@/assets/common/check.png';
import checkedIcon from '@/assets/common/checked.png';

type Props = {
  column: { type: string; title: string }[];
  row: any[];
  onClick: (id: number) => void;
  checkedRow?: number[];
  onCheckAllRow?: () => void;
};

const Table = ({ column, row, onClick, checkedRow, onCheckAllRow }: Props) => {
  const onSelect = () => {
    onCheckAllRow && onCheckAllRow();
  };

  return (
    <>
      <TableWrapper>
        <TableHead>
          <TableHeadRow role='row'>
            {column.map(({ type, title }) => (
              <TableHeader
                key={`${type}-${title}`}
                role='columnheader'
                scope='col'
                width={type === 'id' ? '150px' : 'auto'}
              >
                <Header>
                  {type === 'selection' ? (
                    <HeaderButton onClick={onSelect}>
                      {checkedRow?.length === 0 ? '전체 선택' : '전체 해제'}
                      <Icon
                        imgUrl={
                          checkedRow?.length === 0 ? checkIcon : checkedIcon
                        }
                        width='20px'
                        height='20px'
                      />
                    </HeaderButton>
                  ) : (
                    <HeaderLabel>{title}</HeaderLabel>
                  )}
                </Header>
              </TableHeader>
            ))}
          </TableHeadRow>
        </TableHead>
        {row.length > 0 && (
          <tbody>
            {row.map((data) => (
              <Row
                role='row'
                key={data.hospitalId}
                tabIndex={0}
                onClick={() => onClick(data.hospitalId || data.refundId)}
              >
                {column.map(({ type }, index) => (
                  <TableData key={type}>
                    {RenderTableData({
                      column: column[index].type,
                      data: data[type],
                      id: data.hospitalId || data.refundId,
                      checkedRow,
                      returnDate: data.returnDate,
                    })}
                  </TableData>
                ))}
              </Row>
            ))}
          </tbody>
        )}
      </TableWrapper>
      {row.length === 0 && <NoResult>결과가 존재하지 않습니다.</NoResult>}
    </>
  );
};
export default Table;

const TableWrapper = styled.table`
  width: 100%;
  background-color: #ffffff;
  color: #030303;
  border-collapse: collapse;
  margin-bottom: 50px;
`;

const TableHeadRow = styled.tr`
  height: 48px;
  border-bottom: 1px solid #cbccce;
  background-color: #e5e6e8;
`;

const TableHead = styled.thead``;

const TableHeader = styled.th<{ width: string }>`
  padding: 0 20px;
  width: ${({ width }) => width};
  min-width: 120px;
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  color: #030303;
`;

const HeaderLabel = styled.span`
  font-size: 14px;
  font-weight: 600;
  line-height: 21px;
`;

const HeaderButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  font-size: 14px;
  font-weight: 600;
  line-height: 21px;
`;

const Row = styled.tr`
  height: 48px;
  border-bottom: 1px solid #f5f6f7;
  cursor: pointer;

  &:hover {
    background-color: #f7f9fc;
  }
`;

const TableData = styled.td`
  padding: 0 20px;
`;

const NoResult = styled.p`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 40px;
`;
