import React, { Fragment } from 'react';
import styled, { css } from 'styled-components';
import {
  formatBankInfo,
  formatFranchisee,
  formatWithdrawal,
} from '../../../utils/ktp';

function DetailContent({
  data: { detailFranchiseeInfo, detailWithdrawalInfo, detailBankInfo },
}) {
  const formattedFranchiseeInfo = formatFranchisee(detailFranchiseeInfo);
  const formattedWithdrawalInfo = formatWithdrawal(detailWithdrawalInfo);
  const formattedBankInfo = formatBankInfo(detailBankInfo);

  return (
    <>
      <Title>회원가입 정보</Title>
      <StyledTable width='100%' align='center'>
        {formattedFranchiseeInfo.map((items) => (
          <StyledTr>
            {items.map((item, i) => (
              <Fragment key={i}>
                <StyledTh>{item.label}</StyledTh>
                <StyledTd>{item?.value || item?.option?.value}</StyledTd>
              </Fragment>
            ))}
          </StyledTr>
        ))}
      </StyledTable>
      <Title>T.POINT 출금요청 정보</Title>
      <StyledTable width='100%' align='center'>
        {formattedWithdrawalInfo.map((items) => (
          <StyledTr>
            {items.map((item, i) => (
              <Fragment key={i}>
                <StyledTh>{item.name}</StyledTh>
                <StyledTd>{item.value}</StyledTd>
              </Fragment>
            ))}
          </StyledTr>
        ))}
      </StyledTable>
      <Title>계좌 정보</Title>
      <StyledTable width='100%' align='center'>
        {formattedBankInfo.map((items) => (
          <StyledTr>
            {items.map((item, i) => (
              <Fragment key={i}>
                <StyledTh>{item.name}</StyledTh>
                <StyledTd>{item.value}</StyledTd>
              </Fragment>
            ))}
          </StyledTr>
        ))}
      </StyledTable>
    </>
  );
}

export default DetailContent;
const Title = styled.h3`
  font-size: 18px;
  margin-bottom: 16px;
`;
const borderStyles = css`
  border-collapse: collapse;
  border: 1px solid black;
`;
const StyledTable = styled.table`
  ${borderStyles}
  margin: 0 0 40px;
`;
const StyledTd = styled.td`
  ${borderStyles}
  padding: 0 16px;
  height: 40px;
  font-size: 14px;
  min-width: 120px;
  background-color: #f2f2f2;
  ${(props) =>
    props.editable &&
    css`
      background-color: white;
    `}
  width:${(props) => props.width || 'auto'};
`;
const StyledTh = styled.td`
  ${borderStyles}
  padding-left: 16px;
  height: 40px;
  font-size: 14px;
  line-height: 40px;
  background-color: #d3d3d3;
  width: 170px;
`;

const StyledTr = styled.tr`
  ${borderStyles}
  padding: 8px 15px;
  font-size: 14px;
  height: 40px;
  line-height: 40px;
`;
