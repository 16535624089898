import { useState } from 'react';
import { useQuery } from 'react-query';
import styled from 'styled-components';
import { useHistory, useLocation } from 'react-router-dom';

import { getKioskDetail } from '@/api/kiosk';
import LoadingView from '@/components/common/LoadingView';
import { BillType, GetKioskDetailResponse } from '@/types/api/kiosk';
import CashBalance from './kioskDetail/CashBalance';
import KioskTitle from './KioskTitle';
import WithdrawalInfo from './kioskDetail/WithdrawalInfo';
import ErrorLogInfo from './kioskDetail/ErrorLogInfo';
import ActionBtn from '@/components/common/ActionBtn';
import Tag from '@/components/common/Tag';
import useKioskIsActive from '@/hooks/kiosk/useKioskIsActive';
import KioskModal from '../kioskModal/KioskModal';
import KioskModalContent from '../kioskModal/KioskModalContent';

export type StateType = {
  kioskId: number;
  title: string;
  preTitle?: string;
  billType: BillType;
  detailTitle?: string;
};

export type StyledBtnProps = {
  flexDirection?: string;
  justifyContent?: string;
  gap?: string;
};

function KioskStatusDetail() {
  const { state } = useLocation<StateType>();
  const history = useHistory();
  const { title, preTitle, kioskId, billType } = state;

  const [isModal, setIsModal] = useState(false);

  const { data: kioskDetailData } = useQuery<GetKioskDetailResponse, number>(
    ['kioskDetail'],
    () => getKioskDetail(kioskId),
    {
      onSuccess: () => {},
      retry: false,
    },
  );

  const { mutate: kioskIsActive } = useKioskIsActive();

  const onClickMove = (detailTitle: string, url: string) => {
    history.push({
      pathname: url,
      state: {
        kioskId,
        preTitle: '키오스크 상태',
        title,
        detailTitle,
        billType,
      },
    });
  };

  const onClickChangeModalStatus = () => {
    setIsModal((prev) => !prev);
  };

  const onKioskIsActiveSave = () => {
    if (kioskDetailData) {
      kioskIsActive({
        kioskIndex: kioskId,
        activate: !kioskDetailData?.isActive,
      });
      onClickChangeModalStatus();
    }
  };

  return (
    <>
      <Wrapper flexDirection='row' justifyContent='space-between'>
        <KioskTitle
          kioskId={kioskId}
          title={title}
          preTitle={preTitle}
          billType={billType}
        />
        <Wrapper flexDirection='row' gap='12px'>
          <Tag
            active={kioskDetailData?.isActive}
            color='#27AE60'
            bgColor='#D9F6E5'
          >
            {kioskDetailData?.isActive && <StyledCircle />}
            {kioskDetailData?.isActive ? '사용중' : '사용 불가'}
          </Tag>
          <ActionBtn
            title='상태 변경'
            onClick={onClickChangeModalStatus}
            outlineColor='#CBCCCE'
            bgColor='none'
            color='#5F6165'
            style={{
              borderRadius: '8px',
              minWidth: '92px',
              padding: '6px 16px',
              fontWeight: 600,
            }}
          />
        </Wrapper>
      </Wrapper>
      {kioskDetailData ? (
        <Wrapper>
          <CashBalance
            kioskIndex={kioskId}
            billType={kioskDetailData.billType}
            remainingInfo={kioskDetailData.remainingInfo}
            depositInfo={kioskDetailData.depositInfo}
            trendInfo={kioskDetailData.trendInfo}
            onClick={onClickMove}
          />
          <WithdrawalInfo
            data={kioskDetailData.withdrawalInfo}
            billType={kioskDetailData.billType}
            onClick={onClickMove}
          />
          <ErrorLogInfo
            data={kioskDetailData.errorInfo}
            onClick={onClickMove}
          />
        </Wrapper>
      ) : (
        <LoadingView />
      )}
      {isModal && kioskDetailData && (
        <KioskModal onClose={onClickChangeModalStatus} width='500px'>
          <Form>
            <KioskModalContent
              title='키오스크 상태 변경'
              titleSize={21}
              titleWeight={700}
              onClose={onClickChangeModalStatus}
              onSave={onKioskIsActiveSave}
              isActive={kioskDetailData?.isActive}
            />
          </Form>
        </KioskModal>
      )}
    </>
  );
}

const Wrapper = styled.div<StyledBtnProps>`
  display: flex;
  flex-direction: ${(props) => props.flexDirection || 'column'};
  justify-content: ${(props) => props.justifyContent || 'flex-start'};
  gap: ${(props) => props.gap || '50px'};
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 10px;
`;

const StyledCircle = styled.div`
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: #27ae60;
  margin-right: 8px;
`;

export default KioskStatusDetail;
