import { AxiosError } from 'axios';
import React, {
  ChangeEvent,
  ChangeEventHandler,
  useRef,
  useState,
} from 'react';
import { useMutation } from 'react-query';
import styled from 'styled-components';

import { createNotice } from '@/api/medical';
import ActionBtn from '@/components/common/ActionBtn';
import Form from '@/components/medical/notice/Form';
import FormItemLayout from '@/components/medical/notice/FormItemLayout';
import QuillEditor from '@/components/medical/notice/QuillEditor';
import Modal from '@/components/common/Modal';
import FlexWrap from '@/components/common/FlexWrap';
import { ICreateNoticeBody } from '@/types/api/medical';

interface IProps {
  onCloseModal: () => void;
}
function CreateModal({ onCloseModal }: IProps) {
  const quillRef = useRef(null);
  const [state, setState] = useState<ICreateNoticeBody>({
    fixed: false,
    immediate: true,
    scheduledDate: new Date().toISOString().slice(0, 16),
    visible: true,
    title: '',
    writer: '',
    content: '',
  });

  const setHtmlContent = (value: string) => {
    setState((prev) => ({ ...prev, content: value }));
  };
  const mutation = useMutation<number, AxiosError, ICreateNoticeBody>(
    (payload) => createNotice(payload),
    {
      onError: () =>
        alert('공지사항 등록에 실패하였습니다. 관리자에게 문의해주세요.'),
      onSuccess: () => {
        alert('공지사항이 정상적으로 게시되었습니다.');
        onCloseModal();
      },
    },
  );
  const onSubmit = () => {
    if (!state.immediate && !state.scheduledDate) {
      alert('예약 발송 날짜를 입력해주세요.');
      return;
    }
    if (!state.title || !state.content || !state.writer) {
      alert('필수 입력 항목을 채워주세요.');
      return;
    }
    const isOK = window.confirm('해당 내용으로 공지사항을 올리시겠습니까?');
    if (!isOK) {
      return;
    }
    mutation.mutate(state);
  };
  const onChangeInput: ChangeEventHandler<
    HTMLTextAreaElement | HTMLInputElement
  > = (e) => {
    setState({ ...state, [e.target.name]: e.target.value });
  };
  const onChangeChecked: (
    event: ChangeEvent<HTMLInputElement>,
    checked: boolean,
  ) => void = (e) => {
    setState({ ...state, [e.target.name]: e.target.checked });
  };
  const onChangeRadio: (
    event: ChangeEvent<HTMLInputElement>,
    value: string,
  ) => void = (e) => {
    setState({
      ...state,
      immediate: e.target.name === 'immediate' ? true : false,
      ...(e.target.name === 'immediate' && {
        scheduledDate: new Date().toISOString().slice(0, 16),
      }),
    });
  };

  return (
    <Modal onClose={onCloseModal}>
      <Wrapper>
        <Title>공지사항 신규작성</Title>
        <Form
          state={state}
          onChangeChecked={onChangeChecked}
          onChangeInput={onChangeInput}
          onChangeRadio={onChangeRadio}
          isEditMode={true}
        />
        <QuillEditor
          htmlContent={state.content}
          setHtmlContent={setHtmlContent}
          isEditMode={true}
          quillRef={quillRef}
        />
        <FormItemLayout
          label='작성자'
          required
          style={{ justifyContent: 'flex-end', marginTop: 20 }}
          labelWidth={80}
        >
          <StyledInput
            name='writer'
            defaultValue={state.writer}
            onChange={onChangeInput}
            placeholder='작성자 입력'
          />
        </FormItemLayout>
        <BtnContainer gap='10px' justifyContent='flex-end'>
          <ActionBtn title='발송하기' onClick={onSubmit} size='big' />
        </BtnContainer>
      </Wrapper>
    </Modal>
  );
}
const Wrapper = styled.div`
  width: 100%;
`;
const Title = styled.h2`
  font-size: 28px;
  margin-bottom: 40px;
`;
const BtnContainer = styled(FlexWrap)`
  margin-top: 20px;
`;
const StyledInput = styled.input`
  padding: 8px 12px;
  border: 1px solid lightgray;
  border-radius: 4px;
  font-size: 14px;
  outline: none;
`;
export default CreateModal;
