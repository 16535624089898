import React from 'react';
import { TableRow } from '@material-ui/core';
import styled from 'styled-components';
import { format } from 'date-fns';

import DetailBtn from '../../common/DetailBtn';
import CustomTableCell from '../../common/CustomTableCell';

const ListItem = ({ data, onOpenModal, idx }) => {
  const { pushIndex, title, createdDate } = data;
  return (
    <>
      <StyledTableRow
        style={
          (idx + 1) % 5 === 0
            ? { borderBottom: '2px solid rgb(220, 220, 220)' }
            : { borderBottom: '1px solid rgb(220, 220, 220)' }
        }
      >
        <CustomTableCell width='40' idx={0}>
          {pushIndex}
        </CustomTableCell>
        <CustomTableCell width='370'>{title}</CustomTableCell>
        <CustomTableCell width='200'>
          {format(new Date(createdDate), 'yyyy.MM.dd  hh:mm')}
        </CustomTableCell>
        <CustomTableCell width='80'>
          <DetailBtn onClick={onOpenModal}>상세보기</DetailBtn>
        </CustomTableCell>
      </StyledTableRow>
    </>
  );
};
const StyledTableRow = styled(TableRow)`
  background-color: #ffffff;
  :hover {
    background-color: #f7f9fc;
  }
`;
export default ListItem;
