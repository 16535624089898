import React, { useEffect, useState } from 'react';
import styled, { css } from 'styled-components';
import FlexWrap from '@/components/common/FlexWrap';
import {
  mailCountFilter,
  paymentFilter,
  sortFilterAll,
  sortFilterPayment,
} from '@/constants/ktp';
import ViewDataSelection from '../ViewDataSelection';

export type ListType = {
  title: string;
  isSelected: boolean;
  value: string;
  isDisable?: boolean;
};

export type FilterListType = {
  orderBy: ListType[];
  paymentStatus: ListType[];
  mainCount: ListType[];
};

type ManagementFilterProps = {
  currentTransferView: string;
  onClickChangeTransfer: (value: string) => void;
  initialSize: string;
  hasRefund: boolean;
  onChangeHasRefund: (value: boolean) => void;
};

function ManagementFilter({
  currentTransferView,
  onClickChangeTransfer,
  initialSize,
  hasRefund,
  onChangeHasRefund,
}: ManagementFilterProps) {
  const [filterList, setFilterList] = useState<FilterListType>({
    orderBy: [],
    paymentStatus: [],
    mainCount: [],
  });

  useEffect(() => {
    const newSortFilter = currentTransferView
      ? sortFilterPayment
      : sortFilterAll;

    setFilterList({
      ...filterList,
      orderBy: newSortFilter,
      paymentStatus: paymentFilter,
      mainCount: mailCountFilter,
    });
  }, [currentTransferView]);

  return (
    <>
      <Container>
        <FlexWrap>
          <TransferViewContainer>
            <TransferView
              isActive={hasRefund && currentTransferView === ''}
              onClick={() => {
                onClickChangeTransfer('');
                onChangeHasRefund(true);
              }}
            >
              전체보기
            </TransferView>
            <TransferView
              isActive={hasRefund && currentTransferView === 'NO_FEEDBACK'}
              onClick={() => {
                onClickChangeTransfer('NO_FEEDBACK');
                onChangeHasRefund(true);
              }}
            >
              미조치
            </TransferView>
            <TransferView
              isActive={
                hasRefund && currentTransferView === 'FEEDBACK_IN_PROGRESS'
              }
              onClick={() => {
                onClickChangeTransfer('FEEDBACK_IN_PROGRESS');
                onChangeHasRefund(true);
              }}
            >
              조치
            </TransferView>
            <TransferView
              isActive={!hasRefund && currentTransferView === ''}
              onClick={() => {
                onClickChangeTransfer('');
                onChangeHasRefund(false);
              }}
            >
              미환급
            </TransferView>
          </TransferViewContainer>
        </FlexWrap>
        <div>
          <ViewDataSelection
            options={[10, 100, 500, 10000]}
            initialSize={initialSize}
          />
        </div>
      </Container>
    </>
  );
}

export default ManagementFilter;

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  position: relative;
  height: 56px;
`;

const TransferViewContainer = styled.div`
  display: flex;
  background-color: #fff;
  align-items: center;
`;

const TransferView = styled.div<{ isActive?: boolean }>`
  padding: 16px 32px;
  font-size: 14px;
  line-height: 21px;
  font-weight: 600;
  color: #a7a7a7;
  white-space: nowrap;
  cursor: pointer;
  ${(props) =>
    props.isActive &&
    css`
      color: #1856cd;
      border-bottom: 2px solid #246cf6;
    `}
`;
