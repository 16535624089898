import React from 'react';
import {
  SubTitle,
  TransferContainer,
  TransferStatus,
} from './DetailModalContent';
import FlexWrap from '@/components/common/FlexWrap';
import { TransferInput } from './RemittanceStatusRadio';
import { TextField } from '@material-ui/core';
import Typography from '@/components/common/Typography';
import { formatDatetimeForInput } from '@/utils/common';

type Props = {
  refundInfo: any;
  isEdit: boolean;
  setData: React.Dispatch<React.SetStateAction<unknown>>;
};

const STATUS_LIST = [
  {
    name: '미발송',
    value: 0,
  },
  {
    name: '1회',
    value: 1,
  },
  {
    name: '2회',
    value: 2,
  },
  {
    name: '3회/완료',
    value: 3,
  },
];

function MailingStatusRadio({ refundInfo, isEdit, setData }: Props) {
  const onChangeMail = (mailCount: number) => {
    setData((prev: any) => {
      return {
        ...prev,
        refundInfo: {
          ...prev.refundInfo,
          mailSendCount: mailCount,
        },
      };
    });
  };
  const onChangeMailSendDate = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    setData((prev: any) => {
      return {
        ...prev,
        refundInfo: {
          ...prev.refundInfo,
          lastSentDate: e.target.value,
        },
      };
    });
  };
  return (
    <TransferStatus>
      <SubTitle>메일 발송 내역</SubTitle>
      <TransferContainer dir='row' gap='20px'>
        {STATUS_LIST.map((status) => {
          return (
            <FlexWrap>
              <TransferInput
                type='radio'
                checked={refundInfo.mailSendCount === status.value}
                onChange={() => onChangeMail(status.value)}
                disabled={!isEdit}
                isRadio
              />
              <label>{status.name}</label>
            </FlexWrap>
          );
        })}
        <Typography
          fontSize='16px'
          lineHeight='150%'
          fontWeight='500'
          color='#A7A7A7'
        >
          (최근 발송일)
        </Typography>
        <TextField
          id='datetime-local'
          disabled={!isEdit}
          onChange={onChangeMailSendDate}
          type='datetime-local'
          value={
            refundInfo.lastSentDate
              ? formatDatetimeForInput(new Date(refundInfo.lastSentDate))
              : ''
          }
          InputLabelProps={{
            shrink: true,
          }}
        />
      </TransferContainer>
    </TransferStatus>
  );
}

export default MailingStatusRadio;
