import { useQuery } from 'react-query';

import { getWithdrawalDetail } from '@/api/kiosk';
import Modal from '@/components/common/Modal';
import { GetWithdrawalDetailResponse } from '@/types/api/kiosk';
import WithdrawalModalContent from './WithdrawalModalContent';

type WithdrawalDetailModalProps = {
  kioskWithdrawalIndex: number;
  onClose: () => void;
};

function WithdrawalDetailModal({
  kioskWithdrawalIndex,
  onClose,
}: WithdrawalDetailModalProps) {
  const { isLoading, data } = useQuery<GetWithdrawalDetailResponse, number>(
    ['withdrawalDetail', kioskWithdrawalIndex],
    () => getWithdrawalDetail(kioskWithdrawalIndex),
    {
      refetchOnWindowFocus: false,
      onError: () =>
        alert('데이터 조회에 실패하였습니다. 관리자에게 문의해주세요.'),
    },
  );

  return (
    <Modal onClose={onClose} isLoading={isLoading}>
      {data && (
        <WithdrawalModalContent
          data={data}
          onClose={onClose}
          kioskWithdrawalIndex={kioskWithdrawalIndex}
        />
      )}
    </Modal>
  );
}

export default WithdrawalDetailModal;
