import styled from 'styled-components';
import QnaHistory from './QnaHistory';
import { QnaListResponse } from '@/types/qna.type';

type Props = {
  name?: string;
  nation?: string;
  refundIdList?: number[];
  storeList?: string[];
  contentList?: QnaListResponse[];
  onOpenModal: (id: number) => void;
};

function QnaCustomerInfo({
  name = '이름 없음',
  nation = '국적 없음',
  refundIdList = [],
  storeList = [],
  contentList = [],
  onOpenModal,
}: Props) {
  return (
    <Wrapper>
      <Title>고객 정보</Title>
      <dl>
        <InfoWrapper>
          <DataWrapper>
            <dt>고객명</dt>
            <dd>{name}</dd>
          </DataWrapper>
          <DataWrapper>
            <dt>국적</dt>
            <dd>{nation}</dd>
          </DataWrapper>
        </InfoWrapper>
        <InfoWrapper>
          <dt>가맹점</dt>
          <RefundIdListWrapper>
            {storeList.map((store) => {
              return <RefundStore key={store}>{store}</RefundStore>;
            })}
          </RefundIdListWrapper>
        </InfoWrapper>
        <InfoWrapper>
          <dt>거래넘버</dt>
          <RefundIdListWrapper>
            {refundIdList.map((refundId) => {
              return (
                <RefundNum
                  type='button'
                  key={refundId}
                  onClick={() => onOpenModal(refundId)}
                >
                  {refundId}
                </RefundNum>
              );
            })}
          </RefundIdListWrapper>
        </InfoWrapper>
      </dl>
      <QnaHistory contentList={contentList} />
    </Wrapper>
  );
}

export default QnaCustomerInfo;

const Wrapper = styled.section`
  width: 100%;
  max-height: 690px;
  dt,
  dd {
    font-size: 14px;
  }
`;

const Title = styled.h6`
  margin: 0 0 16px;
  color: #000;
  font-size: 18px;
  font-weight: 700;
`;

const InfoWrapper = styled.div`
  display: flex;
  border-radius: 12px;
  margin-bottom: 8px;
  padding: 12px;
  background-color: #f5f6f7;
  font-weight: 600;
`;

const DataWrapper = styled.div`
  display: flex;
  width: 100%;
  dt {
    word-break: keep-all;
  }
`;

const RefundIdListWrapper = styled.dd`
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  max-width: 300px;
  overflow: auto;
`;

const RefundStore = styled.span`
  color: #030303;
  font-size: 14px;
  font-weight: 600;
`;

const RefundNum = styled.button`
  color: #030303;
  font-size: 14px;
  font-weight: 600;
`;
