import React from 'react';
import { useHistory } from 'react-router-dom';
import { TableBody } from '@material-ui/core';
import styled from 'styled-components';

import FlexWrap from '@/components/common/FlexWrap';
import Typography from '@/components/common/Typography';
import LatestTable from '@/components/ktp/home/LatestTable';
import { homeColumnList } from '@/constants/medical';
import { INoticeItem } from '@/types/api/medical';
import PrimaryNoticeList from './PrimaryNoticeList';
import store from '@/store';

type LeastesNoticeType = {
  data: INoticeItem[];
  onPressNoticeList: (value: number) => void;
};

function LeastestNotice({ data, onPressNoticeList }: LeastesNoticeType) {
  const history = useHistory();
  const { notice } = homeColumnList;
  const serverState = store.getState().serverState;

  return (
    <>
      <TitleWrap justifyContent='space-between' margin='56px 0 0 0'>
        <Typography
          fontSize='20px'
          lineHeight='28px'
          color='#212121'
          margin='0 0 28px 0'
          fontWeight='500'
        >
          주요 공지사항
        </Typography>
        <MoreBtn
          onClick={() => history.push(`/refund/medical/${serverState}/notice`)}
        >
          + 더보기
        </MoreBtn>
      </TitleWrap>
      <LatestTable
        columns={notice}
        contentComponent={
          <TableBody>
            {data.map((item, idx) => (
              <PrimaryNoticeList
                key={idx}
                data={item as INoticeItem}
                onPressList={() => onPressNoticeList(item.noticeIndex)}
              />
            ))}
          </TableBody>
        }
      />
    </>
  );
}

const TitleWrap = styled(FlexWrap)``;

const MoreBtn = styled.button`
  color: #757575;
  margin-top: 20px;
`;

export default LeastestNotice;
