import styled from 'styled-components';
import checkIcon from '@/assets/common/check.png';
import checkedIcon from '@/assets/common/checked.png';
import { comma } from '@/utils/ktp';
import Icon from '@/components/common/Icon';

const RenderTableData = ({
  column,
  data,
  id,
  checkedRow,
  returnDate,
}: {
  column: any;
  data: any;
  id: number;
  checkedRow?: number[];
  returnDate?: string;
}) => {
  if (column === 'selection') {
    return (
      <CheckBox>
        <Icon
          imgUrl={checkedRow?.includes(id) ? checkedIcon : checkIcon}
          width='20px'
          height='20px'
        />
      </CheckBox>
    );
  }

  if (column === 'returnStatus') {
    return data === null ? (
      <StatusData>-</StatusData>
    ) : (
      <StatusData>
        <Status>
          {data === 'RETURN_IN_PROGRESS' ? '환불 중' : '환불 완료'}
        </Status>
        <StatusDate>{returnDate}</StatusDate>
      </StatusData>
    );
  }

  if (['returned', 'price', 'refund'].includes(column)) {
    return <Data>{comma(data)}원</Data>;
  }

  if ('count' === column) {
    return <Data>{comma(data)}</Data>;
  }

  return <Data>{data}</Data>;
};

export default RenderTableData;

const Data = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
`;

const CheckBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const StatusData = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: #3a3b3e;
`;

const Status = styled.span`
  font-size: 14px;
`;

const StatusDate = styled.span`
  font-size: 12px;
  margin-top: 2px;
`;
