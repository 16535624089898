import { NonDepartureStatusOptions } from '@/constants/medical';
import { TextDropdown } from '@successmode-ktp/kui';
import styled from 'styled-components';
import { StatusType } from './ChangeStatusSection';

type Props = {
  label: string;
  status: StatusType;
  onChange: (val: 'RETURN_IN_PROGRESS' | 'RETURN_COMPLETED') => void;
};

function DropdownSection({ label, status, onChange }: Props) {
  return (
    <div>
      <Label>
        {label}
        <RequiredMark>*</RequiredMark>
      </Label>
      <TextDropdown
        options={NonDepartureStatusOptions as StatusType[]}
        selectedOption={status}
        onChange={onChange}
      />
    </div>
  );
}

export default DropdownSection;

const Label = styled.label`
  display: block;
  margin-bottom: 8px;
  font-size: 14px;
  font-weight: 600;
  line-height: 21px;
  color: #5f6165;
`;

const RequiredMark = styled.span`
  margin-left: 4px;
  color: #ed0828;
`;

const Box = styled.button<{ isOpen: boolean }>`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 8px;
  width: 100%;
  height: 53px;
  border-radius: ${({ isOpen }) => (isOpen ? '6px 6px 0 0' : '6px')};
  cursor: pointer;
  outline: 0;
  border: 1px solid #cccccc;
  padding: 0 16px;
  color: #111111;
  transition: all 200ms ease-in-out;
`;

const BoxText = styled.span`
  text-align: left;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const DropdownWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
`;

const OptionButton = styled.button`
  display: flex;
  align-items: center;
  padding: 16px;
  width: 100%;
  height: 53px;
  background-color: #fafafa;

  &:not(:last-child) {
    border-bottom: 1px solid #cccccc;
  }

  &:hover {
    background-color: #f5f6f7;
  }
`;

const OptionText = styled.span`
  font-size: 14px;
  line-height: 21px;
  color: #3a3b3e;
`;
