import {
  getCashbillList,
  requestCashBillIssue,
  updateCashBillStatus,
} from '@/api/medical';
import Search from '@/components/common/Search';
import Title from '@/components/common/Title';
import DataSelection from '@/components/medical/barobill/DataSelection';
import DataTable from '@/components/medical/barobill/DataTable';
import DetailModal from '@/components/medical/refundStatus/DetailModal';
import { BAROBILL_TABLE_HEADER } from '@/constants/medical';
import useCashBillReducer from '@/hooks/medical/useCashBillReducer';
import useUpdateUrlParams from '@/hooks/sales/useUpdateUrlParams';
import { CASHBILL_STATUS } from '@/types/api/medical';
import { comma } from '@/utils/ktp';
import Container from '@material-ui/core/Container';
import { Icon, Pagination } from '@successmode-ktp/kui';
import { useState } from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useLocation } from 'react-router-dom';
import styled from 'styled-components';

function HospitalBarobill() {
  const location = useLocation();
  const updateUrlParams = useUpdateUrlParams();
  const searchParams = new URLSearchParams(location.search);
  const initialSearch = searchParams.get('search') || '';
  const initialPage = searchParams.get('page') || '0';
  const initialSize = searchParams.get('size') || '100';

  const [page, setPage] = useState(
    initialPage === '0' ? 1 : Number(initialPage) + 1,
  );
  const [totalPage, setTotalPage] = useState(0);
  const [isFirstPage, setIsFirstPage] = useState(false);
  const [isLastPage, setIsLastPage] = useState(false);
  const [selection, setSelection] = useState([
    'NOT_ISSUED',
    'ISSUED',
    'TRANSFER_IN_PROGRESS',
    'TRANSFERRED',
    'CANCELED',
  ]);

  const [state, dispatch] = useCashBillReducer();
  const { filter, selected, dateFilter } = state;

  const { data } = useQuery(
    [
      'cashbillList',
      filter,
      initialPage,
      initialSearch,
      initialSearch,
      initialSize,
      selection,
    ],
    () =>
      getCashbillList({
        startDate: filter.startDate,
        endDate: filter.endDate,
        status:
          selection.length === 0
            ? [
                'NOT_ISSUED',
                'ISSUED',
                'TRANSFER_IN_PROGRESS',
                'TRANSFERRED',
                'CANCELED',
              ]
            : (selection as CASHBILL_STATUS[]),
        page: initialPage,
        size: initialSize,
        keyword: initialSearch,
      }),
    {
      onSuccess: ({ refundInfo }) => {
        const { totalPages, first, last } = refundInfo;
        setTotalPage(totalPages);
        setIsFirstPage(first);
        setIsLastPage(last);
      },
      retry: false,
      keepPreviousData: true,
    },
  );

  const onOpenModal = (e: any, payload: any) => {
    dispatch({
      type: 'SET_SELECTED',
      payload,
    });
  };

  const onCloseModal = () => {
    dispatch({ type: 'SET_SELECTED', selected: null });
  };

  const queryClient = useQueryClient();

  const { mutate: requestMutate } = useMutation(requestCashBillIssue, {
    onSuccess: () => {
      queryClient.invalidateQueries('cashbillList');
    },
  });
  const onRequestIssue = () => {
    requestMutate({
      startDate: filter.startDate,
      endDate: filter.endDate,
      keyword: initialSearch,
    });
  };

  const { mutate: updateMutate } = useMutation(updateCashBillStatus, {
    onSuccess: () => {
      queryClient.invalidateQueries('cashbillList');
    },
  });
  const onUpdateStatus = () => {
    updateMutate({
      startDate: filter.startDate,
      endDate: filter.endDate,
      keyword: initialSearch,
    });
  };

  return (
    <>
      <StyledContainer maxWidth='xl'>
        <Title>현금영수증 발급 내역</Title>
        <Search data={[]} placeholder='상호명을 입력하세요.' />
        <PriceWrapper>
          <PriceSection>
            <PriceTitle>
              바로빌 발급 금액
              <PriceTooltip>
                <TooltipButton>
                  <Icon.QuestionFilled size={24} fillColor='#CBCCCE' />
                </TooltipButton>
                <Tooltip>
                  바로빌에서 국세청으로 현금영수증 전송을 완료한 금액
                </Tooltip>
              </PriceTooltip>
            </PriceTitle>
            <PriceData>{comma(data?.amount?.cashBillAmount || 0)}원</PriceData>
          </PriceSection>
          <PriceSection>
            <PriceTitle>
              차감결제 환급액
              <PriceTooltip>
                <TooltipButton>
                  <Icon.QuestionFilled size={24} fillColor='#CBCCCE' />
                </TooltipButton>
                <Tooltip>차감결제 건의 환급액</Tooltip>
              </PriceTooltip>
            </PriceTitle>
            <PriceData>{comma(data?.amount.refundAmount || 0)}원</PriceData>
          </PriceSection>
        </PriceWrapper>
        <DataSelection
          initialSize={initialSize}
          filter={selection as CASHBILL_STATUS[]}
          setFilter={setSelection}
          dispatch={dispatch}
          dateFilter={dateFilter}
          onRequestIssue={onRequestIssue}
          onUpdateStatus={onUpdateStatus}
        />
        <DataTable
          column={BAROBILL_TABLE_HEADER}
          row={data?.refundInfo.content || []}
          onOpenModal={onOpenModal}
        />
        {totalPage > 0 && (
          <Pagination
            page={page}
            totalPage={totalPage}
            isLastPage={isLastPage}
            isFirstPage={isFirstPage}
            displaySize={5}
            setPage={(page) => {
              setPage(page);
              updateUrlParams({ page: (page - 1).toString() });
            }}
          />
        )}
      </StyledContainer>
      {selected?.refundIndex && (
        <DetailModal onClose={onCloseModal} selected={selected} />
      )}
    </>
  );
}

const StyledContainer = styled(Container)`
  display: flex;
  flex-direction: column;
  padding-bottom: 50px;
`;

const PriceWrapper = styled.div`
  display: flex;
  flex-wrap: nowrap;
  gap: 20px;
  width: 100%;
  margin-bottom: 40px;
`;

const PriceSection = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 70px;
  padding: 12px 20px;
  background-color: #ffffff;
  font-size: 16px;
  font-weight: 600;
`;

const PriceTitle = styled.dt`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 10px;
  color: #030303;
`;

const PriceData = styled.dd`
  color: #246cf6;
`;

const Tooltip = styled.aside`
  display: flex;
  flex-direction: column;
  gap: 6px;
  position: absolute;
  bottom: 40px;
  left: -60px;
  background-color: #000000cc;
  color: #ffffff;
  padding: 16px;
  border-radius: 4px;
  text-wrap: nowrap;
  z-index: 10;
  opacity: 0;
  visibility: hidden;
  transform: translate3d(0, 10px, 0);
  transition: transform 200ms ease-in-out, opacity 200ms ease-in-out,
    visibility 200ms ease-in-out;
`;

const PriceTooltip = styled.aside`
  position: relative;
  width: 24px;
  height: 24px;

  &:hover {
    ${Tooltip} {
      transform: translate3d(0, 0, 0);
      opacity: 1;
      visibility: visible;

      &:after {
        content: '';
        position: absolute;
        top: 100%;
        left: 70px;
        margin-right: -7px;
        border-width: 7px;
        border-style: solid;
        border-color: #000000cc transparent transparent transparent;
      }
    }
  }
`;

const TooltipButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0;
  padding: 0;
  width: 24px;
  height: 24px;
`;

export default HospitalBarobill;
