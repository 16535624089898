import { TableBody } from '@material-ui/core';
import { useQuery } from 'react-query';
import styled from 'styled-components';
// api
import { getKeywords } from '@/api/kiosk';
import { KioskActiveTypes } from '@/types/kiosk.type';
import useKioskReducer from '@/hooks/kiosk/useKioskReducer';
// Components
import DataTable from '@/components/common/DataTable';
import Search from '@/components/common/Search';
import ViewDataSelection from '@/components/common/ViewDataSelection';
import Pagination from '@/components/common/Pagination';
import Typography from '@/components/common/Typography';
import LoadingView from '@/components/common/LoadingView';
import KioskList from './KioskList';
import KioskFilter from '../../kioskFilter/KioskFilter';
import { kioskStatusData } from '@/constants/kiosk';
import { GetKioskStatusKioskInfo } from '@/types/api/kiosk';

type KioskStatusListProps = {
  kioskActiveTypeFilter: KioskActiveTypes[];
  setKioskActiveTypeFilter: React.Dispatch<
    React.SetStateAction<KioskActiveTypes[]>
  >;
  size: string;
  kioskInfo?: GetKioskStatusKioskInfo;
  totalPage: number;
  currentPage: string;
  isLastPage: boolean;
  isFirstPage: boolean;
};

function KioskStatusList({
  kioskActiveTypeFilter,
  setKioskActiveTypeFilter,
  size,
  kioskInfo,
  totalPage,
  currentPage,
  isLastPage,
  isFirstPage,
}: KioskStatusListProps) {
  const { columns } = kioskStatusData;

  const [dispatch] = useKioskReducer();

  // 키워드 검색
  const { data: keywords } = useQuery('keywords', getKeywords, {
    retry: false,
  });

  return (
    <>
      <Typography
        fontSize='20px'
        fontWeight='500'
        lineHeight='26px'
        margin='0 0 24px 0'
      >
        현금 현황
      </Typography>
      {keywords && <Search data={keywords} />}
      <Wrapper>
        <KioskFilter
          kioskActiveTypes={kioskActiveTypeFilter}
          setKioskActiveType={setKioskActiveTypeFilter}
        />
        <ViewDataSelection options={[10, 100, 500, 10000]} initialSize={size} />
      </Wrapper>
      <DataTable
        columns={columns}
        dispatch={dispatch}
        contentComponent={
          <TableBody>
            {kioskInfo?.content.map((item, idx) => (
              <KioskList
                key={idx}
                idx={idx}
                data={item}
                size={kioskInfo.size}
                number={kioskInfo.number}
                totalElements={kioskInfo.totalElements}
              />
            ))}
          </TableBody>
        }
      />
      <BottomSection>
        {totalPage > 0 && (
          <Pagination
            initialPage={currentPage}
            totalPage={totalPage}
            isLastPage={isLastPage}
            isFirstPage={isFirstPage}
            displaySize={5}
          />
        )}
      </BottomSection>
    </>
  );
}

export default KioskStatusList;

const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 17.5px 0;
`;
const BottomSection = styled.div`
  margin: 20px 0;
`;
