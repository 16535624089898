import CustomTableCell from '@/components/common/CustomTableCell';
import DetailBtn from '@/components/common/DetailBtn';
import useRefundReducer from '@/hooks/ktp/useRefundReducer';
import { ILatestRefundInfoItem } from '@/types/api/ktp';
import {
  comma,
  formattedDepartureStatus,
  formattedPaymentStatus,
  formattedRefundStatus,
} from '@/utils/ktp';
import { TableRow } from '@material-ui/core';
import { format } from 'date-fns';
import styled from 'styled-components';
import DetailModal from '../refund-status/DetailModal';

interface IProps {
  data: ILatestRefundInfoItem;
}
function LatestRefundList({ data }: IProps) {
  const {
    createdDate,
    refundIndex,
    customerName,
    customerNational,
    storeName,
    totalAmount,
    totalRefund,
    refundStatus,
    departureStatus,
    paymentStatus,
    refundUploadS3Path,
    willBeUploadReceipt,
  } = data;
  const [state, dispatch] = useRefundReducer();
  const { filter, selected } = state;

  const onOpenModal = (payload: any) => {
    dispatch({
      type: 'SET_SELECTED',
      payload,
    });
  };
  const onCloseModal = () => {
    dispatch({ type: 'SET_SELECTED', selected: null });
  };
  const isThirdRefund =
    +totalAmount >= 1100000 ||
    customerNational === 'KOR' ||
    refundStatus === 'PRE_APPROVAL';
  return (
    <StyledTableRow>
      <CustomTableCell idx={0} width='90'>
        {refundIndex}
      </CustomTableCell>
      <CustomTableCell width='180'>
        {format(new Date(createdDate), 'yyyy.MM.dd HH:mm')}
      </CustomTableCell>
      <CustomTableCell width='300'>{storeName}</CustomTableCell>
      <CustomTableCell width='90'>{customerNational}</CustomTableCell>
      <CustomTableCell width='230'>{customerName}</CustomTableCell>
      <CustomTableCell width='120'>{comma(totalAmount)}원</CustomTableCell>
      <CustomTableCell width='120'>{comma(totalRefund)}원</CustomTableCell>
      <CustomTableCell width='110'>
        {formattedRefundStatus(refundStatus)}
      </CustomTableCell>
      {filter.type !== 'IMMEDIATE' && (
        <>
          <CustomTableCell width='110'>
            {isThirdRefund ? formattedDepartureStatus(departureStatus) : '-'}
          </CustomTableCell>
          <CustomTableCell
            width='110'
            color={paymentStatus === 'PAYMENT_WAIT' ? '#E42939' : 'none'}
          >
            {formattedPaymentStatus(paymentStatus)}
          </CustomTableCell>
        </>
      )}
      {filter.type === 'AFTER' && (
        <CustomTableCell width='110'>
          {willBeUploadReceipt ? (
            <DetailBtn
              disabled={!refundUploadS3Path}
              color={refundUploadS3Path && '#354EFF'}
              onClick={
                refundUploadS3Path
                  ? () => onOpenModal({ imgUrl: refundUploadS3Path })
                  : () => {}
              }
            >
              {refundUploadS3Path ? '미리보기' : '미업로드'}
            </DetailBtn>
          ) : (
            '-'
          )}
        </CustomTableCell>
      )}
      {filter.type !== 'IMMEDIATE' && (
        <CustomTableCell width='150'>
          {isThirdRefund ? (
            <DetailBtn onClick={() => onOpenModal({ refundIndex })}>
              상세보기
            </DetailBtn>
          ) : (
            '-'
          )}
        </CustomTableCell>
      )}
      {selected?.refundIndex && !selected?.totalRefund && (
        <DetailModal onClose={onCloseModal} selected={selected} />
      )}
    </StyledTableRow>
  );
}
const StyledTableRow = styled(TableRow)`
  background-color: #ffffff;
  :hover {
    background-color: #f7f9fc;
  }
`;
export default LatestRefundList;
