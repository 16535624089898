import Price from '../section/Price';
import AgeGender from '../section/AgeGender';
import PageWrapper from './PageWrapper';
import { ReportAgeGender, ReportPrice } from '@/types/report';

type Props = {
  priceData?: ReportPrice;
  ageGenderData?: ReportAgeGender;
  priceInfo?: any;
};

function Page4({ priceData = {}, ageGenderData = {}, priceInfo = [] }: Props) {
  return (
    <PageWrapper>
      <Price data={priceData} priceInfo={priceInfo} />
      <AgeGender data={ageGenderData} />
    </PageWrapper>
  );
}

export default Page4;
