import { getLatestYear, monthData } from '@/utils/common';
import { Dropdown } from '@successmode-ktp/kui';
import styled from 'styled-components';

type Props = {
  year: string;
  month: string;
  onChangeYear: (year: string) => void;
  onChangeMonth: (month: string) => void;
};

const DateSelector = ({ year, month, onChangeYear, onChangeMonth }: Props) => {
  return (
    <Wrapper>
      <Dropdown
        options={getLatestYear(2024, new Date().getFullYear())}
        selectedValue={year}
        setSelectedValue={onChangeYear}
        size='md'
        style={{
          width: '100px',
        }}
        variant='solid'
      />
      <Dropdown
        options={monthData}
        selectedValue={month}
        setSelectedValue={onChangeMonth}
        size='md'
        style={{
          width: '80px',
        }}
        variant='solid'
      />
    </Wrapper>
  );
};
export default DateSelector;

const Wrapper = styled.div`
  display: flex;
  gap: 12px;
  margin-bottom: 30px;
`;
