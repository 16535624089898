import FlexWrap from '@/components/common/FlexWrap';
import React from 'react';
import styled from 'styled-components';
import { SubTitle, TransferContainer } from './DetailContent';
import Typography from '@/components/common/Typography';
import { TransferInput } from './RemittanceStatusRadio';
import { TextField } from '@material-ui/core';
import { formatDatetimeForInput } from '@/utils/common';

type Props = {
  detailRefundInfo: any;
  isEdit: boolean;
  setData: React.Dispatch<React.SetStateAction<unknown>>;
};

const MAIL_STATUS_LIST = [
  {
    name: '미발송',
    value: 0,
  },
  {
    name: '1회',
    value: 1,
  },
  {
    name: '2회',
    value: 2,
  },
  {
    name: '3회/완료',
    value: 3,
  },
];

function MailingStatusRadio({ detailRefundInfo, isEdit, setData }: Props) {
  const onChangeMail = (mailCount: number) => {
    setData((prev: any) => {
      return {
        ...prev,
        detailRefundInfo: {
          ...prev.detailRefundInfo,
          mailSendCount: mailCount,
        },
      };
    });
  };

  const onChangeMailSendDate = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    setData((prev: any) => {
      return {
        ...prev,
        detailRefundInfo: {
          ...prev.detailRefundInfo,
          lastSentDate: e.target.value,
        },
      };
    });
  };

  return (
    <Wrapper>
      <SubTitle>메일 발송 내역</SubTitle>
      <TransferContainer dir='row' gap='20px'>
        {MAIL_STATUS_LIST.map((status) => {
          return (
            <FlexWrap>
              <TransferInput
                type='radio'
                checked={detailRefundInfo.mailSendCount === status.value}
                onChange={() => onChangeMail(status.value)}
                disabled={!isEdit}
                isRadio
              />
              <label>{status.name}</label>
            </FlexWrap>
          );
        })}
        <Typography
          fontSize='16px'
          lineHeight='150%'
          fontWeight='500'
          color='#A7A7A7'
        >
          (최근 발송일)
        </Typography>
        <TextField
          id='datetime-local'
          disabled={!isEdit}
          onChange={onChangeMailSendDate}
          type='datetime-local'
          value={
            detailRefundInfo.lastSentDate
              ? formatDatetimeForInput(new Date(detailRefundInfo.lastSentDate))
              : ''
          }
          InputLabelProps={{
            shrink: true,
          }}
        />
      </TransferContainer>
    </Wrapper>
  );
}

export default MailingStatusRadio;

const Wrapper = styled(FlexWrap)`
  position: relative;
  flex: 1;
  align-items: center;
`;
