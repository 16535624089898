import React, { useCallback } from 'react';
import styled from 'styled-components';
import { TableRow, Typography } from '@material-ui/core';
import { formattedFranchiseeStatus } from '../../../utils/ktp';
import CustomTableCell from '../../common/CustomTableCell';
import { format } from 'date-fns';
import DetailBtn from '../../common/DetailBtn';
import Icon from '@/components/common/Icon';
import checkIcon from '@/assets/common/check.png';
import checkedIcon from '@/assets/common/checked.png';

// REAPPLIED | REJECTED | WAIT | ACCEPTED | CANCEL | INIT
const FranchiseeStatusItem = ({
  franchisee,
  onOpen,
  idx,
  checkedFranchisee,
  onCheck,
}) => {
  const {
    refundOnce,
    franchiseeStatus,
    businessNumber,
    storeName,
    sellerName,
    createdDate,
    isRead,
    franchiseeIndex,
    appVersion,
    globalPayOnly,
  } = franchisee;
  const onOpenRefund = useCallback(() => {
    onOpen('REFUND', {
      franchiseeIndex,
      storeName,
    });
  }, [onOpen, storeName, franchiseeIndex]);

  //detail api 수정되면 변경해야할 부분. isRead, createdDate 제거
  const onOpenDetail = useCallback(() => {
    onOpen('DETAIL', {
      franchiseeIndex,
      franchiseeStatus,
      businessNumber,
      isRead,
      createdDate,
      globalPayOnly,
    });
  }, [
    onOpen,
    franchiseeIndex,
    franchiseeStatus,
    businessNumber,
    createdDate,
    isRead,
    globalPayOnly,
  ]);
  return (
    <StyledTableRow
      style={{
        borderBottom:
          (idx + 1) % 5 === 0
            ? '2px solid rgb(220, 220, 220)'
            : '1px solid rgb(220, 220, 220)',
      }}
    >
      <CustomTableCell idx={0} width='40'>
        <CheckBox
          onClick={() => onCheck({ id: franchiseeIndex, name: storeName })}
        >
          <Icon
            imgUrl={
              checkedFranchisee.some((item) => item.id === franchiseeIndex)
                ? checkedIcon
                : checkIcon
            }
            width='20px'
            height='20px'
          />
        </CheckBox>
      </CustomTableCell>
      <CustomTableCell idx={0} width='90'>
        {isRead === false && <Typography color='error'>New</Typography>}
      </CustomTableCell>
      <CustomTableCell width='90'>{franchiseeIndex}</CustomTableCell>
      <CustomTableCell width='100'>
        {formattedFranchiseeStatus(franchiseeStatus)}
      </CustomTableCell>
      <CustomTableCell width='170'>
        {format(new Date(createdDate), 'yyyy.MM.dd HH:mm')}
      </CustomTableCell>
      <CustomTableCell width='140'>{businessNumber}</CustomTableCell>
      <CustomTableCell width='70'>{sellerName}</CustomTableCell>
      <CustomTableCell width='170'>{storeName}</CustomTableCell>
      <CustomTableCell width='100'>{appVersion || '-'}</CustomTableCell>
      <CustomTableCell width='100'>
        {refundOnce ? (
          <DetailBtn onClick={onOpenRefund}>상세보기</DetailBtn>
        ) : (
          '-'
        )}
      </CustomTableCell>
      <CustomTableCell width='100'>
        <DetailBtn onClick={onOpenDetail}>상세보기</DetailBtn>
      </CustomTableCell>
      <CustomTableCell width='100'>
        <Text color={globalPayOnly ? '#246CF6' : undefined}>
          {globalPayOnly ? '간편결제용' : '일반환급용'}
        </Text>
      </CustomTableCell>
    </StyledTableRow>
  );
};
const StyledTableRow = styled(TableRow)`
  background-color: #ffffff;
  :hover {
    background-color: #f7f9fc;
  }
`;
const Text = styled.span`
  color: ${(props) => props.color || '#030303'};
  font-size: ${(props) => props.size || '13px'};
`;

const CheckBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;
export default FranchiseeStatusItem;
