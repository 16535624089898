import React, { Dispatch, SetStateAction } from 'react';
import { useForm } from 'react-hook-form';
import { useMutation, useQueryClient } from 'react-query';
import styled from 'styled-components';
import { format } from 'date-fns';

import { updatePaymentStatus } from '@/api/ktp';
import Typography from '@/components/common/Typography';
import { foramttedDateHypen } from '@/utils/common';
import { updateMedicalPaymentStatus } from '@/api/medical';
import { DatasType } from '@/components/ktp/remmitance-status/RemittanceStatusList';

type RemittancePaymentModalProps = {
  selectedDatas: DatasType[];
  setIsModal: Dispatch<SetStateAction<boolean>>;
  isMedical?: boolean;
};

function RemittancePaymentModal({
  selectedDatas,
  setIsModal,
  isMedical,
}: RemittancePaymentModalProps) {
  const queryClient = useQueryClient();
  const { mutate: remittaceCompleteMutation } = useMutation<any, any, any>(
    [
      isMedical
        ? 'medicalRemittanceStautsComplete'
        : 'remittanceStautsComplete',
    ],
    (payload) =>
      isMedical
        ? updateMedicalPaymentStatus(payload)
        : updatePaymentStatus(payload),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(
          isMedical ? 'medicalRemittanceList' : 'remittanceList',
        );
      },
    },
  );
  const {
    register,
    handleSubmit,
    setError,
    formState: { errors },
  } = useForm();
  const onClickRemittanceComplete = ({ remittanceDate }: any) => {
    const todayDate = format(new Date(), 'yy-MM-dd').replace(/[^0-9]/g, '');
    let transferDate = '';
    if (!remittanceDate) {
      transferDate = todayDate;
    } else if (remittanceDate <= todayDate) {
      transferDate = remittanceDate;
    } else {
      setError('remittanceDate', { message: '오늘 이전 날짜를 입력해주세요.' });
    }

    const newArray = selectedDatas.map((item) => ({
      refundIndex: item.refundId,
      transferDate: foramttedDateHypen('20' + transferDate),
    }));
    const payload = {
      paymentStatus: 'PAYMENT_COMPLETE',
      data: newArray,
    };
    remittaceCompleteMutation(payload);
    setIsModal(false);
  };

  return (
    <Container>
      <Typography fontSize='21px' fontWeight='700' lineHeight='31.5px'>
        송금 상태를 변경합니다
      </Typography>
      <List>
        {selectedDatas.map((data, idx) => (
          <Item>
            No. {data.refundId} {idx !== selectedDatas.length - 1 && '/'}
          </Item>
        ))}
      </List>
      <InputWrapper>
        <Typography
          fontSize='16px'
          fontWeight='600'
          lineHeight='24px'
          color='#3A3B3E'
        >
          송금일 입력
        </Typography>
        <Input
          {...register('remittanceDate', {
            pattern: {
              value: /^[0-9]*$/,
              message: '숫자만 입력하세요.',
            },
          })}
          placeholder='YYMMDD'
          maxLength={6}
        />
        {errors.remittanceDate?.message ? (
          <ErrorMessage>{errors.remittanceDate?.message}</ErrorMessage>
        ) : (
          <Typography
            fontSize='12px'
            fontWeight='400'
            lineHeight='18px'
            color='#5F6165'
          >
            미입력시 오늘 날짜로 등록됩니다.
          </Typography>
        )}
      </InputWrapper>
      <ButtonWrapper>
        <Button isCancelBtn onClick={() => setIsModal(false)}>
          취소
        </Button>
        <Button onClick={handleSubmit(onClickRemittanceComplete)}>
          송금 완료
        </Button>
      </ButtonWrapper>
    </Container>
  );
}

const Container = styled.div`
  padding: 24px 32px;
  background-color: #fff;
  position: absolute;
  right: 160px;
  border-radius: 16px;
  box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.2);
  z-index: 100;
  width: 480px;
`;
const List = styled.div`
  display: flex;
  gap: 4px;
  flex-wrap: wrap;
  margin: 24px 0;
`;
const Item = styled.div`
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
  color: #030303;
`;
const InputWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;
const Input = styled.input`
  padding: 16px;
  outline: none;
  border: 1.5px solid #cbccce;
  border-radius: 4px;
  font-size: 14px;
  line-height: 21px;
  font-weight: 500;
  ::placeholder {
    color: #cbccce;
  }
`;
const ButtonWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 20px;
  margin-top: 24px;
`;
const Button = styled.div<{ isCancelBtn?: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
  padding: 14px 0;
  background-color: ${(props) => (props.isCancelBtn ? '#E5E6E8' : '#246CF6')};
  color: ${(props) => (props.isCancelBtn ? '#3A3B3E' : '#FFF')};
  border-radius: 8px;
  cursor: pointer;
  :hover {
    opacity: 0.8;
  }
`;
const ErrorMessage = styled.span`
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
  color: #ff2d55;
`;
export default RemittancePaymentModal;
