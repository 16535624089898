import React from 'react';
import ConfirmModal from './ConfirmModal';
import styled from 'styled-components';

type Props = {
  onKeep: () => void;
  onDelete: () => void;
  title: string;
  message: string;
};

function DeleteModal({ onKeep, onDelete, title, message }: Props) {
  return (
    <ConfirmModal
      title={title}
      onCancel={onKeep}
      onOK={onDelete}
      cancelLabel='닫기'
      okLabel='삭제'
    >
      <Message>{message}</Message>
    </ConfirmModal>
  );
}

const Message = styled.p`
  font-size: 18px;
  font-weight: 500px;
  line-height: 27px;
  color: #5f6165;
  text-align: center;
`;

export default DeleteModal;
