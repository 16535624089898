import React from 'react';
import styled from 'styled-components';

type Props = {
  imageUrl: string;
  onOpenPreview: (url: string) => void;
};

function ImageButton({ imageUrl, onOpenPreview }: Props) {
  return (
    <Button key={imageUrl} onClick={() => onOpenPreview(imageUrl)}>
      <Image src={imageUrl} alt='' />
    </Button>
  );
}

const Button = styled.button`
  height: 100%;
  aspect-ratio: 1/1;
  overflow: hidden;
  border-radius: 6px;
`;

const Image = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

export default ImageButton;
