import React from 'react';
import format from 'date-fns/format';
import { TableCell, TableRow } from '@material-ui/core';
import { formattedRefundStatus } from '../../../utils/ktp';

const RefundItem = ({ refund, idx }) => {
  const {
    createdDate,
    refundIndex,
    refundStatus,
    totalAmount,
    actualAmount,
    totalRefund,
  } = refund;

  return (
    <TableRow
      style={
        (idx + 1) % 5 === 0
          ? { borderBottom: '2px solid rgb(220, 220, 220)' }
          : { borderBottom: '1px solid rgb(220, 220, 220)' }
      }
    >
      <TableCell
        style={{ padding: 8, fontSize: 13 }}
        component={'th'}
        align='center'
      >
        {refundIndex}
      </TableCell>
      <TableCell style={{ padding: 8, fontSize: 13 }} align='center'>
        {formattedRefundStatus(refundStatus)}
      </TableCell>
      <TableCell style={{ padding: 8, fontSize: 13 }} align='center'>
        {format(new Date(createdDate), 'yyyy년 MM월 dd일 HH시 mm분')}
      </TableCell>
      <TableCell style={{ padding: 8, fontSize: 13 }} align='center'>
        {(+totalAmount).toLocaleString('ko-KR')}원
      </TableCell>
      <TableCell style={{ padding: 8, fontSize: 13 }} align='center'>
        {(+totalRefund).toLocaleString('ko-KR')}원
      </TableCell>

      <TableCell style={{ padding: 8, fontSize: 13 }} align='center'>
        {(+actualAmount).toLocaleString('ko-KR')}원
      </TableCell>
    </TableRow>
  );
};

export default RefundItem;
