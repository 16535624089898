import { format } from 'date-fns';
import styled from 'styled-components';
import { TableRow } from '@material-ui/core';

import CustomTableCell from '@/components/common/CustomTableCell';
import { INoticeItem } from '@/types/api/ktp';
import useNoticeReducer from '@/hooks/ktp/useNoticeReducer';

import { useCallback } from 'react';
import DetailBtn from '@/components/common/DetailBtn';
import DetailModal from '../notice/DetailModal';

interface IProps {
  data: INoticeItem;
  idx: number;
}
function PrimaryNoticeList({ data, idx }: IProps) {
  const { noticeIndex, title, scheduledDate, content } = data;
  const [state, dispatch] = useNoticeReducer();
  const { isModal, selectedData } = state as any;

  const onCloseModal = useCallback(() => {
    dispatch({ type: 'CLOSE_MODAL' });
  }, []);

  const onOpenModal = useCallback((type, noticeIndex) => {
    dispatch({ type: 'OPEN_MODAL', payload: { type, noticeIndex } });
  }, []);

  return (
    <StyledTableRow>
      <CustomTableCell width='90' idx={0}>
        {idx + 1}
      </CustomTableCell>
      <CustomTableCell width='365'>
        <Title>{title}</Title>
      </CustomTableCell>
      <CustomTableCell width='610'>
        <Title>
          {content.length > 52 ? content.slice(0, 51) + '...' : content}
        </Title>
      </CustomTableCell>
      <CustomTableCell width='300' isCenter>
        {format(new Date(scheduledDate), 'yyyy.MM.dd')}
      </CustomTableCell>
      <CustomTableCell width='100'>
        <DetailBtn onClick={() => onOpenModal('DETAIL', noticeIndex)}>
          상세보기
        </DetailBtn>
      </CustomTableCell>
      {isModal && selectedData.type === 'DETAIL' && (
        <DetailModal selectedData={selectedData} onCloseModal={onCloseModal} />
      )}
    </StyledTableRow>
  );
}
const StyledTableRow = styled(TableRow)`
  background-color: #ffffff;
  :hover {
    background-color: #f7f9fc;
  }
`;
const Title = styled.span``;
export default PrimaryNoticeList;
