import React, { ChangeEventHandler, Dispatch, useState } from 'react';
import styled, { css } from 'styled-components';
import { FormControl, Select, MenuItem } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { attachedSlash } from '@/utils/ktp';
import FlexWrap from '@components/common/FlexWrap';
import ActionBtn from '@components/common/ActionBtn';
import { format, subMonths, subWeeks } from 'date-fns';
import useUpdateUrlParams from '@/hooks/sales/useUpdateUrlParams';

const useStyles = makeStyles((theme) => ({
  FormControl: {
    width: 100,
    textAlign: 'center',
    marginRight: 24,
  },
  DateControl: {
    width: 140,
    fontSize: 14,
    borderBottom: 0,
  },
  Button: {
    padding: 2,
    marginLeft: 16,
  },
  Select: {
    fontSize: 14,
  },
}));

interface IProps {
  dateFilter: 'All' | 'WEEK' | 'MONTH' | 'THREE_MONTH' | 'SIX_MONTH' | 'CUSTOM';
  dispatch: Dispatch<any>;
  top?: string;
  right?: string;
  position?: 'static' | 'relative' | 'absolute';
  refetch?: any;
}
function HospitalDateSelector({
  dateFilter,
  dispatch,
  refetch,
  ...divProps
}: IProps) {
  const updateUrlParams = useUpdateUrlParams();
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');

  const handleStartDateChange:
    | ChangeEventHandler<HTMLInputElement>
    | undefined = (e) => {
    setStartDate(attachedSlash(e.target.value));
  };
  const handleEndDateChange:
    | ChangeEventHandler<HTMLInputElement>
    | undefined = (e) => {
    setEndDate(attachedSlash(e.target.value));
  };

  const getStartDate = (value: string) => {
    if (value === 'WEEK') {
      return format(subWeeks(new Date(), 1), 'yyyy-MM-dd');
    }
    const month = value === 'MONTH' ? 1 : value === 'THREE_MONTH' ? 3 : 6;
    return format(subMonths(new Date(), month), 'yyyy-MM-dd');
  };

  const onChange = (e: any) => {
    const { value } = e.target;
    dispatch({
      type: 'SET_DATEFILTER',
      payload: value,
    });

    const startDate = value === 'ALL' ? '2022-01-01' : getStartDate(value);
    dispatch({
      type: 'SET_FILTER_DATE',
      payload: {
        startDate,
        endDate: format(new Date(), 'yyyy-MM-dd'),
      },
    });

    refetch && refetch();

    updateUrlParams({ page: '0' });
  };
  const onApply = () => {
    if (dateFilter === 'CUSTOM') {
      dispatch({
        type: 'SET_FILTER_DATE',
        payload: {
          startDate: '20' + startDate.replace(/\//g, '-'),
          endDate: '20' + endDate.replace(/\//g, '-'),
        },
      });
    }
  };
  const classes = useStyles();

  return (
    <DateContainer {...divProps}>
      <FormControl className={classes.FormControl}>
        <Select
          className={classes.Select}
          value={dateFilter}
          onChange={onChange}
        >
          <MenuItem className={classes.Select} value='ALL'>
            전체기간
          </MenuItem>
          <MenuItem className={classes.Select} value='WEEK'>
            지난 7일
          </MenuItem>
          <MenuItem className={classes.Select} value='MONTH'>
            지난 1개월
          </MenuItem>
          <MenuItem className={classes.Select} value='THREE_MONTH'>
            지난 3개월
          </MenuItem>
          <MenuItem className={classes.Select} value='SIX_MONTH'>
            지난 6개월
          </MenuItem>
          <MenuItem className={classes.Select} value='CUSTOM'>
            기간선택
          </MenuItem>
        </Select>
      </FormControl>
      <FlexWrap margin='0 20px 0 0'>
        {dateFilter === 'CUSTOM' && (
          <>
            <StyledInput
              placeholder='YY/MM/DD'
              value={startDate}
              onChange={handleStartDateChange}
              maxLength={8}
            />
            <StyledText>~</StyledText>
            <StyledInput
              placeholder='YY/MM/DD'
              value={endDate}
              onChange={handleEndDateChange}
              maxLength={8}
            />
          </>
        )}
        {dateFilter === 'CUSTOM' && (
          <ActionBtn
            size='small'
            title='적용'
            onClick={onApply}
            height='26px'
            margin='0 0 0 8px'
          />
        )}
      </FlexWrap>
    </DateContainer>
  );
}
const DateContainer = styled(FlexWrap)<{
  top?: string;
  right?: string;
  position?: 'static' | 'relative' | 'absolute';
}>`
  justify-content: flex-end;
  align-items: center;
  position: ${(props) => props.position || 'absolute'};
  ${(props) =>
    props.top &&
    css`
      top: ${props.top};
    `}
  ${(props) =>
    props.right &&
    css`
      right: ${props.right};
    `}
`;
const StyledText = styled.span`
  margin: 0 10px;
`;
const StyledInput = styled.input`
  width: 100px;
  padding: 4px 8px;
  border: 1px solid lightgray;
`;

export default HospitalDateSelector;
