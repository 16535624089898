import ICON_SEARCH from '@/assets/common/Search.png';
import ICON_DOWN_ARROW from '@/assets/common/arrow_drop_down.png';
import ICON_CLOSE from '@/assets/common/close.png';
import { ProductTypeList as PRODUCT_LIST } from '@/constants/medical';
import { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';

type ProductType = {
  key: number;
  name: string;
  value: number;
};

type Props = {
  productTypes: any;
  setProductTypes: any;
  isEdit: boolean;
};

const ProductTypeSelection = ({
  productTypes,
  setProductTypes,
  isEdit,
}: Props) => {
  const [isOpen, setIsOpen] = useState(false);
  const [productList, setProductList] = useState<ProductType[]>(PRODUCT_LIST);
  const [selectedProduct, setSelectedProduct] = useState<ProductType[]>([]);
  const dropdownRef = useRef<HTMLDivElement | null>(null);

  const selectProductType = (productType: ProductType) => {
    const remainedProduct = productList.filter((product) => {
      return product.value !== productType.value;
    });
    const result = [...selectedProduct, productType].map((product) => {
      return String(product.value);
    });
    setProductTypes(result);
    setSelectedProduct([...selectedProduct, productType]);
    setProductList(remainedProduct);
  };

  const removeProductType = (productType: ProductType) => {
    const remainedProduct = selectedProduct.filter((product) => {
      return product.value !== productType.value;
    });
    const alignProductTypeList = [...productList, productType].sort(
      (a, b) => a.value - b.value,
    );
    const result = remainedProduct.map((product) => String(product.value));
    setProductTypes(result);
    setSelectedProduct(remainedProduct);
    setProductList(alignProductTypeList);
  };

  const handleClickOutside = (event: any) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    let selectedList: ProductType[] = [];
    productTypes.map((productType: string) => {
      const result = PRODUCT_LIST.find(
        (product) => product.value === Number(productType),
      );
      if (result) {
        selectedList = [...selectedList, result];
      }
    });
    const remainedProduct = PRODUCT_LIST.filter(
      (product) => !productTypes.includes(String(product.value)),
    );

    setProductList(remainedProduct);
    setSelectedProduct(selectedList);
  }, [productTypes]);

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <Wrapper ref={dropdownRef}>
      <Button
        type='button'
        onClick={() => setIsOpen(!isOpen)}
        disabled={!isEdit}
      >
        <img src={ICON_SEARCH} alt='' width={24} />
        <Badge>
          {selectedProduct.length !== 0
            ? `${selectedProduct[0].name} 외 ${selectedProduct.length - 1}건`
            : '전체'}
        </Badge>
        <img src={ICON_DOWN_ARROW} alt='' width={24} />
      </Button>
      {isOpen && (
        <ListWrapper>
          <SelectedWrapper>
            {selectedProduct.map(({ key, name, value }) => {
              return (
                <SelectedProduct key={key}>
                  <span>
                    {value + 1}. {name}
                  </span>
                  <button
                    type='button'
                    onClick={() => removeProductType({ key, name, value })}
                  >
                    <img src={ICON_CLOSE} alt='삭제' width={12} />
                  </button>
                </SelectedProduct>
              );
            })}
          </SelectedWrapper>
          {selectedProduct.length !== 0 && <DevideLine />}
          {productList.map(({ key, name, value }) => {
            return (
              <li key={key}>
                <ProductWrapper
                  onClick={() => selectProductType({ key, name, value })}
                >
                  <ProductName>
                    {value + 1}. {name}
                  </ProductName>
                </ProductWrapper>
              </li>
            );
          })}
        </ListWrapper>
      )}
    </Wrapper>
  );
};

export default ProductTypeSelection;

const Wrapper = styled.div`
  position: relative;
  width: 100%;
`;

const Button = styled.button`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;

const Badge = styled.span`
  padding: 2px 6px;
  margin-left: 15px;
  margin-right: auto;
  border-radius: 4px;
  background: #cbccce;
  color: #3a3b3e;
  font-size: 12px;
  font-weight: 500;
  letter-spacing: -0.24px;
  white-space: nowrap;
`;

const ListWrapper = styled.ul`
  position: absolute;
  top: 20px;
  width: 100%;
  border: 1px solid gray;
  border-top: none;
  background-color: #e5e6e8;
`;

const SelectedWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding: 16px 12px 4px;
`;

const SelectedProduct = styled.li`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const DevideLine = styled.hr`
  width: 93%;
`;

const ProductWrapper = styled.button`
  display: block;
  width: 100%;
  padding: 5px 12px;
  text-align: left;

  &:hover {
    background-color: #d3d3d3;
  }
`;

const ProductName = styled.span`
  color: var(--Mono-80, #3a3b3e);
  font-size: 14px;
  font-weight: 500;
  letter-spacing: -0.28px;
`;
