import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import styled from 'styled-components';

import DivideLine from '@/components/common/DivideLine';
import Typography from '@/components/common/Typography';
import ModalFilter from '@/components/common/Remittance/ModalFilter';
import { FilterListType, ListType } from './RemittanceFilter';

type RemittanceModalProps = {
  filterList: FilterListType;
  setFilterList: Dispatch<SetStateAction<FilterListType>>;
  currentTransferView: string;
  setIsModal: Dispatch<SetStateAction<boolean>>;
  applyFilter: (value1: string, value2: string, value3: string) => void;
};

function RemittanceModal({
  filterList,
  setFilterList,
  currentTransferView,
  setIsModal,
  applyFilter,
}: RemittanceModalProps) {
  const [orderByFilterList, setOrderByFilterList] = useState<ListType[]>([]);
  const [paymentStatusFilterList, setPaymentStatusFilterList] = useState<
    ListType[]
  >([]);
  const [mainCountFilterList, setMailCountFilterList] = useState<ListType[]>(
    [],
  );
  useEffect(() => {
    setOrderByFilterList(filterList.orderBy);
    setPaymentStatusFilterList(filterList.paymentStatus);
    setMailCountFilterList(filterList.mainCount);
  }, []);

  const onClickSelect = (
    list: ListType[],
    value: string,
    setFilter: Dispatch<SetStateAction<ListType[]>>,
  ) => {
    const newList = list.map((item) => {
      if (item.title === value) {
        return { ...item, isSelected: true };
      } else {
        return { ...item, isSelected: false };
      }
    });
    setFilter(newList);
  };

  // 필터 '송금완료최신순'일때 송금상태 필터 송금완료로 변경
  useEffect(() => {
    const isTransferCompleteDateSelected = orderByFilterList.some(
      (item) => item.value === 'TRANSFER_COMPLETE_DATE' && item.isSelected,
    );

    if (isTransferCompleteDateSelected) {
      const newPaymentList = paymentStatusFilterList.map((item) =>
        item.value === 'PAYMENT_COMPLETE'
          ? { ...item, isSelected: true, isDisable: false }
          : { ...item, isSelected: false, isDisable: true },
      );
      setPaymentStatusFilterList(newPaymentList);
    } else {
      filterList.paymentStatus.forEach((item) => {
        item.isDisable = false;
      });
      setPaymentStatusFilterList(filterList.paymentStatus);
    }
  }, [orderByFilterList]);

  const onClickFilterApply = () => {
    const newFillterArray: string[] = [];
    [
      ...orderByFilterList,
      ...paymentStatusFilterList,
      ...mainCountFilterList,
    ].forEach((item) => {
      if (item.isSelected) {
        newFillterArray.push(item.value);
      }
    });
    applyFilter(newFillterArray[0], newFillterArray[1], newFillterArray[2]);
    setFilterList({
      ...filterList,
      orderBy: orderByFilterList,
      paymentStatus: paymentStatusFilterList,
      mainCount: mainCountFilterList,
    });
    setIsModal(false);
  };

  return (
    <Container>
      <Typography
        fontSize='21px'
        lineHeight='31.5px'
        fontWeight='700'
        margin='0 0 16px 0'
      >
        필터
      </Typography>
      <DivideLine color='#A7A7A7' />
      {!currentTransferView && (
        <>
          <ModalFilter
            title='정렬'
            filterList={orderByFilterList}
            setFilterList={setOrderByFilterList}
            onClickSelect={onClickSelect}
          />
          <DivideLine color='#E5E6E8' margin='16px 0 0 0' />
          <ModalFilter
            title='송금 상태'
            filterList={paymentStatusFilterList}
            setFilterList={setPaymentStatusFilterList}
            onClickSelect={onClickSelect}
          />
        </>
      )}
      {(currentTransferView === 'TRANSFER_AVAILABLE' ||
        currentTransferView === 'MODIFY_NEEDED') && (
        <ModalFilter
          title='정렬'
          filterList={orderByFilterList}
          setFilterList={setOrderByFilterList}
          onClickSelect={onClickSelect}
        />
      )}
      {currentTransferView === 'MAIL_SEND_NEEDED' && (
        <ModalFilter
          title='횟수'
          filterList={mainCountFilterList}
          setFilterList={setMailCountFilterList}
          onClickSelect={onClickSelect}
        />
      )}
      <DivideLine color='#A7A7A7' margin='16px 0' />
      <ButtonWrapper>
        <Button isCancelBtn onClick={() => setIsModal(false)}>
          취소
        </Button>
        <Button onClick={onClickFilterApply}>적용</Button>
      </ButtonWrapper>
    </Container>
  );
}

const Container = styled.div`
  padding: 32px;
  background-color: #fff;
  position: absolute;
  right: 160px;
  border-radius: 16px;
  box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.2);
  z-index: 100;
  width: 480px;
`;
const ButtonWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 20px;
`;
const Button = styled.div<{ isCancelBtn?: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
  padding: 14px 0;
  background-color: ${(props) => (props.isCancelBtn ? '#E5E6E8' : '#246CF6')};
  color: ${(props) => (props.isCancelBtn ? '#3A3B3E' : '#FFF')};
  border-radius: 8px;
  cursor: pointer;
  :hover {
    opacity: 0.8;
  }
`;
export default RemittanceModal;
