import React, { useCallback } from 'react';
import styled from 'styled-components';

import Modal from '@/components/common/Modal';

function PreviewModal({ onClose, selected, width = '100%' }) {
  const onModalClose = useCallback(() => {
    onClose('DETAIL');
  }, [onClose]);

  return (
    <Modal onClose={onModalClose} width='auto'>
      {selected.imgUrl && <Image src={selected.imgUrl} style={{ width }} />}
    </Modal>
  );
}
const Image = styled.img`
  margin-top: 20px;
`;

export default React.memo(PreviewModal);
