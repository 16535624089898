import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import CloseIcon from '../../assets/common/close.png';
import useModal from '@/hooks/sales/useModal';
import DeleteModal from '../sales/confirmModal/DeleteModal';
import { useMutation } from 'react-query';
import { deleteQuestion as deleteMedicalQuestion } from '@/api/medical';
import { deleteQuestion as deleteKtpQuestion } from '@/api/ktp';
import useGetRequestPath from '@/hooks/common/useGetRequestPath';
import { QNA_MAX_IMAGE_LENGTH } from '@/constants/qna';
import ImageButton from './ImageButton';

type Props = {
  content: string;
  createdDate: string;
  isQuestion: boolean;
  imageUrls: string[];
  onOpenPreviewModal: (payload: any) => void;
  questionId?: number;
};

function ChatBox({
  content,
  createdDate,
  isQuestion,
  imageUrls,
  onOpenPreviewModal,
  questionId,
}: Props) {
  const isMedical = useGetRequestPath(/medical/);

  const textRef = useRef<HTMLTextAreaElement>(null);
  const [height, setHeight] = useState(0);
  const {
    isOpenModal: isOpenDeleteModal,
    openModal: openDeleteModal,
    closeModal: closeDeleteModal,
  } = useModal();

  useEffect(() => {
    if (textRef?.current) {
      setHeight(textRef.current.scrollHeight);
    }
  }, []);

  const formatDate = (date: string) => {
    return date.split('T').join(' ');
  };

  const { mutate: mutateDeleteQuestion } = useMutation(
    isMedical ? deleteMedicalQuestion : deleteKtpQuestion,
  );

  const handleDeleteQuestion = () => {
    if (questionId) {
      mutateDeleteQuestion(questionId, {
        onSuccess: () => {
          closeDeleteModal();
          alert('문의 답변을 삭제하였습니다.');
        },
        onError: () => {
          alert('문의 답변을 삭제하는데 실패하였습니다.');
        },
      });
    }
  };

  const handleOpenPreview = (url: string) => {
    onOpenPreviewModal({ imgUrl: `${url}?${(Math.random() * 10).toString()}` });
  };

  return (
    <Wrapper isQuestion={isQuestion}>
      <Date isQuestion={isQuestion}>{formatDate(createdDate)}</Date>
      <Text
        ref={textRef}
        name=''
        id=''
        value={content}
        disabled
        height={height}
        isQuestion={isQuestion}
      />
      {imageUrls && imageUrls.length > 0 && (
        <ImageList>
          {imageUrls.map((imageUrl) => (
            <ImageButton
              key={imageUrl}
              imageUrl={imageUrl}
              onOpenPreview={handleOpenPreview}
            />
          ))}
          {Array.from(
            { length: QNA_MAX_IMAGE_LENGTH - (imageUrls?.length || 0) },
            (_, index) => (
              <BlankImageSection key={index} aria-hidden></BlankImageSection>
            ),
          )}
        </ImageList>
      )}
      {isQuestion && (
        <DeleteButton onClick={openDeleteModal}>
          <img src={CloseIcon} alt='답변 삭제하기' width={20} />
        </DeleteButton>
      )}
      {isOpenDeleteModal && (
        <DeleteModal
          onKeep={closeDeleteModal}
          onDelete={handleDeleteQuestion}
          title='삭제하시겠습니까?'
          message='작성된 문의 답글이 삭제됩니다.'
        />
      )}
    </Wrapper>
  );
}

export default ChatBox;

type WrapperStyleProps = {
  isQuestion: boolean;
};

type TextStyleProps = {
  height: number;
};

const Wrapper = styled.div<WrapperStyleProps>`
  position: relative;
  display: flex;
  flex-direction: column;
  flex: 1;
  border: 1px solid ${({ isQuestion }) => (isQuestion ? '#5089F8' : '#e5e6e8')};
  border-radius: 8px;
  max-width: 90%;
  padding: 14px 16px;
  background-color: ${({ isQuestion, theme }) =>
    isQuestion ? '#F4F8FF' : '#fff'};
`;

const Date = styled.small<WrapperStyleProps>`
  font-size: 12px;
  font-weight: 400;
  margin-bottom: 8px;
  color: #030303;
`;

const Text = styled.textarea<TextStyleProps & WrapperStyleProps>`
  resize: none;
  border: none;
  height: ${({ height }) => height}px;
  line-height: 150%;
  font-size: 14px;
  font-weight: 500;
  color: #030303;
  &:disabled {
    opacity: 1;
  }
  background-color: ${({ theme, isQuestion }) =>
    isQuestion ? '#F4F8FF' : '#fff'};
`;

const DeleteButton = styled.button`
  position: absolute;
  top: 14px;
  right: 16px;
`;

const ImageList = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  aspect-ratio: 268/64;
  width: 100%;
  overflow-x: auto;
  margin-top: 24px;
`;

const BlankImageSection = styled.div`
  height: 100%;
  aspect-ratio: 1/1;
  overflow: hidden;
  border-radius: 6px;
`;
