import { TextField } from '@material-ui/core';
import { ChangeEvent } from 'react';
import styled from 'styled-components';

type Props = {
  label: string;
  value: string;
  onChange: (val: string) => void;
};

function DateSelection({ label, value, onChange }: Props) {
  const onSelect = (e: ChangeEvent<HTMLInputElement>) => {
    onChange(e.target.value);
  };

  return (
    <div>
      <Label>
        {label}
        <RequiredMark>*</RequiredMark>
      </Label>
      <Box>
        <Input type='date' value={value} onChange={onSelect} />
      </Box>
    </div>
  );
}

export default DateSelection;

const Input = styled(TextField)`
  width: 100%;
  padding: 0;
  outline: 0;
  border: 0;
`;

const Label = styled.label`
  display: block;
  margin-bottom: 8px;
  font-size: 14px;
  font-weight: 600;
  line-height: 21px;
  color: #5f6165;
`;

const RequiredMark = styled.span`
  margin-left: 4px;
  color: #ed0828;
`;

const Box = styled.button`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 8px;
  width: 100%;
  height: 53px;
  border-radius: 6px;
  cursor: pointer;
  outline: 0;
  border: 1px solid #cccccc;
  padding: 0 16px;
  color: #111111;
  transition: all 200ms ease-in-out;
`;

const BoxText = styled.span`
  text-align: left;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
`;
