import { AxiosError } from 'axios';
import { useCallback, useState } from 'react';
import { useMutation } from 'react-query';
import styled, { css } from 'styled-components';

import { globalSignUp } from '@/api/ktp';
import ActionBtn from '@/components/common/ActionBtn';
import Modal from '@/components/common/Modal';
import FlexWrap from '@/components/common/FlexWrap';
import { globalSignUpTableData } from '@/constants/ktp';
import { ErrorType } from '@/types/common';
import debounce from '@/utils/debounce.util';

type GlobalSignUpModalProps = {
  onClose: () => void;
};
type FormData = {
  sellerName: null | string;
  storeTel: null | string;
  businessNumber: null | string;
  email: null | string;
  password: null | string;
  storeName: null | string;
  storeAddressNumber: string;
  storeAddressBasic: null | string;
  storeNumber: null | string;
  businessType: null | string;
  isOwner: boolean;
  isTaxRefundShop: 'N';
  productCategory: '기타';
};
type GlobalPayMethod =
  | 'ALIPAY'
  | 'ALIPAY_PLUS'
  | 'WECHATPAY'
  | 'PAYNOW'
  | 'XNAP'
  | 'THAIQR';
type GlobalPayInfo = {
  mid: string;
  globalPayMethods: Array<GlobalPayMethod>;
};
function GlobalSignUpModal({ onClose }: GlobalSignUpModalProps) {
  const onModalClose = useCallback(() => {
    onClose();
  }, [onClose]);
  const [globalPayInfo, setGlobalPayInfo] = useState<GlobalPayInfo>({
    mid: '',
    globalPayMethods: [],
  });
  const { mid, globalPayMethods } = globalPayInfo;

  const onClickPayBtn = (e: any) => {
    const name = e.target.name as string;
    if (
      globalPayMethods.length === 0 ||
      !globalPayMethods.includes(name as GlobalPayMethod)
    ) {
      //@ts-ignore
      setGlobalPayInfo((prev) => ({
        ...prev,
        globalPayMethods: [...globalPayMethods, name],
      }));
      return;
    }
    setGlobalPayInfo((prev) => ({
      ...prev,
      globalPayMethods: globalPayMethods.filter((v) => v !== name),
    }));
  };
  const [formData, setFormData] = useState<FormData>({
    sellerName: null,
    storeTel: null,
    businessNumber: null,
    email: null,
    password: null,
    storeName: null,
    storeAddressNumber: '',
    storeAddressBasic: null,
    storeNumber: null,
    businessType: null,
    isOwner: true,
    isTaxRefundShop: 'N',
    productCategory: '기타',
  });
  const {
    sellerName,
    storeTel,
    businessNumber,
    email,
    password,
    storeName,
    storeAddressBasic,
    storeNumber,
    businessType,
  } = formData;

  const mutation = useMutation<null, AxiosError<ErrorType>, FormData>(
    (payload) => globalSignUp(payload),
    {
      onSuccess: () => {
        alert('계정 생성이 완료되었습니다.');
        onClose();
      },
      onError: (err) => {
        const code = err?.response?.data?.code;
        let message;
        switch (code) {
          case 'P0002':
            message = '비밀번호 형식에 맞지 않습니다.';
            break;
          case 'B0001':
            message = '사업자번호 형식에 맞지 않습니다.';
            break;
          case 'R0001':
            message = '이미 가입된 사업자등록번호입니다.';
            break;
          default:
            message = '계정 생성에 실패하였습니다. ';
        }
        alert(message);
      },
      retry: false,
    },
  );
  const onChange: any = (e: any) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };
  const onChangeMid = (e: any) => {
    setGlobalPayInfo((prev) => ({ ...prev, mid: e.target.value }));
  };
  const onSubmit = () => {
    const isBtnActive = Boolean(
      sellerName &&
        storeTel &&
        businessNumber &&
        email &&
        password &&
        storeName &&
        storeAddressBasic &&
        storeNumber &&
        businessType &&
        mid &&
        globalPayMethods.length > 0,
    );
    if (!isBtnActive) {
      alert('모든 정보를 입력해주세요.');
    } else if (businessNumber?.length !== 10) {
      alert('사업자등록번호를 10자리로 입력해주세요.');
    } else if (
      !email!.match(/[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*\.[a-zA-Z]{2,3}$/i)
    ) {
      alert('이메일 형식이 옳지 않습니다.');
    } else {
      mutation.mutate({
        ...formData,
        businessNumber: businessNumber.replace(/[^0-9]/g, ''),
        ...globalPayInfo,
      });
    }
  };
  const createAccount = debounce(onSubmit, 200);

  return (
    <Modal onClose={onModalClose}>
      <TableContainer>
        <Title>해외 간편 결제 계정 생성</Title>
        <StyledTable width='100%'>
          {globalSignUpTableData.map((items: any) => (
            <StyledTr>
              {items.map((item: any) => (
                <>
                  <StyledTh>{item.label}</StyledTh>
                  <StyledTd>
                    {item?.selectName ? (
                      <StyledSelect name={item.selectName} onChange={onChange}>
                        {item?.optionList?.map((item: any) => (
                          <option
                            value={typeof item === 'string' ? item : item.value}
                          >
                            {typeof item === 'string' ? item : item.label}
                          </option>
                        ))}
                      </StyledSelect>
                    ) : (
                      <TableInput
                        {...item.option}
                        //@ts-ignore
                        value={formData[item?.option?.name]}
                        onChange={onChange}
                      />
                    )}
                  </StyledTd>
                </>
              ))}
            </StyledTr>
          ))}
        </StyledTable>
        <FlexWrap alignItems='center' margin='12px 0'>
          <Label>MID</Label>
          <Input
            type='text'
            placeholder='ex) M10000024731'
            onChange={onChangeMid}
            value={mid || ''}
          />
        </FlexWrap>
        <FlexWrap gap='8px' margin='0 0 36px' alignItems='center'>
          <Label>결제수단</Label>
          <Button
            name='ALIPAY'
            onClick={onClickPayBtn}
            active={globalPayMethods.includes('ALIPAY')}
          >
            알리페이
          </Button>
          <Button
            name='ALIPAY_PLUS'
            onClick={onClickPayBtn}
            active={globalPayMethods.includes('ALIPAY_PLUS')}
          >
            알리페이플러스
          </Button>
          <Button
            name='WECHATPAY'
            onClick={onClickPayBtn}
            active={globalPayMethods.includes('WECHATPAY')}
          >
            위챗페이
          </Button>
          <Button
            name='PAYNOW'
            onClick={onClickPayBtn}
            active={globalPayMethods.includes('PAYNOW')}
          >
            페이나우
          </Button>
          <Button
            name='XNAP'
            onClick={onClickPayBtn}
            active={globalPayMethods.includes('XNAP')}
          >
            엑스넵
          </Button>
          <Button
            name='THAIQR'
            onClick={onClickPayBtn}
            active={globalPayMethods.includes('THAIQR')}
          >
            타이큐알
          </Button>
        </FlexWrap>
        <ActionBtn
          title='생성하기'
          onClick={createAccount}
          right='0'
          bottom='-8px'
        />
      </TableContainer>
    </Modal>
  );
}

const Title = styled.h3`
  font-size: 18px;
  margin-bottom: 16px;
`;
const TableContainer = styled.div`
  width: 100%;
  position: relative;
`;
const borderStyles = css`
  border-collapse: collapse;
  border: 1px solid black;
`;
const StyledTable = styled.table`
  ${borderStyles}
  margin: 0 0 40px;
`;
const StyledTd = styled.td`
  ${borderStyles}
  padding: 0 16px;
  height: 40px;
  font-size: 14px;
  min-width: 120px;
  width: ${(props) => props.width || 'auto'};
`;
const StyledTh = styled.td`
  ${borderStyles}
  padding-left: 16px;
  height: 40px;
  font-size: 14px;
  line-height: 40px;
  background-color: #d3d3d3;
  width: 170px;
`;

const StyledTr = styled.tr`
  ${borderStyles}
  padding: 8px 15px;
  font-size: 14px;
`;
const TableInput = styled.input`
  border: none;
  outline: none;
  width: 100%;
  padding: 8px 0;
  -moz-appearance: textfield;
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
`;
const Input = styled.input`
  text-transform: uppercase;
  margin-left: 8px;
  border-radius: 4px;
  border: 1px solid lightgray;
  padding: 8px 16px;
  ::placeholder {
    color: lightgray;
  }
`;
const StyledSelect = styled.select`
  padding: 8px;
  border: none;
  outline: none;
`;
const Label = styled.span`
  width: 120px;
  color: #3a3b3e;
`;
const Button = styled.button<{ active: boolean }>`
  border: 1px solid ${(props) => (props.active ? '#354eff' : 'gray')};
  color: ${(props) => (props.active ? '#354eff' : 'gray')};
  padding: 4px 12px;
  border-radius: 15px;
  font-size: 15px;
`;
export default GlobalSignUpModal;
