import styled from 'styled-components';
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
} from 'recharts';
import { CHART_COLORS } from '@/constants/report';
import { countToPercent, nationCodeToName } from '@/utils/report';
import { ReportPrice } from '@/types/report';

type Props = {
  data?: ReportPrice;
  priceInfo?: any;
};

function Price({ data = {}, priceInfo = [] }: Props) {
  const [priceTotalCount, priceCountByNation, priceCountByPriceRange]: any =
    priceInfo;

  const nations = Object.entries(data).map(
    ([nationKey, nationData]) => nationKey,
  );

  const priceList = [
    { value: 'UNDER_100000', label: '~10만' },
    { value: 'UNDER_200000', label: '10만~20만' },
    { value: 'UNDER_300000', label: '20만~30만' },
    { value: 'UNDER_400000', label: '30만~40만' },
    { value: 'UNDER_500000', label: '40만~50만' },
    { value: 'UNDER_700000', label: '50만~70만' },
    { value: 'UNDER_1000000', label: '70만~100만' },
    { value: 'OVER_1000000', label: '100만~' },
  ];

  const chartData = nations.map((nation) => {
    let defaultPriceList = {
      UNDER_100000: 0,
      UNDER_200000: 0,
      UNDER_300000: 0,
      UNDER_400000: 0,
      UNDER_500000: 0,
      UNDER_700000: 0,
      UNDER_1000000: 0,
      OVER_1000000: 0,
    };
    const targetData = Object.entries(data)
      .filter(([nationKey]) => nationKey === nation)
      .map(([_, nationValue]) => nationValue)[0];

    const priceRanges = { ...defaultPriceList, ...targetData };

    const priceRangesWithPercent = Object.entries(priceRanges).reduce(
      (acc, [key, value]) => {
        (acc as any)[key] = countToPercent(
          value,
          (priceCountByNation as any)[nation],
        );
        return acc;
      },
      {},
    );

    return {
      name: nationCodeToName(nation).name,
      ...priceRangesWithPercent,
    };
  });

  const tableRow = priceList.map(({ value, label }, index) => {
    const valueOfNation = nations.reduce((acc, nation) => {
      const nationDetails = Object.entries(data).find(
        ([key, value]) => key === nation,
      );

      if (!nationDetails || !nationDetails[1]) {
        (acc as any)[nation] = 0;
        return acc;
      }

      const nationValue = nationDetails ? nationDetails[1][value] : 0;
      (acc as any)[nation] = nationValue;

      return acc;
    }, {} as any);

    return {
      price: label,
      total: priceCountByPriceRange[value],
      ...valueOfNation,
    };
  });

  return (
    <Section>
      <TableWrapper>
        <TableTitle>
          국적 · 금액구간별<span>(상위 5개국)</span>
        </TableTitle>
        <ChartWrapper>
          <BarChart
            width={690}
            height={200}
            data={chartData}
            margin={{
              top: 20,
              right: 15,
              left: 0,
              bottom: 5,
            }}
          >
            <CartesianGrid stroke='#f1f1f1' vertical={false} />
            <XAxis dataKey='name' fontSize={12} />
            <YAxis
              domain={[0, 100]}
              ticks={[0, 20, 40, 60, 80, 100]}
              unit='%'
              fontSize={12}
            />
            <Tooltip />
            <Legend
              wrapperStyle={{ fontSize: 12 }}
              layout='vertical'
              verticalAlign='middle'
              align='right'
              formatter={(value) => {
                return priceList.find((item) => item.value === value)?.label;
              }}
            />
            {priceList.map(({ value }, index) => (
              <Bar
                key={`${value}-${index}`}
                dataKey={value}
                stackId='a'
                fill={CHART_COLORS[index]}
                isAnimationActive={false}
                barSize={60}
              />
            ))}
          </BarChart>
        </ChartWrapper>
        <TableList>
          <table>
            <thead>
              <tr>
                <th></th>
                <th>합계</th>
                {nations.map((nation) => (
                  <th key={nation}>{nationCodeToName(nation).name}</th>
                ))}
              </tr>
            </thead>
            <tbody>
              {tableRow.map((data, index) => (
                <tr key={index}>
                  <th>{data.price}</th>
                  <td style={{ width: '84px' }}>{data.total}</td>
                  {nations.map((nation) => (
                    <td key={nation} style={{ width: '84px' }}>
                      {(data as any)[nation]}
                    </td>
                  ))}
                </tr>
              ))}
              <tr>
                <th>합계</th>
                <td>{priceTotalCount}</td>
                {nations.map((nation) => (
                  <td key={nation}>{priceCountByNation[nation]}</td>
                ))}
              </tr>
            </tbody>
          </table>
        </TableList>
      </TableWrapper>
    </Section>
  );
}

const Section = styled.section`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 24px;
`;

const TableWrapper = styled.div`
  width: 100%;
`;

const TableTitle = styled.h2`
  display: inline-flex;
  padding: 0 40px;
  align-items: center;
  justify-content: center;
  height: 20px;
  font-size: 14px;
  color: #404040;
  background-color: #bfbfbf;

  span {
    font-size: 12px;
    font-weight: 500;
    margin-left: 4px;
  }
`;

const ChartWrapper = styled.div`
  width: 100%;
  height: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #ffffff;
  border: 1px solid lightgray;
`;

const TableList = styled.div`
  width: 100%;
  display: flex;
  text-align: center;

  table {
    border-collapse: collapse;
    width: 100%;
  }

  tr {
    > :is(th, td):first-child {
      border-left: 0;
    }
    > :is(th, td):last-child {
      border-right: 0;
    }
  }

  th,
  td {
    font-size: 14px;
    height: 22px;
    border: 1px solid lightgray;
  }
`;

export default Price;
