import React, { useCallback, useState } from 'react';
import { useMutation } from 'react-query';
import styled from 'styled-components';
import {
  faCircleCheck,
  faCircleXmark,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { cancelRefund, patchKioskCancel } from '@/api/ktp';
import Modal from '@/components/common/Modal';
import FlexWrap from '@/components/common/FlexWrap';
import { comma } from '@/utils/ktp';
import { cancelRefundMedical } from '@/api/medical';

interface IProps {
  onClose: () => void;
  selected: {
    refundIndex: number;
    storeName: string;
    customerNational: string;
    customerName: string;
    totalRefund: number | string;
    totalAmount: number | string;
    kioskRefund: boolean;
    paymentStatus: string;
    isKioskWithdrawn: boolean;
  };
  isMedical?: boolean;
}
function CancelModal({ onClose, selected, isMedical }: IProps) {
  const {
    refundIndex,
    storeName,
    customerName,
    customerNational,
    totalRefund,
    totalAmount,
    kioskRefund,
    paymentStatus,
    isKioskWithdrawn,
  } = selected;
  const [checkCode, setCheckCode] = useState('');

  const { mutate: ktpCancelMutation } = useMutation(
    () =>
      isMedical
        ? cancelRefundMedical(refundIndex, isKioskWithdrawn)
        : kioskRefund && paymentStatus === 'KIOSK'
          ? patchKioskCancel({ refundIndex })
          : cancelRefund(refundIndex),
    {
      retry: false,
      onSuccess: () => {
        alert('환급이 취소되었습니다.');
        onClose();
      },
      onError: (err: any) => {
        alert(`환급 취소가 실패하였습니다.\n${err?.response?.data?.message}`);
      },
    },
  );
  const onCancel = (e: any) => {
    e.preventDefault();
    if (checkCode !== 'successmode') {
      alert('취소 코드를 입력해주세요.');
      return;
    }
    ktpCancelMutation();
  };
  const onChacnge: React.ChangeEventHandler<HTMLInputElement> = (e) => {
    setCheckCode(e.target.value);
  };
  const onModalClose = useCallback(() => {
    onClose();
  }, [onClose]);

  return (
    <Modal onClose={onModalClose} width='700px'>
      <Title>환급을 취소하시겠습니까?</Title>
      <Text>
        {`\n상호명: ${storeName}\n국적: ${customerNational}\n고객명: ${customerName}\n판매금액: ${comma(
          totalAmount,
        )}원\n환급액: ${comma(totalRefund)}원\n\n취소 코드`}
      </Text>
      <FlexWrap alignItems='flex-end'>
        <Form onSubmit={onCancel} style={{ position: 'relative' }}>
          <Input
            onChange={onChacnge}
            value={checkCode}
            placeholder='successmode'
            spellCheck={false}
            checkCode={checkCode}
          />

          {checkCode === 'successmode' && (
            <FontAwesomeIcon
              icon={faCircleCheck}
              color='#354EFF'
              size='1x'
              style={{ position: 'absolute', right: 8, bottom: 9 }}
            />
          )}
          {checkCode !== '' && (
            <FontAwesomeIcon
              icon={checkCode !== 'successmode' ? faCircleXmark : faCircleCheck}
              color={checkCode !== 'successmode' ? '#E42939' : '#354EFF'}
              size='1x'
              style={{ position: 'absolute', right: 8, bottom: 9 }}
            />
          )}
        </Form>
        <Button onClick={onModalClose}>취소</Button>
        <Button isConfirm onClick={onCancel}>
          확인
        </Button>
      </FlexWrap>
    </Modal>
  );
}
const Form = styled.form``;
const Title = styled.h2``;
const Text = styled.p<{ bold?: boolean }>`
  color: #6a6a65;
  font-size: 14px;
  line-height: 20px;
  white-space: pre-wrap;
`;
const Input = styled.input<{ checkCode: string }>`
  padding: 8px;
  border: 2px solid
    ${(props) =>
      props.checkCode === ''
        ? '#000000'
        : props.checkCode === 'successmode'
          ? '#354EFF'
          : '#E42939'};
  border-radius: 4px;
  width: 400px;
  outline: none;
  margin-top: 4px;
  ::placeholder {
    color: #6a6a65;
  }
`;
const Button = styled.button<{ isConfirm?: boolean }>`
  padding: 8px 16px;
  border-radius: 4px;
  color: ${(props) => (props.isConfirm ? '#ffffff' : '#000000')};
  border: 1px solid ${(props) => (props.isConfirm ? '#000000' : '#D0D0CE')};
  background-color: ${(props) => (props.isConfirm ? '#000000' : '#ffffff')};
  margin-left: 10px;

  :hover {
    opacity: 0.6;
  }
`;
export default React.memo(CancelModal);
