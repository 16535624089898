import { getFeedbackList } from '@/api/ktp';
import DataTable from '@/components/common/DataTable';
import LoadingView from '@/components/common/LoadingView';
import ManagementFilter from '@/components/common/Management/ManagementFilter';
import Title from '@/components/common/Title';
import FeedbackModal from '@/components/ktp/management/FeedbackModal';
import RefundListModal from '@/components/ktp/franchisee-status/RefundListModal';
import ManagementList from '@/components/ktp/management/ManagementList';
import DetailModal from '@/components/ktp/franchisee-status/DetailModal';
import {
  managementNotRefundStatusData,
  managementStatusData,
} from '@/constants/ktp';
import useManagementReducer from '@/hooks/ktp/useManagementReducer';
import useUpdateUrlParams from '@/hooks/sales/useUpdateUrlParams';
import { Container } from '@material-ui/core';
import { Pagination } from '@successmode-ktp/kui';
import { useState } from 'react';
import { useQuery } from 'react-query';
import { useLocation } from 'react-router-dom';
import styled from 'styled-components';
import FeedbackSortingFilter from '@/components/medical/common/FeedbackSortingFIlter';

function Management() {
  const location = useLocation();
  const updateUrlParams = useUpdateUrlParams();
  const searchParams = new URLSearchParams(location.search);
  const initialSearch = searchParams.get('search') || '';
  const initialSize = searchParams.get('size') || '100';
  const initialPage = searchParams.get('page') || '0';
  const [totalPage, setTotalPage] = useState(0);
  const [isFirstPage, setIsFirstPage] = useState(false);
  const [isLastPage, setIsLastPage] = useState(false);
  const [page, setPage] = useState(
    initialPage === '0' ? 1 : Number(initialPage) + 1,
  );
  const [orderBy, setOrderBy] = useState('LAST_REFUND_DATE');
  const [isAsc, setIsAsc] = useState(false);
  const [hasRefund, setHasRefund] = useState(true);

  const [state, dispatch] = useManagementReducer();
  const { filter, selected, isOpenRefund, isOpenDetail, isOpenFeedback } =
    state;
  const { transferView } = filter;

  const { data, isLoading } = useQuery(
    [
      'feedbackList',
      transferView,
      initialPage,
      initialSearch,
      initialSearch,
      initialSize,
      orderBy,
      hasRefund,
      isAsc,
    ],
    () =>
      getFeedbackList({
        feedbackStatusFilter: transferView,
        orderBy: isAsc ? `${orderBy}_ASC` : `${orderBy}_DESC`,
        hasRefund,
        keyword: initialSearch,
        page: Number(initialPage),
        size: Number(initialSize),
      }),
    {
      onSuccess: ({ last, first, totalPages }) => {
        setTotalPage(totalPages);
        setIsFirstPage(first);
        setIsLastPage(last);
      },
      retry: false,
      keepPreviousData: true,
    },
  );

  const onClickChangeTransfer = (value: string) => {
    dispatch({
      type: 'SET_TRANSFERVIEW',
      payload: value,
    });
  };

  const onOpen = (type: 'FEEDBACK' | 'REFUND' | 'DETAIL', selected: any) => {
    dispatch({
      type: 'OPEN_MODAL',
      payload: { type, selected },
    });
  };

  const onClose = (type: 'FEEDBACK' | 'REFUND' | 'DETAIL', selected: any) => {
    dispatch({
      type: 'CLOSE_MODAL',
      payload: { type, selected },
    });
  };

  return (
    <>
      <StyledContainer maxWidth='xl'>
        <Title>매장 관리 대상</Title>
        <FeedbackSortingFilter
          value={orderBy}
          onChange={setOrderBy}
          onChangeSorting={() => setIsAsc((prev) => !prev)}
          hasRefund={hasRefund}
        />
        <ManagementFilter
          currentTransferView={transferView}
          onClickChangeTransfer={onClickChangeTransfer}
          initialSize={initialSize}
          hasRefund={hasRefund}
          onChangeHasRefund={setHasRefund}
        />
        {isLoading ? (
          <LoadingView />
        ) : (
          <>
            <DataTable
              columns={
                hasRefund
                  ? managementStatusData.columns
                  : managementNotRefundStatusData.columns
              }
              contentComponent={
                data?.content ? (
                  <ManagementList
                    datas={data.content}
                    onOpenModal={onOpen}
                    onOpenDetailModal={onOpen}
                    onOpenFeedbackModal={onOpen}
                    hasRefund={hasRefund}
                  />
                ) : (
                  <p>환급 현황이 없습니다.</p>
                )
              }
            />
            <BottomSection>
              {totalPage > 0 && (
                <Pagination
                  page={page}
                  totalPage={totalPage}
                  isLastPage={isLastPage}
                  isFirstPage={isFirstPage}
                  displaySize={5}
                  setPage={(page) => {
                    setPage(page);
                    updateUrlParams({ page: (page - 1).toString() });
                  }}
                />
              )}
            </BottomSection>
          </>
        )}
      </StyledContainer>
      {selected && isOpenRefund && (
        <RefundListModal onClose={onClose} selected={selected} />
      )}
      {selected && isOpenDetail && (
        <DetailModal
          onClose={() => onClose('DETAIL', {})}
          selected={selected}
        />
      )}
      {selected && isOpenFeedback && (
        <FeedbackModal
          onClose={() => onClose('FEEDBACK', {})}
          selected={selected}
        />
      )}
    </>
  );
}

const StyledContainer = styled(Container)`
  display: flex;
  flex-direction: column;
  padding-bottom: 50px;
`;

const BottomSection = styled.div`
  margin: 20px 0;
`;

export default Management;
