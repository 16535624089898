import { ReactNode } from 'react';
import styled from 'styled-components';

type Props = {
  children: ReactNode | ReactNode[];
};
function PageWrapper({ children }: Props) {
  return <Wrapper>{children}</Wrapper>;
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  page-break-after: always;
`;

export default PageWrapper;
