export const CHART_COLORS = [
  '#5878C4',
  '#E38C55',
  '#AAAAAA',
  '#F7C950',
  '#79A2D7',
  '#7FB152',
  '#3D5080',
  '#9E5B34',
  '#737373',
  '#9F803B',
  '#486A98',
  '#5F764C',
  '#5878C4',
  '#E38C55',
  '#AAAAAA',
  '#F7C950',
];
