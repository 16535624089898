import styled from 'styled-components';
import {
  ComposedChart,
  Line,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
} from 'recharts';
import { getKoreanDayValue } from '@/utils/report';
import { comma, removeComma } from '@/utils/ktp';
import { ReportDate } from '@/types/report';

type Props = {
  data?: ReportDate;
};

function Date({ data = [], ...props }: Props) {
  const tableRow = data.map((d) => {
    return {
      date: d.date,
      dayOfWeek: getKoreanDayValue(d.dayRange),
      count: d.count,
      price: comma(d.price),
      avg: comma(d.customerTransaction),
      refund: comma(d.refund),
    };
  });

  const chartData = tableRow.map((data, index) => {
    return {
      name: index + 1,
      price: removeComma(data.price.toString()),
      count: removeComma(data.count.toString()),
    };
  });

  return (
    <Section>
      <TableWrapper>
        <TableTitle>일자별</TableTitle>
        <ChartWrapper>
          <ComposedChart
            width={690}
            height={200}
            data={chartData}
            margin={{
              top: 15,
              right: 5,
              bottom: 10,
              left: 35,
            }}
          >
            <CartesianGrid stroke='#f1f1f1' vertical={false} />
            <XAxis dataKey='name' fontSize={12} />
            <YAxis yAxisId={0} fontSize={12} />
            <YAxis orientation='right' yAxisId={1} fontSize={12} />
            <Tooltip />
            <Legend
              width={640}
              wrapperStyle={{ fontSize: 12 }}
              formatter={(value) => {
                switch (value) {
                  case 'price':
                    return '고객결제금액';
                  case 'count':
                    return '건수';
                  default:
                    return value;
                }
              }}
            />
            <Bar
              dataKey='price'
              barSize={10}
              fill='#2D4374'
              yAxisId={0}
              isAnimationActive={false}
            />
            <Line
              type='monotone'
              dataKey='count'
              stroke='#F6C242'
              yAxisId={1}
              dot={false}
              isAnimationActive={false}
            />
          </ComposedChart>
        </ChartWrapper>
        <TableList>
          <table>
            <thead>
              <tr>
                <th>일자</th>
                <th>요일</th>
                <th>건수</th>
                <th>고객결제금액</th>
                <th>객단가(평균)</th>
                <th>환급액</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <th colSpan={2}>합계</th>
                <td style={{ fontWeight: '700' }}>
                  {comma((props as any).totalCount)}건
                </td>
                <td style={{ fontWeight: '700' }}>
                  {comma((props as any).totalPrice)}원
                </td>
                <td style={{ fontWeight: '700' }}>
                  {comma((props as any).avgCustomerTransaction)}원
                </td>
                <td style={{ fontWeight: '700' }}>
                  {comma((props as any).totalRefund)}원
                </td>
              </tr>
              {tableRow.map((data, index) => (
                <tr key={`${data.dayOfWeek}-${index}`}>
                  <td style={{ textAlign: 'center', padding: '0' }}>
                    {data.date}
                  </td>
                  <td
                    style={{
                      fontWeight: '700',
                      textAlign: 'center',
                      padding: '0',
                    }}
                  >
                    {data.dayOfWeek}
                  </td>
                  <td>{data.count}</td>
                  <td>{data.price}</td>
                  <td>{data.avg}</td>
                  <td>{data.refund}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </TableList>
      </TableWrapper>
    </Section>
  );
}

const Section = styled.section`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const TableWrapper = styled.div`
  width: 100%;
`;

const TableTitle = styled.h2`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 0 40px;
  height: 20px;
  font-size: 14px;
  color: #404040;
  background-color: #bfbfbf;

  span {
    font-size: 12px;
    font-weight: 500;
    margin-left: 4px;
  }
`;

const ChartWrapper = styled.div`
  width: 690px;
  height: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #ffffff;
  border: 1px solid lightgray;
`;

const TableList = styled.div`
  width: 100%;
  display: flex;
  text-align: center;

  table {
    border-collapse: collapse;
    width: 100%;
  }

  tr {
    > :is(th, td):first-child {
      border-left: 0;
    }
    > :is(th, td):last-child {
      border-right: 0;
    }
  }

  th,
  td {
    font-size: 14px;
    height: 21px;
    border: 1px solid lightgray;
  }

  td {
    text-align: right;
    padding-right: 8px;
  }
`;

export default Date;
