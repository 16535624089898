import React, { MouseEventHandler } from 'react';
import styled from 'styled-components';

import FlexWrap from '@/components/common/FlexWrap';
import Close from '@/assets/common/close.png';
import LoadingView from '@/components/common/LoadingView';

interface IProps {
  children?: HTMLElement | any;
  error?: string;
  isLoading?: boolean;
  onClose: any;
  width?: string;
  backDropClickNot?: boolean;
}
function Modal({
  children,
  error,
  isLoading,
  onClose,
  width,
  backDropClickNot,
}: IProps) {
  const onBackDropClick: MouseEventHandler<HTMLDivElement> | undefined = (
    e,
  ) => {
    if (backDropClickNot) return;
    if (e.target !== e.currentTarget) {
      return;
    }
    onClose();
  };
  return (
    <BackDorpContainer onClick={onBackDropClick}>
      <ModalContainer dir='column' width={width}>
        <CloseBtn onClick={onClose}>
          <CloseImg src={Close} onClick={onClose} />
        </CloseBtn>
        <ContentContainer dir='column'>
          {isLoading && <LoadingView />}
          {error && <ErrorText>{error}</ErrorText>}
          {children}
        </ContentContainer>
      </ModalContainer>
    </BackDorpContainer>
  );
}
const BackDorpContainer = styled(FlexWrap)`
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 150;
  background-color: rgba(0, 0, 0, 0.3);
`;
const ModalContainer = styled(FlexWrap)<{ width?: string }>`
  background-color: #ffffff;
  width: ${(props) => props.width || '1280px'};
  max-height: 90%;
  overflow-y: auto;
  border-radius: 4px;
  padding: 50px 30px;
  position: relative;
`;
const ContentContainer = styled(FlexWrap)`
  position: relative;
  width: 100%;
`;
const CloseBtn = styled.button`
  margin-left: auto;
  :hover {
    background-color: rgb(241, 243, 247);
  }
  transition: 300ms all ease-in;
  border-radius: 50%;
  padding: 8px;
  position: absolute;
  top: 8px;
  right: 8px;
`;
const CloseImg = styled.img`
  height: 18px;
  width: 18px;
`;
const ErrorText = styled.p`
  font-size: 16px;
  width: 100%;
  margin-top: 40px;
`;
export default Modal;
