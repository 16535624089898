import countries from 'i18n-iso-countries';

export const nationCodeToName = (code: string) => {
  countries.registerLocale(require('i18n-iso-countries/langs/ko.json'));

  if (code === 'TWN') {
    return {
      code,
      name: '대만',
    };
  }

  const countryNameInKorean = countries.getName(code, 'ko');

  return {
    code,
    name: countryNameInKorean,
  };
};

export const getKoreanDayValue = (day: string) => {
  switch (day) {
    case 'MON':
      return '월';
    case 'TUE':
      return '화';
    case 'WED':
      return '수';
    case 'THU':
      return '목';
    case 'FRI':
      return '금';
    case 'SAT':
      return '토';
    case 'SUN':
      return '일';

    default:
      return '';
  }
};

export const getTimeRange = ({
  time,
  isLast = false,
}: {
  time: number;
  isLast?: boolean;
}) => {
  const startHour = time < 10 ? `0${time}:00` : `${time}:00`;
  const endHour = time + 1 < 10 ? `0${time + 1}:00` : `${time + 1}:00`;

  if (isLast) {
    return `${startHour} ~`;
  }
  return `${startHour} ~ ${endHour}`;
};

export const getTimeRangeShortly = (time: number) => {
  const startHour = time < 10 ? `0${time}` : `${time}`;
  const endHour = time + 1 < 10 ? `0${time + 1}` : `${time + 1}`;

  return `${startHour}~${endHour}`;
};

export const countToPercent = (count: number, total: number) => {
  return Number(((count / total) * 100).toFixed(2));
};
