import BannerItem from '@/components/adSetting/BannerItem';
import {
  DragDropContext,
  Draggable,
  Droppable,
  DropResult,
} from 'react-beautiful-dnd';
import styled from 'styled-components';
import { useMutation } from 'react-query';
import { switchAdList } from '@/api/ad';
import TableHeader from './TableHeader';
import { Ad, SwitchAdPayload } from '@/types/ad.type';

type Props = {
  data: Ad[];
};

const ActivateTable = ({ data }: Props) => {
  const { mutate } = useMutation(
    (payload: SwitchAdPayload) => switchAdList(payload),
    {
      onError: (error: any) => {
        console.error(error);
      },
    },
  );

  const onDragEnd = ({ source, destination }: DropResult) => {
    if (!destination) return;
    const copyBanners = data || [];
    const tempBanners = copyBanners.splice(source.index, 1);
    copyBanners.splice(destination.index, 0, ...tempBanners);
    const payload = copyBanners.map((banner, index) => {
      return {
        advertisementId: banner.advertisementId,
        priority: index + 1,
      };
    });
    mutate(payload);
  };

  return (
    <Content>
      <Title>노출 광고</Title>
      <GuideText>
        배너 광고는 차례대로 노출되며, 우측의 아이콘을 통해 순서 변경이
        가능합니다.
      </GuideText>
      <Table>
        <TableHeader />
        {data.length === 0 ? (
          <NoVideo>
            배너 광고가 없습니다. 배너 광고 업로드를 진행해주세요.
          </NoVideo>
        ) : (
          <DragDropContext onDragEnd={onDragEnd}>
            <div>
              <Droppable droppableId='banner'>
                {(provided) => (
                  <div ref={provided.innerRef} {...provided.droppableProps}>
                    {data.map((item, index) => (
                      <Draggable
                        key={index.toString()}
                        draggableId={index.toString()}
                        index={index}
                      >
                        {(provided) => (
                          <div
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                          >
                            <BannerItem
                              key={index}
                              data={item}
                              index={index}
                              {...provided.dragHandleProps}
                            />
                          </div>
                        )}
                      </Draggable>
                    ))}
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            </div>
          </DragDropContext>
        )}
      </Table>
    </Content>
  );
};
export default ActivateTable;

const Title = styled.h2`
  font-size: 18px;
  font-weight: 700;
  line-height: 26px;
  color: #3a3b3e;
`;

const Content = styled.div`
  padding: 0 40px;
  width: 100%;
`;

const NoVideo = styled.p`
  margin: 100px auto;
  text-align: center;
  color: #80848a;
  font-size: 14px;
`;

const GuideText = styled.p`
  font-size: 14px;
  color: #5f6165;
`;

const Table = styled.table`
  margin: 16px 0 40px;
  width: 100%;
`;
