import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import styled, { css } from 'styled-components';

import Icon from '@/components/common/Icon';
import Typography from '@/components/common/Typography';
import filterIcon from '@assets/common/filter.png';
import RemittanceModal from './RemittanceModal';
import FlexWrap from '@/components/common/FlexWrap';
import RemittancePaymentModal from './RemittancePaymentModal';
import { DatasType } from '../../ktp/remmitance-status/RemittanceStatusList';
import {
  mailCountFilter,
  paymentFilter,
  sortFilterAll,
  sortFilterPayment,
} from '@/constants/ktp';
import { useMutation, useQuery } from 'react-query';
import {
  emittanceDownload,
  emittanceMoinBizDownload,
  updatePaymentStatus,
} from '@/api/ktp';
import { AxiosError } from 'axios';
import {
  IExcelResponse,
  RemittanceDownloadPayload,
  UpdatePaymentsPayload,
} from '@/types/api/medical';
import {
  downloadRemittanceListExcel,
  emittanceMedicalDownload,
  emittanceMoinBizMedicalDownload,
  updateMedicalPaymentStatus,
} from '@/api/medical';
import RemittanceDownloadFile from '@/components/medical/remmitanceStatus/RemittanceDownloadFile';
import {
  convertComponentToImage,
  formattedDate,
  getFormattedTableData,
} from '@/utils/common';
import JSZip from 'jszip';
import ReceiptImage from '@/components/ktp/refund-status/ReceiptImage';
import { formatReceiptForm } from '@/utils/ktp';
import LoadingView from '../LoadingView';
import ViewDataSelection from '@/components/common/ViewDataSelection';
import CertificateSelector from './CertificateSelector';
import ICON_CHECKBOX from '@/assets/common/check.png';
import ICON_CHECKBOX_FILL from '@/assets/common/checked.png';

export type ListType = {
  title: string;
  isSelected: boolean;
  value: string;
  isDisable?: boolean;
};

export type FilterListType = {
  orderBy: ListType[];
  paymentStatus: ListType[];
  mainCount: ListType[];
};

type RemittanceFilterProps = {
  size: string;
  currentTransferView: string;
  onClickChangeTransfer: (value: string) => void;
  onChangeView: (value: number) => void;
  applyFilter: (value1: string, value2: string, value3: string) => void;
  isFiltered: boolean;
  isSelected: boolean;
  selectedDatas: DatasType[];
  isFilterModal: boolean;
  setIsFilterModal: Dispatch<SetStateAction<boolean>>;
  isPaymentModal: boolean;
  setIsPaymentModal: Dispatch<SetStateAction<boolean>>;
  isMedical?: boolean;
  searchParams?: any;
};

const NATIONS_EXCEL_DOWNLOAD = [
  {
    title: '한국 KOR',
    value: 'KOR',
  },
  {
    title: '중국 CHN',
    value: 'CHN',
  },
  {
    title: '일본 JPN',
    value: 'JPN',
  },
];

function RemittanceFilter({
  size,
  currentTransferView,
  onClickChangeTransfer,
  onChangeView,
  applyFilter,
  isFiltered,
  isSelected,
  selectedDatas,
  isFilterModal,
  setIsFilterModal,
  isPaymentModal,
  setIsPaymentModal,
  isMedical = false,
  searchParams,
}: RemittanceFilterProps) {
  const [filterList, setFilterList] = useState<FilterListType>({
    orderBy: [],
    paymentStatus: [],
    mainCount: [],
  });
  const [files, setFiles] = useState([]);
  const [fileDownLoading, setFileDownLoading] = useState(false);
  const [excelNationList, setExcelNationList] = useState<string[]>([]);

  const mutation = useMutation<number, AxiosError, UpdatePaymentsPayload>(
    isMedical ? updateMedicalPaymentStatus : updatePaymentStatus,
    {
      retry: false,
      onSuccess: () => {
        alert(`${selectedDatas.length}건을 송금 중으로 변경했습니다.`);
      },
      onError: () => {
        alert('서버와 연결이 원활하지 않습니다. 다시 시도해주세요.');
      },
    },
  );

  const { mutate: moinBizMutation } = useMutation<
    Blob,
    AxiosError,
    RemittanceDownloadPayload
  >(
    (payload) =>
      isMedical
        ? emittanceMoinBizMedicalDownload(payload)
        : emittanceMoinBizDownload(payload),
    {
      retry: false,
      onSuccess: (res) => {
        const date = formattedDate(new Date());

        const blob = new Blob([res]);
        const url = URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.download = `모인비즈_송금내역_통합_${date}.xlsx`;
        link.click();
        URL.revokeObjectURL(url);
      },
      onError: () => {
        alert('서버와 연결이 원활하지 않습니다. 다시 시도해주세요.');
      },
    },
  );

  const { data: downloadDatas, mutate: downloadMutation } = useMutation<
    any,
    AxiosError,
    RemittanceDownloadPayload
  >(
    (payload) =>
      isMedical
        ? emittanceMedicalDownload(payload)
        : emittanceDownload(payload),
    {
      retry: false,
      onError: () => {
        alert('서버와 연결이 원활하지 않습니다. 다시 시도해주세요.');
      },
    },
  );

  const onClickRemittingBtn = () => {
    mutation.mutate({
      paymentStatus: 'PAYMENT_IN_PROGRESS',
      data: selectedDatas.map((item) => ({ refundIndex: item.refundId })),
    });
  };

  const onClickDownload = (nation?: string) => {
    const ids = selectedDatas.map((item) => item.refundId);

    moinBizMutation({ ids, nation });
    downloadMutation({ ids });
  };

  useEffect(() => {
    const newSortFilter = currentTransferView
      ? sortFilterPayment
      : sortFilterAll;

    setFilterList({
      ...filterList,
      orderBy: newSortFilter,
      paymentStatus: paymentFilter,
      mainCount: mailCountFilter,
    });
  }, [currentTransferView]);

  useEffect(() => {
    if (files.length === downloadDatas?.data.length) {
      handleUploadAndNotify();
    }
  }, [files]);

  const handleUploadAndNotify = async () => {
    setFileDownLoading(true);
    const result = await uploadExcelImage();
    setFileDownLoading(false);
    if (result.success) {
      alert('압축 파일 생성 및 다운로드가 완료되었습니다!');
    } else {
      alert('압축 파일 생성 또는 다운로드 중 오류가 발생하였습니다.');
    }
    setFiles([]);
  };

  const uploadExcelImage = async () => {
    try {
      const zip = new JSZip();

      // 파일들을 순차적으로 처리하기 위해 for...of 루프 사용
      for (const file of files) {
        const blob = await convertComponentToImage(file);
        const index = files.indexOf(file); // 파일의 인덱스 가져오기

        // 이미지 파일 추가 (Blob 객체 직접 전달)
        zip.file(`${index + 1}.png`, blob);
      }

      // 압축 파일 생성
      const zipBlob = await zip.generateAsync({ type: 'blob' });
      const url = URL.createObjectURL(zipBlob);

      const link = document.createElement('a');
      link.href = url;
      link.download = `확인서.zip`;

      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);

      URL.revokeObjectURL(url);

      // 압축 파일 생성 및 다운로드가 성공적으로 완료된 경우
      return { success: true };
    } catch (error) {
      console.error('압축 파일 생성 중 오류 발생:', error);
      // 압축 파일 생성에 실패한 경우
      return { success: false, error };
    }
  };

  const controlNationList = (nation: string) => {
    if (nation === 'ALL') {
      return setExcelNationList([]);
    }
    setExcelNationList((prev) => {
      if (prev.find((prevItem) => prevItem === nation)) {
        return prev.filter((prevItem) => prevItem !== nation);
      }
      return [...prev, nation];
    });
  };

  const { mutate: downloadListExcel } = useMutation(
    ['getRemittanceListExcelDownload'],
    () =>
      downloadRemittanceListExcel({
        transferView: searchParams.transferView || null,
        paymentStatus: searchParams.paymentStatus || null,
        orderBy: searchParams.orderBy,
        page: searchParams.page,
        size: searchParams.size,
        mailSendCounts: searchParams.mailSendCounts || null,
        keyword: searchParams.keyword || null,
        keywordType: searchParams.keywordType || null,
        nations: excelNationList,
      }),
    {
      retry: false,
      onSuccess: (res: any) => {
        const date = formattedDate(new Date());
        const blob = new Blob([res]);
        const url = URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.download = `송금현황목록_${date}.xlsx`;
        link.click();
        URL.revokeObjectURL(url);
      },
    },
  );

  return (
    <>
      <Container>
        <FlexWrap>
          <TransferViewContainer>
            <TransferView
              isActive={currentTransferView === ''}
              onClick={() => onClickChangeTransfer('')}
            >
              전체보기
            </TransferView>
            <TransferView
              isActive={currentTransferView === 'MODIFY_NEEDED'}
              onClick={() => onClickChangeTransfer('MODIFY_NEEDED')}
            >
              정보수정 필요
            </TransferView>
            <TransferView
              isActive={currentTransferView === 'TRANSFER_AVAILABLE'}
              onClick={() => onClickChangeTransfer('TRANSFER_AVAILABLE')}
            >
              송금 가능
            </TransferView>
            <TransferView
              isActive={currentTransferView === 'MAIL_SEND_NEEDED'}
              onClick={() => onClickChangeTransfer('MAIL_SEND_NEEDED')}
            >
              메일 발송 필요
            </TransferView>
          </TransferViewContainer>
          <ButtonWrapper>
            <Remitting
              isSelected={isSelected}
              onClick={onClickRemittingBtn}
              disabled={!isSelected}
            >
              송금중
            </Remitting>
            <RemittanceCompelte
              isSelected={isSelected}
              onClick={() => setIsPaymentModal(true)}
              disabled={!isSelected}
            >
              송금 완료
            </RemittanceCompelte>
            {currentTransferView === 'TRANSFER_AVAILABLE' && (
              <>
                <CertificateSelector title='확인서'>
                  <DownloadButtonWrapper>
                    <CertificateDownload
                      type='button'
                      onClick={() => onClickDownload()}
                    >
                      중국 CHN
                    </CertificateDownload>
                    <CertificateDownload
                      type='button'
                      onClick={() => onClickDownload('jpn')}
                    >
                      일본 JPN
                    </CertificateDownload>
                  </DownloadButtonWrapper>
                </CertificateSelector>
                {isMedical && (
                  <CertificateSelector title='엑셀'>
                    <ExcelWrapper>
                      <ExcelNationWrapper
                        type='button'
                        onClick={() => controlNationList('ALL')}
                      >
                        {excelNationList.length === 0 ? (
                          <img src={ICON_CHECKBOX_FILL} alt='' width={24} />
                        ) : (
                          <img src={ICON_CHECKBOX} alt='' width={24} />
                        )}
                        전체 ALL
                      </ExcelNationWrapper>
                      {NATIONS_EXCEL_DOWNLOAD.map((nation) => {
                        return (
                          <ExcelNationWrapper
                            key={nation.value}
                            type='button'
                            onClick={() => controlNationList(nation.value)}
                          >
                            {excelNationList.find(
                              (item) => item === nation.value,
                            ) ? (
                              <img src={ICON_CHECKBOX_FILL} alt='' width={24} />
                            ) : (
                              <img src={ICON_CHECKBOX} alt='' width={24} />
                            )}
                            {nation.title}
                          </ExcelNationWrapper>
                        );
                      })}
                      <CertificateDownload
                        type='button'
                        onClick={() => downloadListExcel()}
                      >
                        다운로드
                      </CertificateDownload>
                    </ExcelWrapper>
                  </CertificateSelector>
                )}
              </>
            )}
          </ButtonWrapper>
        </FlexWrap>
        <FilterContainer>
          <FilterDetail
            onClick={() => setIsFilterModal(true)}
            isFiltered={isFiltered}
          >
            <Icon imgUrl={filterIcon} width='20px' height='20px' />
            <Typography fontSize='14px' lineHeight='21px' fontWeight='600'>
              필터
            </Typography>
          </FilterDetail>
          <ViewDataSelection initialSize={size} />
        </FilterContainer>
        {isFilterModal && (
          <>
            <RemittanceModal
              filterList={filterList}
              setFilterList={setFilterList}
              currentTransferView={currentTransferView}
              setIsModal={setIsFilterModal}
              applyFilter={applyFilter}
            />
            <Backdrop onClick={() => setIsFilterModal(false)} />
          </>
        )}
        {isPaymentModal && (
          <>
            <RemittancePaymentModal
              selectedDatas={selectedDatas}
              setIsModal={setIsPaymentModal}
              isMedical={isMedical}
            />
            <Backdrop onClick={() => setIsPaymentModal(false)} />
          </>
        )}
        {downloadDatas && isMedical
          ? downloadDatas?.data?.map((file: IExcelResponse, idx: number) => (
              <RemittanceDownloadFile
                key={idx}
                data={getFormattedTableData(file)}
                setFiles={setFiles}
              />
            ))
          : downloadDatas?.data?.map((file: any, idx: number) => (
              <ReceiptImage
                key={idx}
                info={formatReceiptForm(file)}
                index={0}
                nation={file.nation}
                setFiles={setFiles}
                style={{
                  height: '90%',
                  overflowY: 'scroll',
                }}
              />
            ))}
      </Container>
    </>
  );
}

export default RemittanceFilter;

const Backdrop = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 80;
`;

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  position: relative;
  height: 56px;
`;

const TransferViewContainer = styled.div`
  display: flex;
  background-color: #fff;
  align-items: center;
`;

const FilterContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
`;

const FilterDetail = styled.div<{ isFiltered?: boolean }>`
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 4px 32px;
  background-color: ${(props) => (props.isFiltered ? '#A7A7A7' : '#e5e6e8')};
  border-radius: 6px;
  cursor: pointer;
  :hover {
    opacity: 0.8;
  }
`;

const TransferView = styled.div<{ isActive?: boolean }>`
  padding: 16px 32px;
  font-size: 14px;
  line-height: 21px;
  font-weight: 600;
  color: #a7a7a7;
  white-space: nowrap;
  cursor: pointer;
  ${(props) =>
    props.isActive &&
    css`
      color: #1856cd;
      border-bottom: 2px solid #246cf6;
    `}
`;

const ButtonWrapper = styled.div`
  display: flex;
  gap: 8px;
  margin-left: 14px;
  align-items: flex-start;
`;

const Remitting = styled.button<{ isSelected: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 9px 40px;
  white-space: nowrap;
  background-color: ${(props) => (props.isSelected ? '#246CF6' : '#e9f0fe')};
  color: #fff;
  border-radius: 6px;
  font-size: 14px;
  font-weight: 600;
  line-height: 21px;
  cursor: ${(props) => (props.isSelected ? 'pointer' : 'default')};
`;

const RemittanceCompelte = styled(Remitting)`
  background-color: ${(props) => (props.isSelected ? '#3A3B3E' : '#E5E6E8')};
`;

const DownloadButtonWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin: 12px 0 6px;
`;

const CertificateDownload = styled.button`
  padding: 6px 12px;
  border-radius: 4px;
  background: #246cf6;
  color: #fff;
  width: 100%;
  font-size: 14px;
  font-weight: 600;
  line-height: 150%;
  letter-spacing: -0.28px;
`;

const ExcelWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 6px;
  margin: 8px 0;
`;

const ExcelNationWrapper = styled.button`
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #3a3b3e;
  font-size: 14px;
  font-weight: 500;
  line-height: 150%;
  letter-spacing: -0.28px;
`;
