import { InputAdornment, TextField } from '@material-ui/core';
import React, { FormEventHandler, useRef, useState } from 'react';
import { Search as SearchIcon } from '@material-ui/icons';
import styled from 'styled-components';
import { Autocomplete } from '@material-ui/lab';
import match from 'autosuggest-highlight/match';
import parse from 'autosuggest-highlight/parse';
import useUpdateUrlParams from '@/hooks/sales/useUpdateUrlParams';
import { useLocation } from 'react-router-dom';

type Props = {
  data: any;
  placeholder?: string;
};

function Search({ placeholder = '검색', data }: Props) {
  const autoCompleteRef = useRef(null);
  const [inputValue, setInputValue] = useState('');
  const updateUrlParams = useUpdateUrlParams();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const searchKeyword = searchParams.get('search') || '';

  const onSearch: FormEventHandler<HTMLFormElement | HTMLButtonElement> = (
    e,
  ) => {
    e.preventDefault();
    const current = autoCompleteRef.current as any;
    const inputCurrent = current.querySelector('input');
    inputCurrent?.blur();
    updateUrlParams({ search: inputValue?.trim() || '', page: '0' });
  };

  const onChange = (e: any, v: any) => {
    setInputValue(v?.keyword);
  };

  const onInputChange = (e: any, v: any) => {
    setInputValue(v);
  };

  const onResetKeyword = () => {
    setInputValue('');
    updateUrlParams({ search: '', page: '0' });
  };

  return (
    <SearchWrapper>
      <Form onSubmit={onSearch}>
        <Autocomplete
          ref={autoCompleteRef}
          options={data || []}
          onChange={onChange}
          onInputChange={onInputChange}
          getOptionLabel={(option) => option.keyword}
          renderInput={(params) => {
            return (
              <TextField
                {...params}
                variant='outlined'
                style={{ backgroundColor: '#FFFFFF' }}
                placeholder={placeholder}
                InputProps={{
                  className: 'MuiAutocomplete-inputRoot',
                  ref: params.InputProps.ref,
                  startAdornment: (
                    <button onClick={onSearch}>
                      <InputAdornment position='start'>
                        <SearchIcon
                          style={{ marginLeft: 10 }}
                          color='disabled'
                        />
                      </InputAdornment>
                    </button>
                  ),
                  fullWidth: true,
                }}
                size='small'
              />
            );
          }}
          renderOption={(option, { inputValue }) => {
            const matches = match(option.keyword, inputValue);
            const parts = parse(option.keyword, matches);
            return (
              <div>
                {parts.map((part: any, index: number) => (
                  <span
                    key={index}
                    style={{
                      color: part.highlight ? '#27AE60' : 'black',
                    }}
                  >
                    {part.text}
                  </span>
                ))}
              </div>
            );
          }}
        />
      </Form>
      {searchKeyword !== '' && (
        <KeywordContainer>
          <Tag>" {searchKeyword} "</Tag>
          <ResetBtn onClick={onResetKeyword}>X</ResetBtn>
        </KeywordContainer>
      )}
    </SearchWrapper>
  );
}

const SearchWrapper = styled.div`
  display: flex;
  margin-bottom: 24px;
  width: 100%;
`;

const Form = styled.form`
  width: 100%;
`;

const KeywordContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
  text-wrap: nowrap;
`;
const Tag = styled.p<{ isSelectedStoreTag?: boolean }>`
  padding: 4px 10px;
  border-radius: 12px;
  color: white;
  background-color: #757575;
  margin-left: ${(props) => (props.isSelectedStoreTag ? '0' : '18px')};
`;
const ResetBtn = styled.button`
  cursor: pointer;
  border-radius: 50%;
  border: none;
  margin-left: 8px;
  width: 16px;
  height: 16px;
  color: white;
  background-color: #757575;
`;

export default Search;
