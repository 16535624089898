import React, { useState, useEffect } from 'react';
import styled, { css } from 'styled-components';

import FlexWrap from '@/components/common/FlexWrap';
import { convertKindOfPosToKorean } from '@/utils/ktp';
import { useMutation } from 'react-query';
import { updatePosInfo } from '@/api/ktp';

type Props = {
  franchiseeIndex: number;
  posInfo: {
    kindOfPosList: string[];
    posIndexes: {
      [key: string]: string[];
    };
  };
};
function DetailContentPosInfo({ franchiseeIndex, posInfo }: Props) {
  const [newPosInfo, setNewPosInfo] = useState(posInfo);
  const [formData, setFormData] = useState<any>({});

  //@ts-ignore
  const mutation = useMutation((payload) => updatePosInfo(payload), {
    retry: false,
    onSuccess: () => {},
    onError: () => {},
  });

  useEffect(() => {
    const newFormData = {};
    posInfo.kindOfPosList.forEach((v) => {
      //@ts-ignore
      newFormData[v] = '';
    });
    setFormData(newFormData);
  }, []);

  const onChangePosId = (posCompanyName: string, posId: string) => {
    setFormData((prev: any) => ({
      ...prev,
      [posCompanyName]: posId,
    }));
  };

  const onClickAdd = (e: any, type: string) => {
    e.preventDefault();
    const inputData = formData[type];
    if (inputData === '') {
      return;
    }
    const newFormData = {
      ...newPosInfo,
      posIndexes: {
        ...newPosInfo.posIndexes,
        [type]: [...newPosInfo.posIndexes[type], inputData],
      },
    };
    setNewPosInfo((prev: any) => {
      return newFormData;
    });
    setFormData((prev: any) => ({ ...prev, [type]: '' }));
    //@ts-ignore
    mutation.mutate({ franchiseeIndex, ...newFormData });
  };
  const onDeletePodIndex = (posCompanyName: string, posId: string) => {
    if (window.confirm('해당 포스기를 제거하시겠습니까?')) {
      const newFormData = {
        ...newPosInfo,
        posIndexes: {
          ...newPosInfo.posIndexes,
          [posCompanyName]: newPosInfo.posIndexes[posCompanyName].filter(
            (v: string) => v !== posId,
          ),
        },
      };
      setNewPosInfo((prev: any) => {
        return newFormData;
      });
      //@ts-ignore
      mutation.mutate({ franchiseeIndex, ...newFormData });
    }
  };
  return (
    <>
      <Title>연동된 POS사 및 단말기 정보</Title>
      {newPosInfo?.kindOfPosList?.length === 0 ? (
        '계약된 POS사가 존재하지 않습니다.'
      ) : (
        <FlexWrap dir='column'>
          {newPosInfo?.kindOfPosList?.map((posCompanyName, i) => (
            <>
              <Table dir='column'>
                <PosItem key={i}>
                  {convertKindOfPosToKorean(posCompanyName)}
                </PosItem>
                {newPosInfo.posIndexes[posCompanyName]?.length > 0 ? (
                  <>
                    {newPosInfo.posIndexes[posCompanyName]?.map(
                      (posId: string, i: number) => (
                        <>
                          <PosIndexItem key={i}>
                            <span>{posId}</span>
                            <DeletePosIndexBtn
                              onClick={() =>
                                onDeletePodIndex(posCompanyName, posId)
                              }
                            >
                              X
                            </DeletePosIndexBtn>
                          </PosIndexItem>
                          {i ===
                            newPosInfo.posIndexes[posCompanyName]?.length -
                              1 && (
                            <PosIndexItem active>
                              <Form>
                                <span>+</span>
                                <PosIndexInput
                                  placeholder='포스기 식별 번호를 입력해주세요.'
                                  value={formData[posCompanyName]}
                                  onChange={(e) =>
                                    onChangePosId(
                                      posCompanyName,
                                      e.target.value,
                                    )
                                  }
                                />
                                <AddPosIndexBtn
                                  onClick={(e) => onClickAdd(e, posCompanyName)}
                                  disabled={formData[posCompanyName] === ''}
                                >
                                  추가
                                </AddPosIndexBtn>
                              </Form>
                            </PosIndexItem>
                          )}
                        </>
                      ),
                    )}
                  </>
                ) : (
                  <FlexWrap dir='column'>
                    <PosIndexItem active>
                      <Form>
                        <span>+</span>
                        <PosIndexInput
                          placeholder='포스기 식별 번호를 입력해주세요.'
                          value={formData[posCompanyName]}
                          onChange={(e) =>
                            onChangePosId(posCompanyName, e.target.value)
                          }
                        />
                        <AddPosIndexBtn
                          onClick={(e) => onClickAdd(e, posCompanyName)}
                          disabled={formData[posCompanyName] === ''}
                        >
                          추가
                        </AddPosIndexBtn>
                      </Form>
                    </PosIndexItem>
                  </FlexWrap>
                )}
              </Table>
            </>
          ))}
        </FlexWrap>
      )}
    </>
  );
}
const Form = styled.form`
  display: flex;
  align-items: center;
  gap: 8px;
`;
const Table = styled(FlexWrap)`
  border-top: 1px solid black;
  border-left: 1px solid black;
  margin-bottom: 20px;
`;
const PosIndexItem = styled(FlexWrap)<{ active?: boolean }>`
  justify-content: space-between;
  align-items: center;
  width: 300px;
  height: 40px;
  line-height: 40px;
  padding: 0 20px;
  border-right: 1px solid black;
  border-bottom: 1px solid black;
  ${(props) =>
    !props.active &&
    css`
      background-color: #f2f2f2;
    `}
`;
const Title = styled.h3`
  font-size: 18px;
  margin: 40px 0 16px;
`;
const PosItem = styled(PosIndexItem)`
  background-color: #d3d3d6;
`;
const PosIndexInput = styled.input`
  outline: none;
  border: none;
  width: 190px;
`;
const AddPosIndexBtn = styled.button`
  padding: 8px;
  border-radius: 8px;
  background-color: #e9f0fe;
  color: #1856cd;
  border: 1px solid #1856cd;
  :disabled {
    cursor: default;
    opacity: 0.5;
  }
`;
const DeletePosIndexBtn = styled.button`
  width: 18px;
  height: 18px;
  border-radius: 50%;
  line-height: 18px;
  font-size: 12px;
  background-color: #1856cd;
  color: #ffffff;
  :hover {
    opacity: 0.5;
  }
`;
export default DetailContentPosInfo;
