import React, { useState, useEffect, FormEventHandler } from 'react';
import styled from 'styled-components';
import { Container } from '@material-ui/core';
import { useQuery } from 'react-query';
import { getFilteredFranchiseeLists, getSearchKeywords } from '@/api/ktp';
import FranchiseeStatusList from '@components/ktp/franchisee-status/FranchiseeStatusList';
import RefundListModal from '@components/ktp/franchisee-status/RefundListModal';
import DetailModal from '@components/ktp/franchisee-status/DetailModal';
import SignUpModal from '@components/ktp/franchisee-status/SignUpModal';
import CheckboxForm from '@components/common/CheckboxForm';
import store from '@/store';
import { franchiseeStatusData } from '@constants/ktp';
import DataTable from '@components/common/DataTable';
import useFranchiseeReducer from '@hooks/ktp/useFranchiseeReducer';
import Title from '@components/common/Title';
import ActionBtn from '@components/common/ActionBtn';
import LoadingView from '@/components/common/LoadingView';
import GlobalSignUpModal from '@/components/ktp/franchisee-status/GlobalSignUpModal';
import Search from '@/components/common/Search';
import { useLocation } from 'react-router-dom';
import Pagination from '@/components/common/Pagination';
import GenerateReport from '@/components/report/GenerateReport';
import ViewDataSelection from '@/components/common/ViewDataSelection';
import FlexWrap from '@/components/common/FlexWrap';

function FranchiseeStatus() {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const initialSize = searchParams.get('size') || '10';
  const currentPage = searchParams.get('page') || '0';
  const searchKeyword = searchParams.get('search') || '';
  const [state, dispatch] = useFranchiseeReducer();
  const { modalType, selected, toggle, filter } = state;
  const [totalPage, setTotalPage] = useState<number>(0);
  const [isFirstPage, setIsFirstPage] = useState(false);
  const [isLastPage, setIsLastPage] = useState(false);
  const [checkedFranchisee, setCheckedFranchisee] = useState<
    { id: number; name: string }[]
  >([]);
  const { serverState } = store.getState();
  const { columns, franchiseeStatusRadio, isReadRadio } = franchiseeStatusData;

  useEffect(() => {
    setTotalPage(0);
  }, [serverState, searchKeyword, initialSize]);

  const {
    isLoading,
    data: franchiseeData,
    isSuccess,
  } = useQuery(
    ['franchiseeList', filter, currentPage, searchKeyword, initialSize],
    () =>
      getFilteredFranchiseeLists(
        filter,
        currentPage,
        searchKeyword,
        initialSize,
      ),
    {
      onSuccess: ({ totalPages, first, last }) => {
        setTotalPage(totalPages);
        setIsFirstPage(first);
        setIsLastPage(last);
      },
      retry: false,
      keepPreviousData: true,
    },
  );

  const { data: keywords } = useQuery('keywords', getSearchKeywords, {
    retry: false,
  });
  const onOpen = (
    type: 'DETAIL' | 'REFUND' | 'CREATE' | 'CREATE_GLOBAL',
    selected: any,
  ) => {
    dispatch({
      type: 'OPEN_MODAL',
      payload: { type, selected },
    });
  };

  const onClose = () => {
    dispatch({
      type: 'CLOSE_MODAL',
    });
  };

  const onFilter: FormEventHandler<HTMLFieldSetElement> | undefined = (e) => {
    const { name, value } = e.target as any;
    dispatch({ type: 'SET_TOGGLE', payload: null });
    dispatch({
      type: 'SET_FILTER',
      payload: { ...filter, [name]: value },
    });
  };
  const onCreate = () => {
    onOpen('CREATE', null);
  };
  const onCreateGlobal = () => {
    onOpen('CREATE_GLOBAL', null);
  };

  const onCheckFranchisee = ({ id, name }: { id: number; name: string }) => {
    if (checkedFranchisee.some((item) => item.id === id)) {
      setCheckedFranchisee((prev) => prev.filter((item) => item.id !== id));
    } else {
      setCheckedFranchisee((prev) => [...prev, { id, name }]);
    }
  };

  useEffect(() => {
    setCheckedFranchisee([]);
  }, [filter, currentPage, searchKeyword]);

  if (isLoading) {
    return <LoadingView />;
  }

  if (!franchiseeData) {
    return <></>;
  }

  return (
    <StyledContainer maxWidth='xl'>
      <Title>가맹점 현황</Title>
      {keywords && <Search data={keywords} />}
      <FlexWrap
        justifyContent='flex-end'
        gap='16px'
        margin='0 0 20px 0'
        alignItems='center'
      >
        <GenerateReport checkedFranchisee={checkedFranchisee} />
        <ViewDataSelection options={[10, 100, 500]} initialSize={initialSize} />
      </FlexWrap>
      {franchiseeData && (
        <DataTable
          columns={columns}
          data={franchiseeData.content}
          dispatch={dispatch}
          toggle={toggle}
          onCheckFranchisee={setCheckedFranchisee}
          contentComponent={
            <FranchiseeStatusList
              franchisees={franchiseeData.content}
              onOpen={onOpen}
              checkedFranchisee={checkedFranchisee}
              onCheck={onCheckFranchisee}
            />
          }
        />
      )}
      {isSuccess && franchiseeData.content?.length === 0 && (
        <ContentContainer>가맹점 목록이 존재하지 않습니다.</ContentContainer>
      )}
      {modalType === 'REFUND' && (
        <RefundListModal onClose={onClose} selected={selected} />
      )}
      {modalType === 'DETAIL' && (
        <DetailModal onClose={onClose} selected={selected} />
      )}
      {modalType === 'CREATE' && <SignUpModal onClose={onClose} />}
      {modalType === 'CREATE_GLOBAL' && <GlobalSignUpModal onClose={onClose} />}
      {toggle && (
        <CheckboxForm
          valueArray={toggle === 'isRead' ? isReadRadio : franchiseeStatusRadio}
          defaultValue={filter[toggle]}
          type={toggle}
          onFilter={onFilter}
        />
      )}
      <BottomSection>
        <ActionBtn
          title='회원 계정 생성 및 가맹점 신청'
          onClick={onCreate}
          size='big'
          margin='20px 0 0'
          right='70px'
        />
        <ActionBtn
          title='해외 간편 결제 계정 생성'
          bgColor='#FFF'
          color='#354eff'
          outlineColor='#354eff'
          onClick={onCreateGlobal}
          size='big'
          margin='20px 0 0'
          right='290px'
        />
        {totalPage > 0 && (
          <Pagination
            initialPage={currentPage}
            totalPage={totalPage}
            isLastPage={isLastPage}
            isFirstPage={isFirstPage}
            displaySize={5}
          />
        )}
      </BottomSection>
    </StyledContainer>
  );
}
const StyledContainer = styled(Container)`
  display: flex;
  flex-direction: column;
  margin: 0;
`;
const ContentContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 200px;
  color: gray;
  font-size: 16px;
`;
const BottomSection = styled.div`
  margin: 20px 0;
`;
export default FranchiseeStatus;
