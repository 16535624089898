import Portal from '@/components/common/Portal';
import React from 'react';
import styled from 'styled-components';
import SaveIcon from '@/assets/common/Save.png';

type Props = {
  ticketPath: string;
  onConfirm: () => void;
  onClose: () => void;
  onRequest: () => void;
  ticketUploadStatus: string;
};

function TicketModal({
  ticketPath,
  onConfirm,
  onClose,
  onRequest,
  ticketUploadStatus,
}: Props) {
  const isCompletedRequest = ticketUploadStatus === 'REQUEST_RE_UPLOAD';
  const isConfirmDeparture = ticketUploadStatus === 'UPLOAD_COMPLETE';

  const onClickRequestButton = () => {
    onRequest();
  };

  const downloadTicket = (path: string) => {
    const fileName = path.split('/').pop();
    let link = document.createElement('a');
    link.href = path;
    link.download = fileName || 'ticket';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <Portal isOpaqueBackdrop zIndex={150}>
      <Popover>
        <Header>
          <Title>항공권 확인</Title>
          {isConfirmDeparture || (
            <RequestButton
              onClick={onClickRequestButton}
              disabled={isCompletedRequest}
            >
              {isCompletedRequest ? '요청 완료' : '항공권 재확인 요청'}
            </RequestButton>
          )}
        </Header>
        <TicketSection isPDF={ticketPath.slice(-3) === 'pdf'}>
          {ticketPath ? (
            ticketPath.slice(-3) === 'pdf' ? (
              <DownloadButton onClick={() => downloadTicket(ticketPath)}>
                <img src={SaveIcon} alt='' width={24} />
                PDF 다운로드
              </DownloadButton>
            ) : (
              <Ticket
                src={`${ticketPath}?${(Math.random() * 10).toString()}`}
                alt=''
              />
            )
          ) : (
            <NullImage>항공권 이미지</NullImage>
          )}
        </TicketSection>
        <Buttons>
          <CloseButton onClick={onClose}>닫기</CloseButton>
          <SaveButton onClick={onConfirm} disabled={isConfirmDeparture}>
            출국 확인
          </SaveButton>
        </Buttons>
      </Popover>
    </Portal>
  );
}

const Popover = styled.aside`
  display: flex;
  flex-direction: column;
  gap: 16px;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 32px;
  width: 664px;
  background-color: #ffffff;
  border-radius: 16px;
  box-shadow: 0px 0px 30px 0px #00000014;
  z-index: 150;
`;

const Header = styled.header`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;
`;

const Title = styled.h3`
  font-size: 21px;
  font-weight: 700;
  color: #030303;
`;

const RequestButton = styled.button`
  background-color: #246cf6;
  color: #ffffff;
  border-radius: 8px;
  padding: 0 16px;
  height: 33px;
  font-weight: 600;

  &:disabled {
    background-color: #e9f0fe;
    color: #246cf6;
  }
`;

const DownloadButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  background-color: #246cf6;
  color: #ffffff;
  border-radius: 8px;
  padding: 0 16px;
  height: 36px;
  font-size: 14px;
  font-weight: 600;
`;

const Buttons = styled.footer`
  display: flex;
  gap: 17px;
  height: 52px;
  margin-top: 24px;

  & > button {
    width: 100%;
    height: 100%;
    border-radius: 8px;
    font-size: 16px;
    font-weight: 600;
  }
`;

const CloseButton = styled.button`
  background-color: #e5e6e8;
  color: #3a3b3e;
`;

const SaveButton = styled.button`
  background-color: #246cf6;
  color: #ffffff;

  &:disabled {
    background-color: #d3e2fd;
  }
`;

const TicketSection = styled.section<{ isPDF: boolean }>`
  display: flex;
  align-items: ${({ isPDF }) => (isPDF ? 'center' : 'flex-start')};
  justify-content: ${({ isPDF }) => (isPDF ? 'center' : 'flex-start')};
  width: 100%;
  height: 450px;
  border-radius: 4px;
  background-color: #f5f6f7;
  overflow-y: auto;
  cursor: pointer;
`;

const Ticket = styled.img`
  width: 100%;
  height: auto;
  object-fit: cover;
`;

const NullImage = styled.span`
  font-size: 14px;
  font-weight: 500;
  color: #5f6165;
  line-height: 21px;
`;

export default TicketModal;
