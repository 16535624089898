import { getFeedback } from '@/api/medical';
import Modal from '@/components/common/Modal';
import React, { useCallback } from 'react';
import { useQuery } from 'react-query';
import FeedbackContent from './FeedbackContent';

function FeedbackModal({ onClose, selected }: any) {
  const { hospitalId } = selected;

  const { isLoading, data, isError, isSuccess } = useQuery(
    ['hospitalDetail', hospitalId],
    () => getFeedback(hospitalId),
  );

  const onModalClose = useCallback(() => {
    onClose('FEEDBACK');
  }, [onClose]);

  return (
    <Modal
      error={
        isError ? '가맹 현황 조회에 실패했습니다. 관리자에게 문의해주세요.' : ''
      }
      onClose={onModalClose}
      isLoading={isLoading}
    >
      {isSuccess && <FeedbackContent data={data} hospitalId={hospitalId} />}
    </Modal>
  );
}

export default React.memo(FeedbackModal);
