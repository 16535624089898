import { comma } from '@/utils/ktp';
import styled from 'styled-components';

type Props = {
  totalCount?: number;
  totalReturned?: number;
};

const TotalSummary = ({ totalCount, totalReturned }: Props) => {
  return (
    <Wrapper>
      <DataWrapper>
        <DataTitle>외국인 환자 미출국 건</DataTitle>
        <DataContent>{comma(totalCount) || 0} 건</DataContent>
      </DataWrapper>
      <DataWrapper>
        <DataTitle>환불 금액</DataTitle>
        <DataContent>{comma(totalReturned) || 0} 원</DataContent>
      </DataWrapper>
    </Wrapper>
  );
};
export default TotalSummary;

const Wrapper = styled.div`
  display: flex;
  gap: 20px;
  margin-bottom: 30px;
`;

const DataWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex: 1;
  background-color: #fff;
  padding: 23px 20px;
`;

const DataTitle = styled.dt`
  color: var(--KTP-Grayscale-100, var(--KTP-Color-Mono-100, #030303));
  font-weight: 600;
  line-height: 150%;
  letter-spacing: -0.32px;
`;

const DataContent = styled.dd`
  color: var(--KTP-Primary-100, var(--KTP-Color-Primary-100, #246cf6));
  font-weight: 600;
  line-height: 150%;
  letter-spacing: -0.32px;
`;
