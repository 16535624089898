import format from 'date-fns/format';
import styled from 'styled-components';
import { TableRow } from '@material-ui/core';
import { convertNumberToTwoDigits } from '@/utils/medical';
import { comma, formattedPaymentStatus } from '@/utils/ktp';
import CustomTableCell from '@components/common/CustomTableCell';
import DetailBtn from '@/components/common/DetailBtn';

const RefundStatusItem = ({
  data,
  idx,
  onOpenModal,
  onOpenTicketModal,
  setCancelModalInfo,
}) => {
  const {
    createdDate,
    hospitalName,
    refund,
    refundIndex,
    refundStatus,
    totalPrice,
    touristName,
    nation,
    departureDate,
    paymentType,
    expireDateLookupFailed,
    usedKiosk,
    paymentStatus,
    recordReleaseDate,
    departureConfirmDate,
    ticketPath,
    vanRefundId,
  } = data;
  const isRefundStatusCancel = refundStatus === 'CANCEL';
  const isAfterRefundType = paymentType === 'DIRECT';

  const downloadTicket = (path) => {
    const fileName = path.split('/').pop();
    let link = document.createElement('a');
    link.href = path;
    link.download = fileName || 'ticket';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <StyledTableRow
      idx={idx}
      onClick={(e) => {
        if (e.target.tagName === 'BUTTON') return;
        onOpenModal(e, {
          refundIndex,
          isRefundStatusCancel,
          isAfterRefundType,
          departureDate,
          nation,
        });
      }}
    >
      <CustomTableCell
        idx={0}
        color={
          isRefundStatusCancel ? '#BDBDBD' : isAfterRefundType && '#246CF6'
        }
      >
        {convertNumberToTwoDigits(refundIndex)}
      </CustomTableCell>
      <CustomTableCell width='200' color={isRefundStatusCancel && '#BDBDBD'}>
        {format(new Date(createdDate), 'yyyy.MM.dd HH:mm:ss')}
      </CustomTableCell>
      <CustomTableCell color={isRefundStatusCancel && '#BDBDBD'}>
        {hospitalName}
      </CustomTableCell>
      <CustomTableCell
        color={
          isRefundStatusCancel
            ? '#BDBDBD'
            : (nation === 'KOR' || expireDateLookupFailed) && '#246CF6'
        }
      >
        {nation}
      </CustomTableCell>
      <CustomTableCell color={isRefundStatusCancel && '#BDBDBD'}>
        {touristName}
      </CustomTableCell>
      <CustomTableCell
        color={
          isRefundStatusCancel ? '#BDBDBD' : totalPrice > 5500000 && '#246CF6'
        }
      >
        {comma(+totalPrice)}원
      </CustomTableCell>
      <CustomTableCell color={isRefundStatusCancel && '#BDBDBD'}>
        {comma(+refund)}원
      </CustomTableCell>
      <CustomTableCell color={isRefundStatusCancel && '#BDBDBD'}>
        {refundStatus === 'APPROVE_CONFIRM'
          ? '승인'
          : usedKiosk
          ? '키오스크 취소'
          : '취소'}
      </CustomTableCell>
      <CustomTableCell color={departureConfirmDate === null && '#E42939'}>
        {departureConfirmDate ?? '출국 미확인'}
      </CustomTableCell>
      <CustomTableCell
        color={
          isAfterRefundType && paymentStatus === 'PAYMENT_WAIT' && '#E42939'
        }
      >
        {isRefundStatusCancel || !isAfterRefundType
          ? `${usedKiosk ? '키오스크 수령' : '-'}`
          : usedKiosk && paymentStatus === 'PAYMENT_COMPLETE'
          ? '키오스크 수령'
          : formattedPaymentStatus(
              paymentStatus === 'PAYMENT_COMPLETE' && !!vanRefundId
                ? 'VAN'
                : paymentStatus,
            )}
      </CustomTableCell>

      <CustomTableCell
        className='scheduledDeparture'
        isScheduledDeparture
        width={120}
      >
        {isRefundStatusCancel || !isAfterRefundType
          ? '-'
          : departureDate
          ? String(departureDate).slice(0, 10).replaceAll('-', '.')
          : '미입력'}
      </CustomTableCell>
      <CustomTableCell>
        {recordReleaseDate
          ? format(new Date(recordReleaseDate), 'yyyy-MM')
          : '-'}
      </CustomTableCell>
      <CustomTableCell>
        {isRefundStatusCancel ? (
          '-'
        ) : (
          <DetailBtn
            name='cancelBtn'
            color='#EB5757'
            onClick={() =>
              setCancelModalInfo(() => ({
                isModal: true,
                info: {
                  refundIndex,
                  storeName: hospitalName,
                  customerNational: nation,
                  customerName: touristName,
                  totalRefund: refund,
                  totalAmount: totalPrice,
                  isKioskWithdrawn: usedKiosk ? true : false,
                },
              }))
            }
          >
            환급취소
          </DetailBtn>
        )}
      </CustomTableCell>
      <CustomTableCell>
        {nation !== 'KOR' ? (
          '-'
        ) : (
          <DetailBtn
            color={ticketPath && '#246CF6'}
            name='cancelBtn'
            onClick={() => {
              if (ticketPath.slice(-3) === 'pdf') {
                downloadTicket(ticketPath);
              } else {
                onOpenTicketModal({
                  imgUrl: `${ticketPath}?${(Math.random() * 10).toString()}`,
                });
              }
            }}
          >
            {ticketPath ? '미리보기' : '미업로드'}
          </DetailBtn>
        )}
      </CustomTableCell>
    </StyledTableRow>
  );
};
const StyledTableRow = styled(TableRow)`
  cursor: pointer;
  background-color: #ffffff;
  border-bottom: ${(props) =>
    (props.idx + 1) % 5 === 0
      ? '2px solid rgb(220, 220, 220)'
      : '1px solid rgb(220, 220, 220)'};

  :hover {
    background-color: #f7f9fc;
  }
`;
const Icon = styled.img`
  position: absolute;
  right: 25px;
`;
export default RefundStatusItem;
