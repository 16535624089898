import styled from 'styled-components';
import { PieChart, Pie, Cell } from 'recharts';
import { CHART_COLORS } from '@/constants/report';
import { nationCodeToName } from '@/utils/report';
import { ReportNation } from '@/types/report';

type Props = {
  data?: ReportNation;
};

function Nation({ data = {} }: Props) {
  const dataList = Object.entries(data);

  const nationData = dataList.map(([nation, count]) => ({
    name: nationCodeToName(nation).name,
    value: count,
  }));

  const totalCount = dataList.reduce((acc, [nation, count]) => acc + count, 0);

  const nationTableData = [nationData.slice(0, 10)].concat([
    nationData.slice(10),
  ]);

  const RADIAN = Math.PI / 180;
  const renderCustomizedLabel = ({
    cx,
    cy,
    midAngle,
    innerRadius,
    outerRadius,
    percent,
    index,
  }: any) => {
    const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
    const x = cx + radius * Math.cos(-midAngle * RADIAN);
    const y = cy + radius * Math.sin(-midAngle * RADIAN);

    if (index >= 5) {
      return <></>;
    }
    return (
      <PieLabel
        x={x}
        y={y}
        fill='white'
        textAnchor='middle'
        dominantBaseline='central'
      >
        {nationData[index].name}
      </PieLabel>
    );
  };

  return (
    <Section>
      <TableTitle>
        국적별<span>(상위19개국)</span>
      </TableTitle>
      <Wrapper>
        <Table>
          {nationTableData.map((column, index) => (
            <Column key={index}>
              {column.map((data) => (
                <DataWrapper>
                  <DataTitle style={{ width: '100px' }}>{data.name}</DataTitle>
                  <Data style={{ width: '80px' }}>{data.value}</Data>
                </DataWrapper>
              ))}
              {index === 1 && (
                <DataWrapper>
                  <DataTitle style={{ width: '100px', fontWeight: '700' }}>
                    합계
                  </DataTitle>
                  <Data style={{ width: '80px' }}>{totalCount}</Data>
                </DataWrapper>
              )}
            </Column>
          ))}
        </Table>
        <PieWrapper>
          <PieChart width={200} height={200}>
            <Pie
              data={nationData}
              cx='50%'
              cy='50%'
              labelLine={false}
              label={renderCustomizedLabel}
              outerRadius={80}
              innerRadius={20}
              fill='#8884d8'
              dataKey='value'
              isAnimationActive={false}
            >
              {nationData.map((entry, index) => (
                <Cell
                  key={`cell-${index}`}
                  fill={CHART_COLORS[index % CHART_COLORS.length]}
                />
              ))}
            </Pie>
          </PieChart>
        </PieWrapper>
      </Wrapper>
    </Section>
  );
}

const Section = styled.section`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const Wrapper = styled.section`
  display: flex;
`;

const Table = styled.div`
  width: 100%;
  height: 240px;
  display: flex;
  border-top: 1px solid #bfbfbf;
`;

const TableTitle = styled.h2`
  display: inline-flex;
  padding: 0 40px;
  max-width: 200px;
  align-items: center;
  justify-content: center;
  height: 20px;
  font-size: 14px;
  color: #404040;
  background-color: #bfbfbf;

  span {
    font-size: 12px;
    font-weight: 500;
    margin-left: 4px;
  }
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;

  &:first-child {
    border-right: 1px solid #bfbfbf;
  }
`;

const DataWrapper = styled.div`
  display: flex;
  height: calc(240px / 10);
  border-bottom: 1px solid #bfbfbf;
`;

const DataTitle = styled.dt`
  display: flex;
  align-items: center;
  justify-content: center;
  border-right: 1px solid #bfbfbf;
`;

const Data = styled.dd`
  display: flex;
  align-items: center;
  justify-content: center;

  margin: 0;
`;

const PieWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  max-width: 360px;
  height: 240px;
  background-color: #ffffff;
  border: 1px solid lightgray;
`;

const PieLabel = styled.text`
  fill: #040506;
  font-weight: 700;
  font-size: 14px;
`;

export default Nation;
