import styled from 'styled-components';
import PageWrapper from './PageWrapper';
import LOGO from '@/assets/common/logo_background.png';
import LOGO_KTP from '@/assets/common/logo_ktp_long.png';

type Props = {
  year: string;
  month: string;
  name: string;
};

function Cover({ year, month, name }: Props) {
  return (
    <PageWrapper>
      <Page>
        <Inner>
          <TitleWrapper>
            <Title>{name}</Title>
            <SubTitle>
              {year}년 {month}월 REPORT
            </SubTitle>
          </TitleWrapper>
          <Logo src={LOGO_KTP} alt='' />
        </Inner>
      </Page>
    </PageWrapper>
  );
}

const TitleWrapper = styled.div`
  width: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 16px;
`;

const Title = styled.h1`
  font-size: 48px;
`;

const SubTitle = styled.p`
  font-size: 42px;
  font-weight: 700;
`;

const Logo = styled.img`
  position: absolute;
  left: 50%;
  bottom: 24px;
  transform: translateX(-50%);
  width: 120px;
`;

const Page = styled.section`
  width: 21cm;
  height: 29.7cm;
  padding: 24px;
  background-color: #f6f9ff;
  color: #3a3b3e;
`;

const Inner = styled.div`
  position: relative;
  padding: 24px;
  border: 4px solid #1856cd;
  width: 100%;
  height: 100%;

  &::before {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 500px;
    height: 500px;
    opacity: 0.1;
    background-image: url(${LOGO});
    background-repeat: no-repeat;
    background-size: 100% 100%;
    z-index: 0;
  }
`;

export default Cover;
