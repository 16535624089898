import React, { useState } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import TextField from '@material-ui/core/TextField';
import DialogActions from '@material-ui/core/DialogActions';
import { Divider } from '@material-ui/core';
import HostAcceptDialogContent from './HostAcceptDialogContent';
import HostAcceptDialogImageContent from './HostAcceptDialogImageContent';
import { useMutation } from 'react-query';
import { acceptHost, rejectHost } from '../../../api/successmode';

const HostAcceptDialog = ({ info, open, close }) => {
  const {
    id,
    name,
    gender,
    birthday,
    job,
    jobDetail,
    profileImagePathList,
    simpleIntroduction,
    assistField,
    career,
    detailIntroduction,
  } = info;

  const [rejectReason, setRejectReason] = useState('');
  const [rejectReasonFill, setRejectReasonFill] = useState(true);

  const handleRejectReasonChange = (e) => {
    setRejectReason(e.target.value);
  };
  const acceptMutation = useMutation((payload) => acceptHost(payload), {
    onSuccess: () => {
      close();
      window.location.reload();
    },
  });
  const rejectMutation = useMutation((payload) => rejectHost(payload), {
    retry: false,
    onSuccess: () => {
      close();
      window.location.reload();
    },
  });
  const handleAccept = () => {
    acceptMutation.mutate(id);
  };

  const handleReject = async () => {
    if (rejectReason.length < 1) {
      rejectReason.length < 1
        ? setRejectReasonFill(false)
        : setRejectReasonFill(true);
      return;
    }
    rejectMutation.mutate({ id, rejectReason });
  };

  return open ? (
    <Dialog
      fullWidth={true}
      open={open}
      onClose={close}
      aria-labelledby='form-dialog-title'
    >
      <DialogTitle id='form-dialog-title'>{name}님의 호스트신청</DialogTitle>
      <Divider />
      <DialogContent>
        <HostAcceptDialogImageContent
          title={'프로필 이미지'}
          profileImagePathList={profileImagePathList}
        />
        <HostAcceptDialogContent
          title={'성별 / 생년월일'}
          contents={gender === 'male' ? '남' : '여' + ' / ' + birthday}
        />
        <HostAcceptDialogContent title={'도움 분야'} contents={assistField} />
        <HostAcceptDialogContent
          title={'소개'}
          subTitle={simpleIntroduction}
          contents={detailIntroduction}
        />
        <HostAcceptDialogContent
          title={'직업'}
          subTitle={job}
          contents={jobDetail}
        />
        <HostAcceptDialogContent title={'경력'} contents={career} />
        {rejectReasonFill ? (
          <TextField
            autoFocus
            margin='dense'
            id='rejectReason'
            label='거절 메시지 입력'
            type='email'
            fullWidth
            defaultValue={info.rejectReason || ''}
            onChange={handleRejectReasonChange}
          />
        ) : (
          <TextField
            autoFocus
            error
            id='standard-error'
            label='거절 메시지를 입력하세요.'
            fullWidth
            margin={'dense'}
            defaultValue={info.rejectReason || ''}
            onChange={handleRejectReasonChange}
          />
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={handleAccept} color='primary'>
          호스트 등록
        </Button>
        <Button onClick={handleReject} color='primary'>
          호스트 거절
        </Button>
        <Button onClick={close} color='primary'>
          취소
        </Button>
      </DialogActions>
    </Dialog>
  ) : null;
};

export default HostAcceptDialog;
