import { TableBody, TableCell, TableRow } from '@material-ui/core';
import React from 'react';

function TotalRefundList({
  totalData: {
    totalCount,
    totalCancel,
    totalAmount,
    totalRefund,
    totalActualAmount,
  },
}) {
  return (
    <TableBody>
      <TableRow>
        <TableCell style={{ padding: 8, fontSize: 13 }} align='center'>
          {' '}
          {(+totalCount).toLocaleString('ko-KR')}건
        </TableCell>
        <TableCell style={{ padding: 8, fontSize: 13 }} align='center'>
          {' '}
          {(+totalCancel).toLocaleString('ko-KR')}건
        </TableCell>
        <TableCell style={{ padding: 8, fontSize: 13 }} align='center'>
          {(+totalAmount).toLocaleString('ko-KR')}원
        </TableCell>
        <TableCell style={{ padding: 8, fontSize: 13 }} align='center'>
          {(+totalRefund).toLocaleString('ko-KR')}원
        </TableCell>
        <TableCell style={{ padding: 8, fontSize: 13 }} align='center'>
          {' '}
          {(+totalActualAmount).toLocaleString('ko-KR')}원
        </TableCell>
      </TableRow>
    </TableBody>
  );
}

export default TotalRefundList;
