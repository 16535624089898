import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { format } from 'date-fns';
import { Container, TableBody } from '@material-ui/core';

import DateSelectorFilter from '@/components/common/DateSelectorFilter';
import FlexWrap from '@/components/common/FlexWrap';
import Title from '@/components/common/Title';
import RefundCard from '@/components/common/RefundCard';
import { homeColumnList } from '@/constants/ktp';
import useHomeReducer from '@/hooks/common/useHomeReducer';
import useHomeData from '@/hooks/ktp/useHomeData';
import store from '@/store';
import { ILatestRefundInfoItem, INoticeItem } from '@/types/api/ktp';
import LatestRefundList from '@/components/ktp/home/LatestRefundList';
import PrimaryNoticeList from '@/components/ktp/home/PrimaryNoticeList';
import LatestTable from '@/components/ktp/home/LatestTable';
import LoadingView from '@/components/common/LoadingView';

function Home() {
  const [state, dispatch] = useHomeReducer();
  const {
    dateFilter,
    filter: { startDate, endDate },
  } = state;
  const { data, isError, isLoading } = useHomeData({
    dateFilter: 'CUSTOM',
    startDate: format(startDate, 'yyyy-MM-dd'),
    endDate: format(endDate, 'yyyy-MM-dd'),
  });
  const { refund, notice } = homeColumnList;
  const history = useHistory();
  const serverState = store.getState().serverState;

  const onClickMore = (type: string) => {
    let pathname;
    switch (type) {
      case 'REFUND':
        pathname = `/ktp/${serverState}/refund-status`;
        break;
      case 'NOTICE':
        pathname = `/ktp/${serverState}/notice`;
        break;
      default:
        pathname = `/`;
        break;
    }
    history.push(pathname);
  };

  useEffect(() => {
    const todayDate = format(new Date(), 'yyyy-MM-dd');
    const currDate = format(startDate, 'yyyy-MM-dd');
    if (currDate !== todayDate) {
      dispatch({
        type: 'SET_FILTER_DATE',
        payload: {
          startDate: new Date(),
          endDate: new Date(),
        },
      });
    }
  }, []);

  if (isError) {
    return <>서버 요청에 실패하였습니다. 관리자에게 문의하세요.</>;
  }
  return (
    <StyledContainer maxWidth='xl'>
      {isLoading ? (
        <LoadingView />
      ) : (
        <>
          {data && (
            <>
              <Title>홈</Title>
              <TitleWrap justifyContent='space-between'>
                <SubTitle>환급 개요</SubTitle>

                <DateSelectorFilter
                  position='relative'
                  dispatch={dispatch}
                  filter={state.filter}
                />
              </TitleWrap>
              <RefundContainer>
                {Object.values(data.refundSummary).map((item, idx) => (
                  <RefundCard key={idx} idx={idx} value={item as number} />
                ))}
              </RefundContainer>
              {data.tableData.map((item, idx) => (
                <TableSection key={idx}>
                  <TitleWrap justifyContent='space-between'>
                    <SubTitle>{item.title}</SubTitle>
                    <MoreBtn onClick={() => onClickMore(item.value)}>
                      + 더보기
                    </MoreBtn>
                  </TitleWrap>
                  <LatestTable
                    columns={item.value === 'REFUND' ? refund : notice}
                    contentComponent={
                      <TableBody>
                        {item.data ? (
                          item.value === 'REFUND' ? (
                            item.data.map(
                              (data: ILatestRefundInfoItem, idx: number) => (
                                <LatestRefundList
                                  key={idx}
                                  data={data as ILatestRefundInfoItem}
                                />
                              ),
                            )
                          ) : (
                            item.data.map((data: INoticeItem, idx: number) => (
                              <PrimaryNoticeList
                                key={idx}
                                data={data as INoticeItem}
                                idx={idx}
                              />
                            ))
                          )
                        ) : (
                          <p>{item.title}이 없습니다.</p>
                        )}
                      </TableBody>
                    }
                  />
                </TableSection>
              ))}
            </>
          )}
        </>
      )}
    </StyledContainer>
  );
}

const StyledContainer = styled(Container)`
  padding-top: 16px;
`;
const TitleWrap = styled(FlexWrap)``;
const MoreBtn = styled.button`
  color: #757575;
  margin-top: 20px;
`;
const TableSection = styled.div`
  margin-bottom: 56px;
`;
const SubTitle = styled.h2`
  font-size: 20px;
  line-height: 28px;
  color: #212121;
  margin-bottom: 28px;
`;
const RefundContainer = styled(FlexWrap)`
  gap: 20px;
  margin-bottom: 56px;
`;

export default Home;
