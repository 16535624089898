import styled from 'styled-components';
import {
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
} from '@material-ui/core';

import { GetKioskStatusWithdrawalInfo } from '@/types/api/kiosk';
import {
  kioskWithdrawalStatusTitle,
  kioskWithdrawalStatusField,
} from '@/constants/kiosk';
import { comma } from '@/utils/kiosk';

import Typography from '@/components/common/Typography';
import DateSelectorFilter from '@/components/common/DateSelectorFilter';
import CustomTableCell from '@/components/common/CustomTableCell';

type KioskRechargeStatusProps = {
  withdrawalInfo?: GetKioskStatusWithdrawalInfo;
  dispatch: React.Dispatch<any>;
  stateFilter: { endDate: Date; startDate: Date };
};

const cellStyle = {
  padding: '12px 20px',
};

const highlightedCellStyle = {
  ...cellStyle,
  backgroundColor: '#E5E6E8',
};

function KioskWithdrawalStatus({
  withdrawalInfo,
  dispatch,
  stateFilter,
}: KioskRechargeStatusProps) {
  return (
    <>
      <Wrapper margin='0 0 20px 0'>
        <Typography fontSize='20px' fontWeight='500' lineHeight='26px'>
          현금 불출 현황
        </Typography>
        <DateSelectorFilter
          position='relative'
          dispatch={dispatch}
          filter={stateFilter}
        />
      </Wrapper>
      <TableContainer style={{ margin: '0 0 30px 0' }}>
        <Table>
          <TableHead>
            <StyledTableRow>
              {kioskWithdrawalStatusTitle.map((title, idx) => (
                <CustomTableCell
                  key={`${title}-${idx}`}
                  width='300px'
                  style={idx === 3 ? highlightedCellStyle : cellStyle}
                >
                  {title}
                </CustomTableCell>
              ))}
            </StyledTableRow>
          </TableHead>
          <TableBody>
            <StyledTableRow>
              {kioskWithdrawalStatusField.map((field, idx) => (
                <CustomTableCell
                  key={field}
                  fontSize='14px'
                  width='300px'
                  style={idx === 3 ? highlightedCellStyle : cellStyle}
                >
                  {withdrawalInfo
                    ? idx !== 3
                      ? withdrawalInfo[
                          field as keyof GetKioskStatusWithdrawalInfo
                        ]
                      : `${comma(
                          withdrawalInfo[
                            field as keyof GetKioskStatusWithdrawalInfo
                          ],
                        )} 원`
                    : '-'}
                </CustomTableCell>
              ))}
            </StyledTableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
}

export default KioskWithdrawalStatus;

const Wrapper = styled.div<{ margin?: string; flexDirection?: string }>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: ${({ flexDirection }) => flexDirection || 'row'};
  margin: ${({ margin }) => margin || '0'};
`;

const StyledTableRow = styled(TableRow)<{ margin?: string }>`
  background-color: #ffffff;
  border-bottom: 1px solid #cbccce;
`;
