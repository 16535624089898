import React, { useCallback, useReducer } from 'react';
import styled from 'styled-components';
import {
  Dialog,
  DialogContent,
  DialogContentText,
  Table,
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
} from '@material-ui/core';
import { useQuery } from 'react-query';
import { format } from 'date-fns';

import { withStyles } from '@material-ui/core/styles';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';

import RefundList from './RefundList';
import TotalRefundList from './TotalRefundList';
import HospitalDateSelector from '@/components/medical/common/HospitalDateSelector';
import LoadingView from '@/components/common/LoadingView';
import { getRefundListByIndex } from '@/api/medical';
import FlexWrap from '@/components/common/FlexWrap';

const ContentContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 200px;
`;

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(5),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});
const initialState = {
  dateSelector: 'MONTH',
  filter: {
    startDate: '2000-01-01',
    endDate: '2000-01-02',
  },
};

function reducer(state, action) {
  switch (action.type) {
    case 'SET_FILTER_DATE':
      return {
        ...state,
        filter: {
          startDate: action.payload.startDate,
          endDate: action.payload.endDate,
        },
      };
    case 'SET_DATEFILTER':
      return {
        ...state,
        dateSelector: action.payload,
      };
    default:
      throw Error(`Unhandled Type Error: ${action.type}`);
  }
}

const DialogTitle = withStyles(styles)(
  ({ children, classes, onClose, ...other }) => {
    return (
      <MuiDialogTitle disableTypography className={classes.root} {...other}>
        <Typography variant='h6'>{children}</Typography>
        <IconButton
          aria-label='close'
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      </MuiDialogTitle>
    );
  },
);
function RefundListModal({ onClose, selected }) {
  const [state, dispatch] = useReducer(reducer, initialState);
  const { filter, dateSelector } = state;
  const { hospitalIndex, hospitalName } = selected;
  const {
    isLoading: isFetchLoading,
    data,
    isError,
    error,
    refetch,
  } = useQuery(
    ['refundList', hospitalIndex, dateSelector, filter],
    () => getRefundListByIndex({ hospitalIndex, dateSelector, filter }),
    { retry: false },
  );
  const onModalClose = useCallback(() => {
    onClose('REFUND');
  }, [onClose]);

  const columnsRefundList = [
    'No',
    '환급상태',
    '환급일자',
    '상품금액',
    '환급액',
    '결제금액',
  ];
  const columnsTotalRefund = [
    '환급건수',
    '환급취소건수',
    '총상품금액',
    '환급액',
    '결제금액',
  ];

  return (
    <Dialog
      fullWidth={true}
      open={true}
      maxWidth='lg'
      onClose={onModalClose}
      aria-labelledby='customized-dialog-title'
    >
      <DialogTitle id='customized-dialog-title' onClose={onModalClose} />
      <DialogContent style={{ minHeight: 700 }}>
        <Title>환급 현황 상세보기</Title>
        {isFetchLoading && <LoadingView />}
        {isError && (
          <ContentContainer>
            <DialogContentText>
              환급 현황 조회에 실패했습니다. 관리자에게 문의해주세요.
            </DialogContentText>
            <DialogContentText>{error?.message}</DialogContentText>
          </ContentContainer>
        )}
        {data && (
          <>
            <TableContainer style={{ marginBottom: 30, padding: '0 50px' }}>
              <TitleWrap justifyContent='space-between'>
                <SubTitle>{hospitalName}</SubTitle>
                <HospitalDateSelector
                  position='relative'
                  dateFilter={dateSelector}
                  dispatch={dispatch}
                  refetch={refetch}
                />
              </TitleWrap>
              <Table>
                <TableHead>
                  <TableRow>
                    {columnsTotalRefund.map((column) => (
                      <TableCell
                        key={column}
                        align='center'
                        style={{
                          backgroundColor: '#f1f3f5',
                          padding: 5,
                          fontSize: 13,
                        }}
                      >
                        {column}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TotalRefundList totalData={data.refundDetailCountDto} />
              </Table>
            </TableContainer>
          </>
        )}
        {data && (
          <TableContainer style={{ marginBottom: 30, padding: '0 50px' }}>
            <Table>
              <TableHead>
                <TableRow>
                  {columnsRefundList.map((column) => (
                    <TableCell
                      key={column}
                      align='center'
                      style={{
                        backgroundColor: '#f1f3f5',
                        padding: 5,
                        fontSize: 13,
                      }}
                    >
                      {column}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <RefundList refundData={data?.refundDto} />
            </Table>
          </TableContainer>
        )}
      </DialogContent>
    </Dialog>
  );
}
const Title = styled.h1`
  font-size: 30px;
  margin: 0 50px 44px;
`;
const SubTitle = styled.h3`
  font-size: 18px;
`;
const TitleWrap = styled(FlexWrap)`
  width: 100%;
  margin-bottom: 16px;
`;
export default React.memo(RefundListModal);
