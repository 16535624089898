import { ReportResult } from '@/types/report';
import { useEffect, useState } from 'react';

type Props = {
  id: number;
  year: string;
  month: string;
};

function useGetReportData({ id, year, month }: Props) {
  const [reportData, setReportData] = useState<ReportResult | undefined>();

  useEffect(() => {
    const fetchData = async () => {
      const response = await fetch(
        `https://ktp-image.s3.ap-northeast-2.amazonaws.com/KtpReport/report/${year}/${month}/${id}.json`,
      );
      const data = await response.json();
      setReportData(data);
    };

    fetchData();
  }, [year, month, id]);

  return {
    nationData: reportData?.[0],
    dateNationData: reportData?.[1],
    dateData: reportData?.[2],
    dayTimeData: reportData?.[3],
    priceData: reportData?.[4],
    ageGenderData: reportData?.[5],
  };
}

export default useGetReportData;
