import styled from 'styled-components';
import { Icon } from '@successmode-ktp/kui';

function SearchTooltip() {
  return (
    <ToolTipWrapper>
      <TooltipButton>
        <Icon.QuestionFilled size={24} fillColor='#CBCCCE' />
      </TooltipButton>
      <Tooltip>
        <Item>
          <ItemTitle>가맹점 연락처</ItemTitle>
          <ItemData>0으로 시작하는 숫자</ItemData>
        </Item>
        <Item>
          <ItemTitle>환급번호</ItemTitle>
          <ItemData>숫자</ItemData>
        </Item>
        <Item>
          <ItemTitle>고객명</ItemTitle>
          <ItemData>영어 대문자</ItemData>
        </Item>
        <Item>
          <ItemTitle>가맹점명</ItemTitle>
          <ItemData>문자</ItemData>
        </Item>
      </Tooltip>
    </ToolTipWrapper>
  );
}

const ToolTipWrapper = styled.div`
  position: relative;
  width: 24px;
  height: 24px;
  margin-bottom: 24px;

  &:hover {
    aside {
      transform: translate3d(0, 0, 0);
      opacity: 1;
      visibility: visible;

      &:after {
        content: '';
        position: absolute;
        bottom: 100%;
        left: 70px;
        margin-left: -7px;
        border-width: 7px;
        border-style: solid;
        border-color: transparent transparent #000000cc transparent;
      }
    }
  }
`;

const Tooltip = styled.aside`
  display: flex;
  flex-direction: column;
  gap: 6px;
  position: absolute;
  top: 40px;
  left: -60px;
  background-color: #000000cc;
  color: #ffffff;
  padding: 16px;
  border-radius: 4px;
  text-wrap: nowrap;
  z-index: 10;
  opacity: 0;
  visibility: hidden;
  transform: translate3d(0, -10px, 0);
  transition: transform 200ms ease-in-out, opacity 200ms ease-in-out,
    visibility 200ms ease-in-out;
`;

const TooltipButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0;
  padding: 0;
  width: 24px;
  height: 24px;
`;

const Item = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
`;

const ItemTitle = styled.dt`
  width: 72px;
  font-size: 16px;
  line-height: 24px;
  color: #cbccce;
  white-space: nowrap;
`;

const ItemData = styled.dd`
  font-size: 16px;
  font-weight: 600;
  white-space: nowrap;
`;

export default SearchTooltip;
