import React, { useCallback, useState } from 'react';
import { useQuery } from 'react-query';
import { getRefundDetail } from '@/api/medical';
import Modal from '@/components/common/Modal';
import DetailModalContent from './DetailModalContent';

function DetailModal({ onClose, selected }) {
  const [data, setData] = useState();
  const { refundIndex } = selected;
  const onModalClose = useCallback(() => {
    onClose('DETAIL');
  }, [onClose]);
  const { isLoading, isError } = useQuery(
    ['hospitalRefundDetail', refundIndex],
    () => getRefundDetail(refundIndex),
    {
      refetchOnWindowFocus: false,
      onSuccess: (data) => {
        setData(data);
      },
      onError: () =>
        alert('데이터 조회에 실패하였습니다. 관리자에게 문의해주세요.'),
    },
  );

  return (
    <Modal
      width='1600px'
      onClose={onModalClose}
      isLoading={isLoading}
      error={
        isError && '환급 상세보기 조회에 실패했습니다. 관리자에게 문의해주세요.'
      }
    >
      {data && (
        <DetailModalContent
          data={data}
          setData={setData}
          refundIndex={refundIndex}
          onClose={onModalClose}
        />
      )}
    </Modal>
  );
}

export default React.memo(DetailModal);
