import { ReportDate } from '@/types/report';
import Date from '../section/Date';
import PageWrapper from './PageWrapper';

type Props = {
  dateData?: ReportDate;
};

function Page2({ dateData = [], ...props }: Props) {
  return (
    <PageWrapper>
      <Date data={dateData} {...props} />
    </PageWrapper>
  );
}

export default Page2;
