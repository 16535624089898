import Summary from '../section/Summary';
import Nation from '../section/Nation';
import NationDate from '../section/NationDate';
import PageWrapper from './PageWrapper';
import { ReportDateNation, ReportNation, ReportSummary } from '@/types/report';
import { useQuery } from 'react-query';
import { getFranchiseeDetail } from '@/api/ktp';

type Props = {
  id: number;
  name: string;
  nationData?: ReportNation;
  dateNationData?: ReportDateNation;
};

function Page1({ id, name, nationData, dateNationData, ...props }: Props) {
  const summary = {
    store: name,
    ...props,
  } as ReportSummary;

  return (
    <PageWrapper>
      <Summary data={summary} />
      <Nation data={nationData} />
      <NationDate data={dateNationData} />
    </PageWrapper>
  );
}

export default Page1;
