import React, { useCallback } from 'react';
import { TableRow } from '@material-ui/core';
import styled from 'styled-components';
import { IContent } from '@/types/api/medical';
import DetailBtn from '@/components/common/DetailBtn';
import CustomTableCell from '@/components/common/CustomTableCell';
import { convertNumberToTwoDigits, newRegisterHospital } from '@/utils/medical';
import { formattedEquipment } from '@/utils/common';
import Typography from '@/components/common/Typography';

// REAPPLIED | REJECTED | WAIT | ACCEPTED | CANCEL | INIT
interface IProps {
  data: IContent;
  onOpen: (type: 'DETAIL' | 'REFUND' | 'CREATE', selected: any) => void;
  idx: number;
}
const HospitalStatusListItem = ({ data, onOpen, idx }: IProps) => {
  const {
    hospitalIndex,
    paymentType,
    createdDate,
    hospitalName,
    managerName,
    businessNumber,
    refundOnce,
    equipmentTypes,
    promotionExpiryDate,
  } = data;
  const onOpenRefund = useCallback(() => {
    onOpen('REFUND', {
      hospitalIndex,
      hospitalName,
    });
  }, [onOpen, hospitalName, hospitalIndex]);

  const onOpenDetail = useCallback(() => {
    onOpen('DETAIL', {
      hospitalIndex,
      businessNumber,
      createdDate,
    });
  }, [onOpen, hospitalIndex, businessNumber, createdDate]);

  const isExpiredBeforeOneMonth = (date: string) => {
    if (!date) {
      return '-';
    }
    const expiredDate = new Date(date);
    const today = new Date();
    const oneMonthAfter = new Date(today.setMonth(today.getMonth() + 2));

    if (expiredDate.getTime() < oneMonthAfter.getTime()) {
      return <WarningExpiredDate>{date}</WarningExpiredDate>;
    }
    return date;
  };

  return (
    <StyledTableRow idx={idx}>
      <CustomTableCell idx={0} width='90'>
        {convertNumberToTwoDigits(hospitalIndex)}
      </CustomTableCell>
      <CustomTableCell width='60'>
        {newRegisterHospital(createdDate) && (
          <Typography color='#FF2D55' fontWeight='500'>
            New
          </Typography>
        )}
      </CustomTableCell>
      <CustomTableCell width='120'>
        <Typography fontWeight='500'>{createdDate.slice(0, 10)}</Typography>
      </CustomTableCell>
      <CustomTableCell width='200'>{hospitalName}</CustomTableCell>
      <CustomTableCell width='200'>
        {paymentType === 'DIRECT' ? '전액 결제' : '차감 결제'}
      </CustomTableCell>
      <CustomTableCell width='150'>
        {formattedEquipment(equipmentTypes)}
      </CustomTableCell>
      <CustomTableCell width='150'>{managerName}</CustomTableCell>
      <CustomTableCell width='150'>{businessNumber}</CustomTableCell>
      <CustomTableCell width='200'>
        {isExpiredBeforeOneMonth(promotionExpiryDate)}
      </CustomTableCell>
      <CustomTableCell width='100'>
        {refundOnce ? (
          <DetailBtn onClick={onOpenRefund}>상세보기</DetailBtn>
        ) : (
          '-'
        )}
      </CustomTableCell>
      <CustomTableCell width='100'>
        <DetailBtn onClick={onOpenDetail}>상세보기</DetailBtn>
      </CustomTableCell>
    </StyledTableRow>
  );
};

export default HospitalStatusListItem;

const StyledTableRow = styled(TableRow)<{ idx: number }>`
  background-color: #ffffff;
  border-bottom: ${(props) =>
    (props.idx + 1) % 5 === 0
      ? '2px solid rgb(220, 220, 220)'
      : '1px solid rgb(220, 220, 220)'};
  :hover {
    background-color: #f7f9fc;
  }
`;

const WarningExpiredDate = styled.span`
  color: #ed0828;
`;
