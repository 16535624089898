import { useMutation } from 'react-query';

import { deleteMedicalRefund } from '@/api/medical';

export const useRefundDelete = () => {
  return useMutation({
    mutationKey: ['medicalRefundDelete'],
    mutationFn: deleteMedicalRefund,
    retry: false,
    onSuccess: () => {
      alert('환급현황이 삭제되었습니다.');
    },
    onError: () => {
      alert('환급현황 삭제가 실패하였습니다.');
    },
  });
};
