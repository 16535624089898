import { format } from 'date-fns';
import styled from 'styled-components';
import { TableRow } from '@material-ui/core';

import CustomTableCell from '@/components/common/CustomTableCell';
import { INoticeListConent } from '@/types/api/medical';
import { convertNumberToTwoDigits } from '@/utils/medical';

interface IProps {
  data: INoticeListConent;
  onOpenModal: () => void;
  idx: number;
}
function NoticeListItem({ data, onOpenModal, idx }: IProps) {
  const { noticeIndex, title, scheduledDate, fixed, visible, immediate } = data;
  return (
    <StyledTableRow idx={idx}>
      <CustomTableCell width='40' idx={0}>
        {convertNumberToTwoDigits(noticeIndex)}
      </CustomTableCell>
      <CustomTableCell width='370'>
        <Title onClick={onOpenModal}>{title}</Title>
      </CustomTableCell>
      <CustomTableCell width='150'>
        {!immediate && <Reserved>예약</Reserved>}
        {format(new Date(scheduledDate), 'yyyy.MM.dd')}
      </CustomTableCell>
      <CustomTableCell width='80'>{fixed ? 'O' : 'X'}</CustomTableCell>
      <CustomTableCell width='80'>
        {visible ? '공개' : '비공개'}
      </CustomTableCell>
    </StyledTableRow>
  );
}
const Reserved = styled.span`
  color: #e42939;
  font-size: 13px;
  margin-right: 10px;
`;
const StyledTableRow = styled(TableRow)<{ idx: number }>`
  background-color: #ffffff;
  border-bottom: ${(props) =>
    (props.idx + 1) % 5 === 0
      ? '2px solid rgb(220, 220, 220)'
      : '1px solid rgb(220, 220, 220)'};
  :hover {
    background-color: #f7f9fc;
  }
`;
const Title = styled.span`
  :hover {
    text-decoration: underline;
    cursor: pointer;
  }
`;
export default NoticeListItem;
