import { useState } from 'react';
import { useQuery } from 'react-query';
import { format } from 'date-fns';
import { useLocation } from 'react-router-dom';
// API
import {
  GetKioskStatusPayload,
  GetKioskStatusResponse,
} from '@/types/api/kiosk';
import { getKioskStatus } from '@/api/kiosk';
import useHomeReducer from '@/hooks/common/useHomeReducer';
import { KioskActiveTypes } from '@/types/kiosk.type';
// Components
import KioskTitle from '../KioskTitle';
import KioskRechargeStatus from './KioskWithdrawalStatus';
import KioskStatusList from './KioskStatusList';
import LoadingView from '@/components/common/LoadingView';

function KioskHome() {
  const location = useLocation();

  const [kioskActiveTypeFilter, setKioskActiveTypeFilter] = useState<
    KioskActiveTypes[]
  >(['ACTIVATED']);
  const searchParams = new URLSearchParams(location.search);
  const currentPage = searchParams.get('page') || '0';
  const keyword = searchParams.get('search') || '';
  const size = searchParams.get('size') || '100';

  const [totalPage, setTotalPage] = useState<number>(0);
  const [isFirstPage, setIsFirstPage] = useState(false);
  const [isLastPage, setIsLastPage] = useState(false);

  const [state, dispatch] = useHomeReducer();
  const {
    filter: { startDate, endDate },
  } = state;

  const { data: kioskData, isLoading } = useQuery<
    GetKioskStatusResponse,
    GetKioskStatusPayload
  >(
    [
      'kioskStatus',
      currentPage,
      keyword,
      size,
      kioskActiveTypeFilter,
      startDate,
      endDate,
    ],
    () =>
      getKioskStatus({
        page: Number(currentPage),
        size: Number(size),
        keyword,
        view:
          kioskActiveTypeFilter.length === 2 ? 'ALL' : kioskActiveTypeFilter[0],
        startDate: format(state.filter.startDate, 'yyyy-MM-dd'),
        endDate: format(state.filter.endDate, 'yyyy-MM-dd'),
      }),
    {
      onSuccess: ({ kioskInfo }) => {
        const { totalPages, first, last } = kioskInfo;

        setTotalPage(totalPages);
        setIsFirstPage(first);
        setIsLastPage(last);
      },
      retry: false,
    },
  );

  return (
    <>
      {isLoading ? (
        <LoadingView />
      ) : (
        <>
          <KioskTitle title='홈' />
          <KioskRechargeStatus
            withdrawalInfo={kioskData?.withdrawalInfo}
            dispatch={dispatch}
            stateFilter={state.filter}
          />
          <KioskStatusList
            kioskActiveTypeFilter={kioskActiveTypeFilter}
            setKioskActiveTypeFilter={setKioskActiveTypeFilter}
            size={size}
            kioskInfo={kioskData?.kioskInfo}
            totalPage={totalPage}
            currentPage={currentPage}
            isLastPage={isLastPage}
            isFirstPage={isFirstPage}
          />
        </>
      )}
    </>
  );
}

export default KioskHome;
