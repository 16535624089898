import { useState } from 'react';
import styled from 'styled-components';
import { Container, TableBody } from '@material-ui/core';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { QNA_TABLE_DATA } from '@constants/ktp';
import DataTable from '@components/common/DataTable';
import Title from '@components/common/Title';
import LoadingView from '@/components/common/LoadingView';
import QnaItem from '@/components/qna/QnaItem';
import QnaFilter from '@/components/qna/QnaFilter';
import QnaModal from '@/components/qna/Qna.modal';
import useQnaReducer from '@/hooks/ktp/useQnaReducer';
import {
  getQnaMedicalList,
  hideQuestion,
  patchQnaMedicalRead,
} from '@/api/medical';
import { QnaResponse } from '@/types/qna.type';
import useRemittanceReducer from '@/hooks/medical/useRemittanceReducer';
import DetailModal from '@/components/medical/refundStatus/DetailModal';
import Search from '@/components/common/Search';
import ViewDateSelection from '@/components/common/ViewDataSelection';
import Pagination from '@/components/common/Pagination';
import { useLocation } from 'react-router-dom';
import ConfirmModal from '@/components/sales/confirmModal/ConfirmModal';

function MedicalQna() {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const currentPage = searchParams.get('page') || '0';
  const searchKeyword = searchParams.get('search') || '';
  const size = searchParams.get('size') || '10';
  const [state, dispatch] = useQnaReducer();
  const { toggle, selected } = state;
  const [refundState, refundDispatch] = useRemittanceReducer();
  const { selected: refundSelected } = refundState;
  const [totalPage, setTotalPage] = useState<number>(0);
  const [isFirstPage, setIsFirstPage] = useState(false);
  const [isLastPage, setIsLastPage] = useState(false);
  const [qnafilter, setQnaFilter] = useState('ALL');
  const [selectedHiddenQna, setSelectedHiddenQna] = useState<number[]>([]);
  const [isOpenHiddenModal, setIsOpenHiddenModal] = useState(false);
  const { columns } = QNA_TABLE_DATA;
  const queryClient = useQueryClient();

  const { data, isLoading } = useQuery(
    ['medicalQnaList', qnafilter, size, currentPage, searchKeyword],
    () => {
      const params = {
        status: qnafilter,
        page: currentPage,
        size,
        keyword: searchKeyword,
      };
      return getQnaMedicalList(params);
    },
    {
      onSuccess: ({ totalPages, first, last }) => {
        setTotalPage(totalPages);
        setIsFirstPage(first);
        setIsLastPage(last);
      },
      retry: false,
      keepPreviousData: true,
    },
  ) as any;

  const { mutate: readQna } = useMutation(
    (selected: number) => patchQnaMedicalRead(selected),
    {
      onSuccess: () => {
        queryClient.invalidateQueries('medicalQnaList');
      },
    },
  );

  const { mutate: hideQna } = useMutation(
    (selected: { questionIds: number[] }) => hideQuestion(selected),
    {
      onSuccess: () => {
        queryClient.invalidateQueries('medicalQnaList');
      },
    },
  );

  const onQnaModal = {
    open: (selected: number, isNew: boolean) => {
      if (isNew) {
        readQna(selected);
      }
      dispatch({
        type: 'OPEN_MODAL',
        payload: { selected },
      });
    },
    close: () => {
      dispatch({
        type: 'CLOSE_MODAL',
      });
    },
  };

  const onRefundModal = {
    open: (refundIndex: number) => {
      refundDispatch({
        type: 'SET_SELECTED',
        payload: { refundIndex },
      });
    },
    close: () => {
      refundDispatch({ type: 'SET_SELECTED', selected: null });
    },
  };

  const onHideQuestion = () => {
    if (qnafilter === 'NOT_ANSWERED') {
      hideQna({ questionIds: selectedHiddenQna });
      setIsOpenHiddenModal(false);
    }
  };

  if (isLoading) {
    return <LoadingView />;
  }

  return (
    <StyledContainer maxWidth='xl'>
      <Title>FAQ</Title>
      <Search data={[]} />
      <FilterWrapper>
        <QnaFilter
          currentTransferView={qnafilter}
          onClickChangeTransfer={(value) => setQnaFilter(value)}
        />
        <ViewDateSelection initialSize={size} />
      </FilterWrapper>
      <DataTable
        columns={columns}
        dispatch={dispatch}
        toggle={toggle}
        contentComponent={
          <TableBody>
            {data?.content.map((question: QnaResponse) => (
              <QnaItem
                key={question.questionId}
                id={question.questionId}
                data={question}
                onClick={() =>
                  onQnaModal.open(question.questionId, question.isNew)
                }
                notAnswered={qnafilter === 'NOT_ANSWERED'}
                selectedHidden={selectedHiddenQna.includes(question.questionId)}
                setSelectedHiddenQna={(id) =>
                  setSelectedHiddenQna((prev) =>
                    prev.includes(id)
                      ? prev.filter((val) => val !== id)
                      : [...prev, id],
                  )
                }
              />
            ))}
          </TableBody>
        }
      />
      {data?.content?.length === 0 && (
        <ContentContainer>문의가 존재하지 않습니다.</ContentContainer>
      )}
      {qnafilter === 'NOT_ANSWERED' && data?.content?.length > 0 && (
        <ButtonWrapper>
          <HiddenButton
            disabled={selectedHiddenQna.length === 0}
            onClick={() => setIsOpenHiddenModal(true)}
          >
            선택한 문의 숨기기
          </HiddenButton>
        </ButtonWrapper>
      )}
      <BottomSection>
        {totalPage > 0 && (
          <Pagination
            initialPage={currentPage}
            totalPage={totalPage}
            isLastPage={isLastPage}
            isFirstPage={isFirstPage}
            displaySize={5}
          />
        )}
      </BottomSection>
      {selected && (
        <QnaModal
          qnaId={selected}
          onClose={onQnaModal.close}
          onOpenRefundModal={onRefundModal.open}
        />
      )}
      {refundSelected?.refundIndex && (
        <DetailModal onClose={onRefundModal.close} selected={refundSelected} />
      )}
      {isOpenHiddenModal && (
        <ConfirmModal
          title='문의를 숨기시겠습니까?'
          onCancel={() => setIsOpenHiddenModal(false)}
          onOK={onHideQuestion}
          cancelLabel='취소'
          okLabel='확인'
        >
          <Message>선택된 문의가 숨겨집니다.</Message>
        </ConfirmModal>
      )}
    </StyledContainer>
  );
}

export default MedicalQna;

const StyledContainer = styled(Container)`
  display: flex;
  flex-direction: column;
  margin: 0;
`;

const ContentContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 200px;
  color: gray;
  font-size: 16px;
`;

const FilterWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  height: 56px;
`;

const BottomSection = styled.div`
  margin: 20px 0;
`;

const HiddenButton = styled.button<{ disabled: boolean }>`
  margin-top: 20px;
  padding: 6px 24px;
  border-radius: 6px;
  background-color: ${({ disabled }) => (disabled ? '#E5E6E8' : '#3a3b3e')};
  color: #ffffff;
  font-size: 14px;
  line-height: 21px;
`;

const Message = styled.p`
  font-size: 18px;
  font-weight: 500px;
  line-height: 27px;
  color: #5f6165;
  text-align: center;
`;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 100%;
`;
