import React from 'react';
import styled from 'styled-components';
import SubtractImage from '../../assets/common/Subtract.png';

type Props = {
  image: string;
  fileName: string;
  onDelete: () => void;
};

function SelectedImage({ image, fileName, onDelete }: Props) {
  return (
    <ImageItem>
      <ImageSection>
        <img src={image} alt='' />
        <ImageDeleteButton onClick={onDelete}>
          <img src={SubtractImage} alt='이미지 삭제하기' width={18} />
        </ImageDeleteButton>
      </ImageSection>
      <ImageName>{fileName}</ImageName>
    </ImageItem>
  );
}

const ImageSection = styled.div`
  position: relative;
  width: 80px;
  height: 80px;
  background-color: #ffffff;
  border: 1px dashed #cbccce;
  border-radius: 12px;
  overflow: hidden;

  & > img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;

const ImageDeleteButton = styled.button`
  position: absolute;
  top: 0;
  right: 0;
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const ImageItem = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-items: center;
  justify-content: center;
`;

const ImageName = styled.span`
  width: 60px;
  font-size: 12px;
  line-height: 18px;
  color: #a7a7a7;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`;

export default SelectedImage;
