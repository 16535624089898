import React, { KeyboardEvent, useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';
import Logo from '@assets/common/logo_full.png';
import FlexWrap from '@/components/common/FlexWrap';
import LoadingView from '@/components/common/LoadingView';
import { ISignInPayload, SignInVerifyPayload } from '@/types/api/ktp';
import { signIn, signInVerify } from '@/api/ktp';
import { useMutation } from 'react-query';
import { useForm } from 'react-hook-form';
import { Icon } from '@successmode-ktp/kui';

interface IForm {
  username: string;
  password: string;
  verifyCode: string;
}

const SignIn = () => {
  const VERIFY_CODE_TIME = 180 - 1;
  const [actionAlert, setActionAlert] = useState<{
    state: 'ERROR' | 'CONFIRM' | '';
    message: string;
    description?: string;
  }>({ state: '', message: '' });
  const [isActive, setIsActive] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isAutoLogin, setIsAutoLogin] = useState(false);
  const [isEmailVerificationStep, setIsEmailVerificationStep] = useState(false);
  const [timer, setTimer] = useState(VERIFY_CODE_TIME);
  const [startTimer, setStartTimer] = useState(Date.now());
  const history = useHistory();

  const { register, handleSubmit, watch, getValues, setValue } = useForm({
    defaultValues: {
      username: '',
      password: '',
      verifyCode: '',
    },
  });

  const loginMutation = useMutation(
    (payload: ISignInPayload) => signIn(payload),
    {
      retry: false,
      onSuccess: () => {
        onSendEmailVerification();
        setActionAlert({
          state: 'CONFIRM',
          message: `'${username}@ktaxpay.com' 으로 인증 코드가 전송되었습니다.`,
          description:
            '*메일이 정상적으로 도착하지 않았다면 스팸 메일함을 확인해주세요.',
        });
        setTimer(VERIFY_CODE_TIME);
        setStartTimer(Date.now());
      },
      onError: () => {
        setActionAlert({
          state: 'ERROR',
          message: '이메일 또는 비밀번호가 올바르지 않습니다.',
        });
      },
    },
  );

  const loginVerifyMutation = useMutation(
    (payload: SignInVerifyPayload) => signInVerify(payload),
    {
      retry: false,
      onSuccess: (res) => {
        setActionAlert({ state: '', message: '' });
        if (isAutoLogin) {
          const today = new Date();
          localStorage.setItem('autoLogin', 'true');
          localStorage.setItem('loginExpires', today.getTime().toString());
        }
        localStorage.setItem('loginToken', res.token);
        history.push('/ktp/prod/home');
      },
      onError: () => {
        setActionAlert({
          state: 'ERROR',
          message: '인증 코드가 올바르지 않습니다.',
        });
      },
    },
  );

  const onLogin = ({ username, password }: IForm, e: any) => {
    e.preventDefault();
    const payload = {
      username,
      password,
    };
    loginMutation.mutate(payload);
  };

  const onSendEmailVerification = () => {
    setIsEmailVerificationStep(true);
    setIsActive(false);
  };

  const onCheckEmailCode = (
    { username, password, verifyCode }: IForm,
    e: any,
  ) => {
    e.preventDefault();
    const payload = {
      username,
      password,
      verifyCode: verifyCode.trim(),
    };
    loginVerifyMutation.mutate(payload);
  };

  const onResend = () => {
    const payload = {
      username: getValues().username,
      password: getValues().password,
    };
    loginMutation.mutate(payload);
    setValue('verifyCode', '');
  };

  const onEnter = (e: KeyboardEvent) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      const { username, password, verifyCode } = getValues();
      onCheckEmailCode({ username, password, verifyCode }, e);
    }
  };

  const { username, password, verifyCode } = watch();

  useEffect(() => {
    // 이메일 인증 단계에서 아이디 또는 비밀번호 수정 시 이메일 인증 필드 제거
    setActionAlert({ state: '', message: '' });
    if (isEmailVerificationStep) {
      setIsEmailVerificationStep(false);
    }
  }, [username, password]);

  useEffect(() => {
    const isFullFilled = isEmailVerificationStep
      ? !!(username && password && verifyCode)
      : !!(username && password);
    setIsActive(isFullFilled);
  }, [username, password, verifyCode, isEmailVerificationStep]);

  useEffect(() => {
    setIsLoading(true);
    if (localStorage.getItem('autoLogin')) {
      history.push('/ktp/prod/home');
    }
    setIsLoading(false);
  }, []);

  const convertNumberTo2Digits = (num: number) => String(num).padStart(2, '0');

  const timerViewer = useMemo(() => {
    const min = Math.floor(timer / 60);
    const sec = timer - min * 60;
    return `${convertNumberTo2Digits(min)}:${convertNumberTo2Digits(sec)}`;
  }, [timer]);

  useEffect(() => {
    const id = setInterval(() => {
      const elapsedTime = Math.floor((Date.now() - startTimer) / 1000);
      setTimer(VERIFY_CODE_TIME - elapsedTime);
    }, 1000);

    if (timer === 0) {
      clearInterval(id);
    }
    return () => clearInterval(id);
  }, [startTimer, timer]);

  return (
    <LoginContainer>
      {isLoading ? (
        <LoadingView />
      ) : (
        <LoginSection>
          <LoginForm
            onSubmit={handleSubmit(
              isEmailVerificationStep ? onCheckEmailCode : onLogin,
            )}
          >
            <Image src={Logo} />
            <InputContainer>
              <StyledInput
                placeholder='아이디'
                autoFocus
                {...register('username', { required: true })}
              ></StyledInput>
            </InputContainer>
            <InputContainer>
              <StyledInput
                placeholder='비밀번호'
                type='password'
                {...register('password', { required: true })}
              ></StyledInput>
            </InputContainer>
            {isEmailVerificationStep && (
              <InputContainer>
                <StyledInput
                  placeholder='인증 코드'
                  {...register('verifyCode', { required: true })}
                  style={{ marginTop: '10px', marginBottom: '10px' }}
                  tabIndex={0}
                  autoFocus
                  onKeyDown={onEnter}
                ></StyledInput>
                <Timer>{timerViewer}</Timer>
                <ResendButton onClick={onResend}>재전송</ResendButton>
              </InputContainer>
            )}
            <FlexWrap alignItems='center' gap='8px'>
              <Checkbox
                type='checkbox'
                checked={isAutoLogin}
                onChange={() => setIsAutoLogin(!isAutoLogin)}
              />
              <Label>자동 로그인</Label>
            </FlexWrap>

            <Button active={isActive} disabled={!isActive}>
              로그인
            </Button>
          </LoginForm>
          {!!actionAlert.state && (
            <AlertWrapper>
              <Alert>
                {actionAlert.state === 'ERROR' ? (
                  <Icon.WarningFilled fillColor='#ff2d55' size={20} />
                ) : (
                  <Icon.CheckFilled fillColor='#246CF6' size={20} />
                )}
                {actionAlert.message}
              </Alert>
              {actionAlert.description && (
                <AlertDesc>{actionAlert.description}</AlertDesc>
              )}
            </AlertWrapper>
          )}
        </LoginSection>
      )}
    </LoginContainer>
  );
};

const LoginContainer = styled.div`
  display: flex;
  width: 100%;
  height: 100vh;
  align-items: center;
  justify-content: center;
`;
const Image = styled.img`
  width: 90%;
  margin-bottom: 30px;
`;
const LoginForm = styled.form`
  width: 355px;
  display: flex;
  flex-direction: column;
`;
const InputContainer = styled(FlexWrap)`
  position: relative;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  min-height: 55px;
  justify-content: flex-start;
`;
const StyledInput = styled.input`
  width: 100%;
  border: 1px solid #cbccce;
  ::placeholder {
    font-weight: 300;
  }
  border-radius: 4px;
  padding: 12px 15px;
  outline: none;
  font-size: 15px;
`;
const Checkbox = styled.input`
  width: 18px;
  height: 18px;
`;
const Label = styled.label`
  font-size: 14px;
  color: #757575;
`;
const Button = styled.button<{ active: boolean }>`
  background-color: ${(props) => (props.active ? '#354eff' : '#EDEEEF')};
  color: ${(props) => (props.active ? '#ffffff' : '#9FA1A7')};
  padding: 13px;
  font-size: 18px;
  border-radius: 4px;
  margin-top: 12px;
  :disabled {
    cursor: default;
  }
`;
const LoginSection = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;
const AlertWrapper = styled.div`
  top: calc(100% + 32px);
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
`;
const Alert = styled.p`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  border-radius: 4px;
  padding: 8px 20px;
  background-color: #f5f6f7;
`;
const AlertDesc = styled.span`
  margin-top: 10px;
  font-size: 14px;
  color: #a7a7a7;
`;
const Timer = styled.span`
  position: absolute;
  top: 50%;
  right: 20px;
  transform: translateY(-50%);
  font-size: 14px;
  color: #e42939;
`;
const ResendButton = styled.button`
  position: absolute;
  top: 50%;
  right: -84px;
  transform: translateY(-50%);
  width: 78px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 6px;
  font-size: 14px;
  font-weight: 500;
  color: #3a3b3e;
  background-color: #e5e6e8;
`;
export default SignIn;
