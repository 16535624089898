import { checkDeparture } from '@/api/medical';
import { Icon, Modal } from '@successmode-ktp/kui';
import { useState } from 'react';
import { useMutation, useQueryClient } from 'react-query';
import styled from 'styled-components';

type Props = {
  refundId: number;
};

function DepartureCheckSection({ refundId }: Props) {
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [isOpenWarningModal, setIsOpenWarningModal] = useState(false);

  const queryClient = useQueryClient();

  const { mutate: mutateCheck } = useMutation(checkDeparture);
  const onCheckDeparture = () => {
    mutateCheck(refundId, {
      onSuccess: (data: any) => {
        if (data.status === 'DEPARTURE_WAIT') {
          setIsOpenWarningModal(true);
        } else {
          queryClient.invalidateQueries('hospitalRefundDetail');
          setIsOpenModal(false);
        }
      },
      onError: () => {
        alert('출국 확인에 실패하였습니다.');
        setIsOpenModal(false);
      },
    });
  };

  return (
    <>
      <DepartureButton onClick={() => setIsOpenModal(true)}>
        출국 확인하기
      </DepartureButton>
      {isOpenModal && (
        <Modal isOpen={isOpenModal} hasBackdrop>
          <Modal.Header>
            <Title>
              <Icon.QuestionFilled fillColor='#CBCCCE' size={28} />
              고객 출국확인
            </Title>
          </Modal.Header>
          <Content>출국 여부를 확인하시겠습니까?</Content>
          <Modal.Footer>
            <Modal.Button
              variant='cancel'
              onClick={() => setIsOpenModal(false)}
            >
              닫기
            </Modal.Button>
            <Modal.Button variant='ok' onClick={onCheckDeparture}>
              출국확인
            </Modal.Button>
          </Modal.Footer>
        </Modal>
      )}
      {isOpenWarningModal && (
        <Modal.Confirm
          state='fail'
          isOpen={isOpenWarningModal}
          onClick={() => {
            setIsOpenModal(false);
            setIsOpenWarningModal(false);
          }}
          title='미출국자'
          message='출국 조회 결과 미출국자입니다.'
        />
      )}
    </>
  );
}

const DepartureButton = styled.button`
  position: absolute;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  padding: 6px 12px;
  width: auto;
  height: 33px;
  margin-right: 20px;
  background-color: #ffffff;
  border-radius: 6px;
  border: 1px solid #e5e6e8;
  font-size: 14px;
  font-weight: 500;
  color: #030303;
`;

const Title = styled.h4`
  display: flex;
  align-items: center;
  gap: 8px;
  margin: 0 0 16px 0;
  font-size: 21px;
  font-weight: 700;
  color: #030303;
`;

const Content = styled.p`
  font-size: 16px;
  font-weight: 500;
  color: #5f6165;
  width: 436px;
`;

export default DepartureCheckSection;
