import { format } from 'date-fns';
import styled from 'styled-components';
import { TableRow } from '@material-ui/core';

import CustomTableCell from '@/components/common/CustomTableCell';
import { INoticeItem } from '@/types/api/medical';
import { convertNumberToTwoDigits } from '@/utils/medical';

interface IProps {
  data: INoticeItem;
  onPressList?: () => void;
}
function PrimaryNoticeList({ data, onPressList }: IProps) {
  const { noticeIndex, title, scheduledDate, writer } = data;
  return (
    <StyledTableRow onClick={onPressList}>
      <CustomTableCell width='45' idx={0}>
        {convertNumberToTwoDigits(noticeIndex)}
      </CustomTableCell>
      <CustomTableCell width='350'>
        <Title>{title}</Title>
      </CustomTableCell>
      <CustomTableCell width='120'>
        {format(new Date(scheduledDate), 'yyyy.MM.dd')}
      </CustomTableCell>
      <CustomTableCell width='120'>{writer}</CustomTableCell>
    </StyledTableRow>
  );
}
const StyledTableRow = styled(TableRow)`
  background-color: #ffffff;
  :hover {
    background-color: #f7f9fc;
  }
`;
const Title = styled.span`
  :hover {
    text-decoration: underline;
    cursor: pointer;
  }
`;
export default PrimaryNoticeList;
