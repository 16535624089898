import React, { useState } from 'react';
import Container from '@material-ui/core/Container';
import TextareaAutosize from '@material-ui/core/TextareaAutosize';
import styled from 'styled-components';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import { convertJasypt } from '../../api/developer';
import { useMutation } from 'react-query';

const Jasypt = () => {
  const [configText, setConfigText] = useState('');
  const [password, setPassword] = useState('');
  const mutation = useMutation<any, any, any>(
    ['convertJasypt'],
    (payload) => convertJasypt(payload),
    {
      retry: false,
      onSuccess: async (data) => {
        setConfigText(data);
      },
      onError: (err) => {
        console.log('err', err);
      },
    },
  );
  const handleChangeConfigText = (e: any) => {
    setConfigText(e.target.value);
  };

  const handleChangePassword = (e: any) => {
    setPassword(e.target.value);
  };

  const handleClickConvertButton = async (e: any) => {
    const category = e.target.parentNode.id;
    mutation.mutate({ category, password, configText });
  };

  return (
    <StyledContainer>
      <StyledTextareaAutosize
        placeholder='Write your configuration in "application.yml"'
        value={configText}
        minRows={30}
        onChange={handleChangeConfigText}
      ></StyledTextareaAutosize>
      <TextField
        autoFocus
        margin='dense'
        id='password'
        value={password}
        label='Write password to encrypt or decrypt in Jasypt'
        fullWidth
        onChange={handleChangePassword}
      />
      <div>
        <StyledButton
          id='encrypt'
          variant='outlined'
          color='primary'
          onClick={handleClickConvertButton}
        >
          암호화
        </StyledButton>
        <StyledButton
          id='decrypt'
          variant='outlined'
          color='secondary'
          onClick={handleClickConvertButton}
        >
          복호화
        </StyledButton>
      </div>
    </StyledContainer>
  );
};

const StyledContainer = styled(Container)`
  display: flex;
  height: 50%;
  flex-direction: column;
  justify-content: center;
  margin: 0;
`;

const StyledTextareaAutosize = styled(TextareaAutosize)`
  width: 100%;
  height: 500px;
  padding: 0;
  margin: 0;
`;

const StyledButton = styled(Button)`
  width: 50%;
`;

export default Jasypt;
