import React, { useCallback, useState } from 'react';
import styled from 'styled-components';
import { Container, TableBody } from '@material-ui/core';
import { useQuery } from 'react-query';

import { getSearchKeywords, getWithdrawalLists } from '@api/ktp';
import CheckboxForm from '@components/common/CheckboxForm';
import DetailModal from '@components/ktp/point-withdrawal/DetailModal';
import ListItem from '@components/ktp/point-withdrawal/ListItem';
import Title from '@components/common/Title';
import { pointWithdrawalData } from '@constants/ktp';
import usePointReducer from '@hooks/ktp/usePointReducer';
import DataTable from '@components/common/DataTable';
import LoadingView from '@/components/common/LoadingView';
import Pagination from '@/components/common/Pagination';
import { useLocation } from 'react-router-dom';
import Search from '@/components/common/Search';

function PointWithdrawal() {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const currentPage = searchParams.get('page') || '0';
  const searchKeyword = searchParams.get('search') || '';
  const [state, dispatch] = usePointReducer();
  const { isOpenDetail, toggle, filter, selected } = state;
  const [totalPage, setTotalPage] = useState(0);
  const [isFirstPage, setIsFirstPage] = useState(false);
  const [isLastPage, setIsLastPage] = useState(false);
  const { columns, isAllRadio, pointStatusRadio } = pointWithdrawalData;

  const { isLoading, data: pointData } = useQuery(
    ['withdrawalLists', filter, currentPage, searchKeyword],
    () => getWithdrawalLists(filter, currentPage, searchKeyword),
    {
      onSuccess: ({ totalPages, first, last }) => {
        setTotalPage(totalPages);
        setIsFirstPage(first);
        setIsLastPage(last);
      },
      retry: false,
      keepPreviousData: true,
    },
  );
  const { data: keywords } = useQuery('keywords', getSearchKeywords, {
    retry: false,
  });
  const onOpen = (selected: any) => {
    dispatch({
      type: 'OPEN_MODAL',
      payload: { selected },
    });
  };

  const onClose = useCallback((selected) => {
    dispatch({
      type: 'CLOSE_MODAL',
      payload: { selected },
    });
  }, []);

  const onFilter = useCallback(
    (e) => {
      const { name, value } = e.target;
      dispatch({ type: 'SET_TOGGLE', payload: null });
      dispatch({
        type: 'SET_FILTER',
        payload: { ...filter, [name]: value },
      });
    },
    [filter],
  );

  if (isLoading) {
    return <LoadingView />;
  }
  return (
    <StyledContainer maxWidth='xl'>
      <Title>포인트 정산 관리</Title>
      {keywords && <Search data={keywords} />}
      <DataTable
        columns={columns}
        dispatch={dispatch}
        toggle={toggle}
        contentComponent={
          <TableBody>
            {pointData?.content?.map((item: any, idx: number) => (
              <ListItem
                key={item.pointsIndex}
                idx={idx}
                data={item}
                onOpen={onOpen}
              />
            ))}
          </TableBody>
        }
      />
      {pointData?.content?.length === 0 && (
        <ContentContainer>
          포인트 출금 요청이 존재하지 않습니다.
        </ContentContainer>
      )}
      {isOpenDetail && <DetailModal onClose={onClose} selected={selected} />}
      {toggle && (
        <CheckboxForm
          valueArray={toggle === 'isAll' ? isAllRadio : pointStatusRadio}
          defaultValue={filter[toggle]}
          type={toggle}
          onFilter={onFilter}
        />
      )}
      <BottomSection>
        {totalPage > 0 && (
          <Pagination
            initialPage={currentPage}
            totalPage={totalPage}
            isLastPage={isLastPage}
            isFirstPage={isFirstPage}
            displaySize={5}
          />
        )}
      </BottomSection>
    </StyledContainer>
  );
}
const StyledContainer = styled(Container)`
  display: flex;
  flex-direction: column;
  margin: 0;
`;
const ContentContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 200px;
  color: gray;
  font-size: 16px;
`;

const BottomSection = styled.div`
  margin: 20px 0;
`;

export default PointWithdrawal;
