import React, { ReactNode, RefObject } from 'react';
import styled from 'styled-components';
import Portal from '../../common/Portal';

type Props = {
  title: string;
  children: ReactNode | ReactNode[];
  onCancel: () => void;
  onOK: () => void;
  cancelLabel?: string;
  okLabel?: string;
  buttonRef?: RefObject<HTMLButtonElement>;
};

function ConfirmModal({
  title,
  children,
  onCancel,
  onOK,
  cancelLabel = '닫기',
  okLabel = '확인',
  buttonRef,
}: Props) {
  return (
    <Portal isOpaqueBackdrop zIndex={150}>
      <Popover>
        <Title>{title}</Title>
        {children}
        <ButtonWrapper>
          <Button onClick={onCancel} ref={buttonRef}>
            {cancelLabel}
          </Button>
          <Button color='blue' type='submit' onClick={onOK}>
            {okLabel}
          </Button>
        </ButtonWrapper>
      </Popover>
    </Portal>
  );
}

const Popover = styled.aside`
  display: flex;
  flex-direction: column;
  gap: 16px;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 32px;
  width: 464px;
  background-color: #ffffff;
  border-radius: 16px;
  box-shadow: 0px 0px 30px 0px #00000014;
  z-index: 150;
`;

const Title = styled.h4`
  font-size: 21px;
  font-weight: 700px;
  line-height: 32px;
  color: #030303;
  text-align: center;
  margin: 0;
`;

const ButtonWrapper = styled.footer`
  display: flex;
  gap: 16px;
`;

const Button = styled.button<{ color?: string }>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 48px;
  font-size: 16px;
  font-weight: 600;
  color: ${({ color }) => (color === 'blue' ? '#ffffff' : '#3A3B3E')};
  background-color: ${({ color }) =>
    color === 'blue' ? '#246CF6' : '#e5e6e8'};
  border-radius: 8px;
`;

export default ConfirmModal;
