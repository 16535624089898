import styled from 'styled-components';
import format from 'date-fns/format';
import { TableRow } from '@material-ui/core';

import {
  formatDateDot,
  formattedDepartureStatus,
  formattedPaymentStatus,
  formattedRefundStatus,
} from '../../../utils/ktp';
import CustomTableCell from '@/components/common/CustomTableCell';
import DetailBtn from '@/components/common/DetailBtn';

// 'APPROVAL' | 'CANCEL' | 'REJECT'
const RefundStatusItem = ({ refund, idx, refundType, onOpenModal }) => {
  const {
    createdDate,
    refundIndex,
    refundStatus,
    departureStatus,
    paymentStatus,
    storeName,
    customerNational,
    customerName,
    totalAmount,
    totalRefund,
    refundUploadS3Path,
    willBeUploadReceipt,
    gender,
    birth,
    immediate,
    kioskRefund,
  } = refund;

  const isAfterRefund =
    +totalAmount > 1000000 ||
    customerNational === 'KOR' ||
    refundStatus === 'PRE_APPROVAL' ||
    paymentStatus;
  const isRefundStatusCancel = refundStatus === 'CANCEL';
  return (
    <StyledTableRow
      style={
        (idx + 1) % 5 === 0
          ? { borderBottom: '2px solid rgb(220, 220, 220)' }
          : { borderBottom: '1px solid rgb(220, 220, 220)' }
      }
    >
      <CustomTableCell idx={0} color={isRefundStatusCancel && '#BDBDBD'}>
        {refundIndex}
      </CustomTableCell>
      <CustomTableCell color={isRefundStatusCancel && '#BDBDBD'}>
        {format(new Date(createdDate), 'yyyy.MM.dd HH:mm')}
      </CustomTableCell>
      <CustomTableCell color={isRefundStatusCancel && '#BDBDBD'}>
        {storeName}
      </CustomTableCell>
      <CustomTableCell color={isRefundStatusCancel && '#BDBDBD'}>
        {customerNational}
      </CustomTableCell>
      <CustomTableCell color={isRefundStatusCancel && '#BDBDBD'}>
        {customerName}
      </CustomTableCell>
      <CustomTableCell color={isRefundStatusCancel && '#BDBDBD'}>
        {formatDateDot(birth) || '-'}
      </CustomTableCell>
      <CustomTableCell color={isRefundStatusCancel && '#BDBDBD'} isCenter>
        {gender || '-'}
      </CustomTableCell>
      <CustomTableCell color={isRefundStatusCancel && '#BDBDBD'}>
        {(+totalAmount).toLocaleString('ko-KR')}원
      </CustomTableCell>
      <CustomTableCell color={isRefundStatusCancel && '#BDBDBD'}>
        {(+totalRefund).toLocaleString('ko-KR')}원
      </CustomTableCell>
      <CustomTableCell color={refundStatus === 'CHECK' && '#E42939'}>
        {formattedRefundStatus(refundStatus, paymentStatus)}
      </CustomTableCell>

      {refundType !== 'IMMEDIATE' && (
        <>
          <CustomTableCell color={isRefundStatusCancel && '#BDBDBD'}>
            {immediate ? '-' : formattedDepartureStatus(departureStatus)}
          </CustomTableCell>
        </>
      )}

      <CustomTableCell color={paymentStatus === 'PAYMENT_WAIT' && '#E42939'}>
        {formattedPaymentStatus(paymentStatus)}
      </CustomTableCell>

      {refundType === 'AFTER' && (
        <CustomTableCell color={isRefundStatusCancel && '#BDBDBD'}>
          {willBeUploadReceipt ? (
            <DetailBtn
              disabled={!refundUploadS3Path}
              color={refundUploadS3Path && '#354EFF'}
              onClick={
                refundUploadS3Path
                  ? () => onOpenModal({ imgUrl: refundUploadS3Path })
                  : () => {}
              }
            >
              {refundUploadS3Path ? '미리보기' : '미업로드'}
            </DetailBtn>
          ) : (
            '-'
          )}
        </CustomTableCell>
      )}

      <CustomTableCell>
        <DetailBtn onClick={() => onOpenModal({ refundIndex })}>
          상세보기
        </DetailBtn>
      </CustomTableCell>
      <CustomTableCell color={isRefundStatusCancel && '#BDBDBD'}>
        {refundStatus === 'CANCEL' ? (
          '-'
        ) : (
          <DetailBtn
            color='#EB5757'
            onClick={() =>
              onOpenModal({
                refundIndex,
                storeName,
                customerNational,
                customerName,
                totalRefund,
                totalAmount,
                kioskRefund,
                paymentStatus,
              })
            }
          >
            환급취소
          </DetailBtn>
        )}
      </CustomTableCell>
    </StyledTableRow>
  );
};
const StyledTableRow = styled(TableRow)`
  background-color: #ffffff;
  :hover {
    background-color: #f7f9fc;
  }
`;
export default RefundStatusItem;
