import { Button, Dropdown, Icon, MultiSelection } from '@successmode-ktp/kui';
import { Dispatch, useEffect, useState } from 'react';
import styled from 'styled-components';
import HospitalDateSelector from '../common/HospitalDateSelector';
import useUpdateUrlParams from '@/hooks/sales/useUpdateUrlParams';
import { CASHBILL_STATUS } from '@/types/api/medical';

type Props = {
  initialSize: string;
  filter: CASHBILL_STATUS[];
  setFilter: (filter: string[]) => void;
  dispatch: Dispatch<any>;
  dateFilter: 'All' | 'WEEK' | 'MONTH' | 'THREE_MONTH' | 'SIX_MONTH' | 'CUSTOM';
  onRequestIssue: () => void;
  onUpdateStatus: () => void;
};

function DataSelection({
  initialSize,
  filter,
  setFilter,
  dispatch,
  dateFilter,
  onRequestIssue,
  onUpdateStatus,
}: Props) {
  const updateUrlParams = useUpdateUrlParams();
  const [size, setSize] = useState<string | number>(initialSize || '100');

  const statusOptions = [
    {
      label: '취소',
      value: 'CANCELED',
    },
    {
      label: '미발급',
      value: 'NOT_ISSUED',
    },
    {
      label: '전송완료',
      value: 'TRANSFERRED',
    },
    {
      label: '전송중',
      value: 'TRANSFER_IN_PROGRESS',
    },
    {
      label: '발급완료',
      value: 'ISSUED',
    },
  ];

  const viewOptions = [
    {
      label: '10개씩 보기',
      value: '10',
    },
    {
      label: '100개씩 보기',
      value: '100',
    },
    {
      label: '500개씩 보기',
      value: '500',
    },
  ];

  const onResetFilter = () => {
    const allSelection = statusOptions.map(
      (option) => option.value,
    ) as CASHBILL_STATUS[];
    setFilter(allSelection);
  };

  useEffect(() => {
    setSize(initialSize);
  }, [initialSize]);

  return (
    <Section>
      <LeftSection>
        <MultiSelection
          title='구분'
          key='barobillStatus'
          name='barobillStatus'
          options={statusOptions}
          selectedItems={filter}
          setSelectedItems={setFilter}
        />
        <Button
          onClick={onResetFilter}
          theme='mono'
          size='sm'
          style={{ width: '111px', backgroundColor: '#E5E6E8', gap: '8px' }}
        >
          <Icon.Reload size={20} fillColor='#5F6165' />
          필터 초기화
        </Button>
      </LeftSection>
      <RightSection>
        <RequestButton onClick={onRequestIssue}>재발급 요청</RequestButton>
        <ReloadButton onClick={onUpdateStatus}>
          <Icon.Reload size={24} fillColor='#5f6165' />
        </ReloadButton>
        <Dropdown
          options={viewOptions}
          selectedValue={size}
          setSelectedValue={(filter) => {
            setSize(filter);
            updateUrlParams({ size: filter as string });
          }}
          size='md'
          style={{
            width: '160px',
          }}
          variant='solid'
        />
        <HospitalDateSelector
          top='120px'
          right='50px'
          dateFilter={dateFilter}
          dispatch={dispatch}
          position='static'
        />
      </RightSection>
    </Section>
  );
}

const Section = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 25px;
`;

const LeftSection = styled.div`
  display: flex;
  gap: 20px;
  // MultiSelection Styling
  > div {
    align-items: flex-start;
  }
  ul {
    flex-wrap: wrap;
    width: 324px;
  }

  // reset button styling
  button {
    position: relative;
    ::before {
      content: '';
      width: 1px;
      height: 29px;
      background-color: #e5e6e8;
      position: absolute;
      top: 50%;
      left: -10px;
      transform: translateY(-50%);
    }
  }
`;

const RightSection = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;
`;

const ReloadButton = styled.button`
  width: 24px;
  height: 24px;
`;

const RequestButton = styled.button`
  display: inline-flex;
  height: 40px;
  padding: 6px 10px;
  justify-content: center;
  align-items: center;
  border-radius: 6px;
  background-color: #3a3b3e;
  color: #ffffff;
  font-size: 14px;
`;

export default DataSelection;
