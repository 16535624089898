import { Button, Modal, Popover } from '@successmode-ktp/kui';
import { useEffect, useState } from 'react';
import DownloadPDF from './DownloadPDF';
import styled from 'styled-components';
import DateSelection from '../common/DateSelection';

type Props = {
  checkedFranchisee: { id: number; name: string }[];
};

function GenerateReport({ checkedFranchisee }: Props) {
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [canPrint, setCanPrint] = useState(-1);

  const printPagesSequentially = async () => {
    for (let i = 0; i < checkedFranchisee.length; i++) {
      if (!isOpenModal) {
        break;
      }
      setCanPrint(i);
      await waitForPrintCompletion(i);
    }
    setCanPrint(-1);
  };

  const waitForPrintCompletion = (currentIndex: number): Promise<void> => {
    return new Promise((resolve) => {
      let attempts = 0;
      const maxAttempts = 10;

      const checkCompletion = () => {
        if (canPrint === currentIndex + 1 || !isOpenModal) {
          resolve();
        } else if (attempts < maxAttempts) {
          attempts += 1;
          setTimeout(checkCompletion, 300);
        } else {
          resolve();
        }
      };

      checkCompletion();
    });
  };

  const isDisabledButton = canPrint !== -1;

  useEffect(() => {
    if (!isOpenModal) {
      setCanPrint(-1);
    }
  }, [isOpenModal]);

  const [year, setYear] = useState('');
  const [month, setMonth] = useState('');

  useEffect(() => {
    const currentYear = new Date().getFullYear();
    const currentMonth = new Date().getMonth() + 1;

    setYear(currentYear.toString());
    setMonth(currentMonth.toString());
  }, []);

  return (
    <Wrapper>
      <Button
        size='sm'
        theme='mono'
        style={{ width: '174px' }}
        onClick={() => setIsOpenModal(true)}
        disabled={checkedFranchisee.length === 0}
      >
        매출리포트 다운로드
      </Button>
      {isOpenModal && (
        <Popover
          isOpen={isOpenModal}
          hasBackdrop
          style={{
            padding: '32px',
            borderRadius: '16px',
            maxHeight: '90vh',
            overflowY: 'auto',
            overflow: 'visible',
          }}
        >
          <Modal.Header>매출리포트 다운로드</Modal.Header>
          <Content>
            <Description>
              선택된 상호명을 확인 후 기간을 선택해주세요.
            </Description>
            <StoreList>
              {checkedFranchisee.map(({ id, name }) => (
                <StoreItem key={id}>{name}</StoreItem>
              ))}
            </StoreList>
            <DateSelection
              year={year}
              month={month}
              onChangeYear={setYear}
              onChangeMonth={setMonth}
              size='lg'
              isFull
            />
          </Content>
          <Modal.Footer>
            <Button theme='mono' onClick={() => setIsOpenModal(false)}>
              닫기
            </Button>
            <Button
              theme='primary'
              onClick={printPagesSequentially}
              disabled={isDisabledButton}
              loading={isDisabledButton}
            >
              다운로드
            </Button>
          </Modal.Footer>

          {/* 생성될 매출리포트 (미리보기X) */}
          {checkedFranchisee.map(({ id, name }, index) => (
            <DownloadPDF
              key={index}
              franchiseeId={id}
              franchiseeName={name}
              title={`${year}-${month}-${name}`}
              year={year}
              month={month}
              canPrint={canPrint === index}
            />
          ))}
        </Popover>
      )}
    </Wrapper>
  );
}

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

const Content = styled.div`
  width: 436px;
`;

const Description = styled.p`
  margin-top: 12px;
  color: #5f6165;
  font-size: 14px;
  font-weight: 500;
`;

const StoreList = styled.ul`
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding: 12px;
  background-color: #f5f6f7;
  margin: 16px 0 24px 0;
  height: 100%;
  max-height: 150px;
  overflow-y: auto;
`;

const StoreItem = styled.li`
  color: #5f6165;
  font-size: 14px;
  font-weight: 500;
`;

export default GenerateReport;
