import { Fragment, useState } from 'react';
import { useMutation } from 'react-query';
import styled, { css } from 'styled-components';
import { TextField, Typography } from '@material-ui/core';
import { comma, getRefundAmount, removeComma } from '../../../utils/ktp';
import ActionBtn from '@/components/common/ActionBtn';
import { ProductTypeList } from '@/constants/medical';
import { foramttedDateHypen, formattedUserName } from '@/utils/common';
import { refundApproval } from '@/api/medical';
import { RefundApprovalBodyType } from '@/types/api/medical';
import { AxiosError } from 'axios';
import debounce from '@/utils/debounce.util';
import FlexWrap from '@/components/common/FlexWrap';

type RefundSectionProps = {
  hospitalIndex: number;
  paymentType: 'ORIGINAL' | 'DIRECT';
};
function RefundSection({ hospitalIndex, paymentType }: RefundSectionProps) {
  const initialRefundData = {
    hospitalIndex,
    refund: '',
    name: '',
    nationality: '',
    passportNumber: '',
    birthDate: '',
    productType: '',
    supplyDate: '',
    paymentCash: '',
    paymentCard: '',
  };
  const [refundData, setRefundData] = useState(initialRefundData);
  const {
    refund,
    name,
    nationality,
    passportNumber,
    birthDate,
    productType,
    supplyDate,
    paymentCash,
    paymentCard,
  } = refundData;
  const [isForce, setIsForce] = useState(false);
  const refundMutation = useMutation<any, AxiosError, RefundApprovalBodyType>(
    (data) => refundApproval(data),
    {
      onSuccess: () => {
        alert('환급이 완료되었습니다.');
        setRefundData(initialRefundData);
        setIsForce(false);
      },
      onError: (err) => {
        alert(`환급에 실패하였습니다.\n${err?.response?.data?.message}`);
      },
      retry: false,
    },
  );

  const onChanceRefundInput = (e: any) => {
    const { name, value } = e.target;
    if (name === 'paymentCard' || name === 'paymentCash') {
      setRefundData((prev) => {
        return {
          ...prev,
          [name]: comma(removeComma(value)),
          refund: comma(
            getRefundAmount(
              name === 'paymentCash'
                ? +removeComma(paymentCard) + +removeComma(value)
                : +removeComma(paymentCash) + +removeComma(value),
            ),
          ),
        };
      });
    } else if (name === 'birthDate') {
      setRefundData((prev) => {
        return { ...prev, [name]: foramttedDateHypen(value) };
      });
    } else if (name === 'name') {
      setRefundData((prev) => {
        return { ...prev, [name]: formattedUserName(value) };
      });
    } else {
      setRefundData((prev) => {
        return { ...prev, [name]: value.toUpperCase() };
      });
    }
  };
  const onRefund = (type: 'ORIGINAL' | 'DIRECT') => {
    const confirmMessage = isForce
      ? '강제환급 하시겠습니까?'
      : '환급하시겠습니까?';
    if (window.confirm(confirmMessage)) {
      const totalPaymentCard = removeComma(paymentCard) || '0';
      const totalPaymentCash = removeComma(paymentCash) || '0';
      const totalPayment = +totalPaymentCard + +totalPaymentCash;

      const payload = {
        hospitalIndex,
        supplyDate: supplyDate.slice(0, 10),
        totalRefund: removeComma(refund),
        totalPrice: totalPayment,
        paymentType: type,
        name,
        nationality,
        birthDate,
        passportNumber,
        orderInfoList: [
          {
            productType,
            price: totalPayment,
          },
        ],
        paymentCard:
          type === 'ORIGINAL'
            ? totalPaymentCard - removeComma(refund)
            : totalPaymentCard,
        paymentCash: totalPaymentCash,
        isForce,
      };

      refundMutation.mutate(payload);
    }
  };

  const createRefund = (type: 'ORIGINAL' | 'DIRECT') => {
    return debounce(() => onRefund(type), 200);
  };

  return (
    <>
      <Title>환급하기</Title>
      <StyledTable width='100%'>
        <StyledTh>국적</StyledTh>
        <StyledTh>여권번호</StyledTh>
        <StyledTh>이름</StyledTh>
        <StyledTh>생년월일</StyledTh>
        <StyledTh>공급시술</StyledTh>
        <StyledTh>카드결제 금액</StyledTh>
        <StyledTh>현금결제 금액</StyledTh>
        <StyledTh>환급액</StyledTh>
        <StyledTh>공급일자</StyledTh>
        <StyledTr>
          <StyledTd isEditMode>
            <StyledInput
              disabled={false}
              name='nationality'
              value={nationality}
              onChange={onChanceRefundInput}
            />
          </StyledTd>
          <StyledTd isEditMode>
            <StyledInput
              disabled={false}
              name='passportNumber'
              value={passportNumber}
              onChange={onChanceRefundInput}
            />
          </StyledTd>
          <StyledTd isEditMode>
            <StyledInput
              disabled={false}
              name='name'
              value={name}
              onChange={onChanceRefundInput}
            />
          </StyledTd>
          <StyledTd isEditMode>
            <StyledInput
              disabled={false}
              name='birthDate'
              value={birthDate}
              onChange={onChanceRefundInput}
            />
          </StyledTd>
          <StyledTd isEditMode>
            <Select
              name='productType'
              onChange={onChanceRefundInput}
              value={productType}
            >
              {ProductTypeList.map((option, idx) => (
                <Fragment key={idx}>
                  <Option value='' hidden>
                    시술선택
                  </Option>
                  <Option key={option.key} value={option.value}>
                    {option.name}
                  </Option>
                </Fragment>
              ))}
            </Select>
          </StyledTd>
          <StyledTd isEditMode>
            <StyledInput
              disabled={false}
              name='paymentCard'
              value={paymentCard}
              onChange={onChanceRefundInput}
            />
          </StyledTd>
          <StyledTd isEditMode>
            <StyledInput
              disabled={false}
              name='paymentCash'
              value={paymentCash}
              onChange={onChanceRefundInput}
            />
          </StyledTd>
          <StyledTd>{refund}</StyledTd>
          <StyledTd isEditMode>
            <TextField
              id='datetime-local'
              onChange={onChanceRefundInput}
              style={{ width: '180px' }}
              name='supplyDate'
              type='datetime-local'
              defaultValue={new Date()}
              value={supplyDate}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </StyledTd>
        </StyledTr>
      </StyledTable>
      <FlexWrap justifyContent='flex-end' alignItems='center' gap='10px'>
        <CheckBoxContainer>
          <CheckBox
            name='forceInsert'
            type='checkbox'
            checked={isForce}
            onChange={() => setIsForce((prev) => !prev)}
          />
          <Typography>강제환급</Typography>
        </CheckBoxContainer>
        {paymentType !== 'DIRECT' && (
          <ActionBtn
            title='차감 결제'
            bgColor='#354eff'
            onClick={createRefund('ORIGINAL')}
            active={Boolean(
              nationality !== 'KOR' &&
                nationality &&
                passportNumber &&
                name &&
                (paymentCard || paymentCash) &&
                birthDate &&
                productType &&
                supplyDate,
            )}
          />
        )}
        <ActionBtn
          title='전액 결제'
          bgColor='#333333'
          onClick={createRefund('DIRECT')}
          active={Boolean(
            nationality &&
              passportNumber &&
              name &&
              birthDate &&
              productType &&
              supplyDate,
          )}
        />
      </FlexWrap>
    </>
  );
}
const Title = styled.h3`
  font-size: 18px;
  margin-bottom: 16px;
`;
const StyledInput = styled.input`
  color: black;
  border: none;
  outline: none;
  width: 100%;
  padding: 8px 0;
  -moz-appearance: textfield;
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  ${(props) =>
    props.disabled &&
    css`
      background-color: #f2f2f2;
    `}
`;
const borderStyles = css`
  border-collapse: collapse;
  border: 1px solid black;
`;
const StyledTable = styled.table`
  ${borderStyles}
  margin: 0 0 40px;
`;
const StyledTd = styled.td<{ isEditMode?: boolean; disabled?: boolean }>`
  ${borderStyles}
  padding: 0 16px;
  height: 40px;
  font-size: 14px;
  background-color: ${(props) =>
    props.isEditMode ? (props.disabled ? '#f2f2f2' : 'white') : '#f2f2f2'};
  width: 350px;
`;
const StyledTh = styled.td`
  ${borderStyles}
  padding-left: 16px;
  height: 40px;
  font-size: 14px;
  line-height: 40px;
  background-color: #d3d3d3;
  width: 190px;
  color: black;
`;

const StyledTr = styled.tr`
  ${borderStyles}
  padding: 8px 15px;
  font-size: 14px;
`;

const Select = styled.select`
  width: 100%;
  height: 80%;
`;

const Option = styled.option``;

const CheckBoxContainer = styled.div`
  display: flex;
  align-items: center;
`;
const CheckBox = styled.input`
  width: 18px;
  height: 18px;
`;
export default RefundSection;
