import React, { Fragment, useState } from 'react';
import styled, { css } from 'styled-components';
import { useMutation } from 'react-query';

import ActionBtn from '@/components/common/ActionBtn';
import {
  formatHospitalDetailInfo,
  formatApplicantInfo,
  taxFreeNumberFormatted,
} from '@/utils/medical';
import {
  resetPassword,
  updateHospitalInfo,
  uploadHospitalImage,
} from '@/api/medical';
import FlexWrap from '@/components/common/FlexWrap';
import HospitalPreviewModal from '../common/HospitalPreviewModal';
import MedicalEquirement from './MedicalEquirement';
import RefundSection from './RefundSection';
import ProductTypeSelection from './ProductTypeSelection';
import { Button } from '@successmode-ktp/kui';
import AdSetting from '@/components/adSetting/AdSetting';
import BarobillSignUpModal from './BarobillSignUpModal';

function DetailContent({ detailInfo, uploadInfo, hospitalIndex }) {
  const [isEditMode, setIsEditMode] = useState(false);
  const [productTypes, setProductTypes] = useState(detailInfo.productTypes);
  const [equipmentTypes, setEquipmentTypes] = useState(
    detailInfo.equipmentTypes,
  );

  const [isPreviewModal, setIsPreviewModal] = useState(false);
  const [newDetailHospitalInfo, setNewDetailHospitalInfo] =
    useState(detailInfo);
  const [newDetailApplicantInfo, setNewDetailApplicantInfo] = useState({
    ...uploadInfo,
    taxFreeNumber: uploadInfo.taxFreeNumber && 'M-' + uploadInfo.taxFreeNumber,
  });
  const formattedHospitalInfo = formatHospitalDetailInfo(newDetailHospitalInfo);
  const formattedApplicantInfo = formatApplicantInfo(newDetailApplicantInfo);
  const [isImageUploadFile, setIsImageUploadFile] = useState(
    !!newDetailHospitalInfo['hospitalImagePath'],
  );
  const [isOpenBarobillSignUpModal, setIsOpenBarobillSignUpModal] =
    useState(false);
  const updateMutation = useMutation(
    (payload) => updateHospitalInfo(payload, hospitalIndex),
    {
      onSuccess: () => {
        setIsEditMode(false);
        alert('회원 정보 수정이 완료되었습니다.');
      },
      onError: (err) => {
        console.log(err?.response);
        alert('회원 정보 수정에 실패하였습니다.');
      },
      retry: false,
    },
  );

  const imgUploadMutation = useMutation(
    (payload) => uploadHospitalImage(payload, hospitalIndex),
    {
      onSuccess: (res) => {
        alert('이미지가 업로드 되었습니다.');
        setIsImageUploadFile(true);
      },
      onError: (err) => {
        console.log(err?.response);
      },
      retry: false,
    },
  );

  const checkValidate = () => {
    const {
      email,
      managerName,
      hospitalAddress,
      hospitalName,
      hospitalTelNumber,
    } = newDetailHospitalInfo;
    if (
      !Boolean(
        email &&
          managerName &&
          hospitalAddress &&
          hospitalName &&
          hospitalTelNumber,
      )
    ) {
      alert('필수 정보를 모두 입력해주세요.');
      return false;
    }
    if (!email.match(/[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*\.[a-zA-Z]{2,3}$/i)) {
      alert('이메일 형식이 옳지 않습니다.');
      return false;
    }
    return true;
  };

  const onEdit = () => {
    setIsEditMode(true);
    // isEditMode가 true일 때만 실행
    if (!isEditMode) {
      return;
    }

    // validate 체크
    if (!checkValidate()) {
      return;
    }

    // 특례의료기관 지정번호 길이 확인
    const { taxFreeNumber } = newDetailApplicantInfo;
    if (taxFreeNumber.length !== 17) {
      alert('특례의료기관 지정번호는 17자리여야 합니다.');
      return;
    }

    // 최적화된 문자열 치환
    const formattedTaxFreeNumber = taxFreeNumber.startsWith('M-')
      ? taxFreeNumber.slice(2)
      : taxFreeNumber;

    // Mutation 실행
    updateMutation.mutate({
      detailInfo: {
        ...newDetailHospitalInfo,
        productTypes,
        equipmentTypes,
      },
      uploadInfo: {
        ...newDetailApplicantInfo,
        taxFreeNumber: formattedTaxFreeNumber,
      },
    });
  };
  const onChangeFranchisee = (e) => {
    const { name, value } = e.target;
    setNewDetailHospitalInfo({
      ...newDetailHospitalInfo,
      [name]: value,
    });
  };
  const onChangeApplicant = (e) => {
    const { name, value } = e.target;
    if (name === 'taxFreeNumber') {
      setNewDetailApplicantInfo({
        ...newDetailApplicantInfo,
        [name]: taxFreeNumberFormatted(value),
      });
    } else {
      setNewDetailApplicantInfo({
        ...newDetailApplicantInfo,
        [name]: value,
      });
    }
  };
  const onImageUpload = (e) => {
    const { files } = e.target;
    const formData = new FormData();
    formData.append('image', files[0]);
    imgUploadMutation.mutate(formData);
  };
  const onClickPreview = () => {
    setIsPreviewModal(true);
  };

  const resetPasswordMutation = useMutation(
    () => resetPassword(hospitalIndex),
    {
      onSuccess: () => {
        alert('비밀번호가 초기화 되었습니다.\n(초기화 된 비밀번호: 1234)');
      },
      onError: (err) => {
        console.log(err?.response);
        alert('비밀번호 초기화에 실패했습니다.');
      },
      retry: false,
    },
  );
  const onClickReset = () => {
    const result = window.confirm('비밀번호를 초기화 하시겠습니까?');
    if (result) {
      resetPasswordMutation.mutate();
    }
  };

  const [isOpenBanner, setIsOpenBanner] = useState(false);

  return (
    <Container>
      <RefundSection
        hospitalIndex={hospitalIndex}
        paymentType={detailInfo.paymentType}
      />
      <Title>가맹점 현황 상세보기</Title>
      <SubTitleWrapper>
        <SubTitle>회원가입 정보</SubTitle>
        {detailInfo.adEnabled && (
          <ButtonWrapper>
            <Button
              theme='primary'
              variant='solid'
              size='sm'
              onClick={() => setIsOpenBanner(true)}
              enabled
            >
              배너 광고 설정
            </Button>
          </ButtonWrapper>
        )}
      </SubTitleWrapper>
      <StyledTable width='100%' align='center'>
        {formattedHospitalInfo.map((items, idx) => (
          <StyledTr key={idx}>
            {items.map((item, index) => (
              <Fragment key={item.label + index}>
                <StyledTh>{item.label}</StyledTh>
                <StyledTd
                  disabled={item.option?.disabled}
                  isEditMode={isEditMode}
                >
                  <FlexWrap alignItems='center'>
                    {item.option.isMedicalType ? (
                      <ProductTypeSelection
                        productTypes={productTypes}
                        setProductTypes={setProductTypes}
                        isEdit={isEditMode}
                      />
                    ) : item.option.isEquipmentType ? (
                      item.option.list.map((item, idx) => (
                        <MedicalEquirement
                          key={idx}
                          item={item}
                          isEditMode={isEditMode}
                          equipmentTypes={equipmentTypes}
                          setEquipmentTypes={setEquipmentTypes}
                        />
                      ))
                    ) : (
                      <>
                        {item.option.isImageUpload ? (
                          <StyledInput
                            {...item.option}
                            style={
                              !isEditMode
                                ? {
                                    ...item.option.style,
                                    color: isImageUploadFile
                                      ? '#354EFF'
                                      : '#BDBDBD',
                                    border: `2px solid  ${
                                      isImageUploadFile ? '#354EFF' : '#BDBDBD'
                                    }`,
                                    cursor: isImageUploadFile
                                      ? 'pointer'
                                      : 'auto',
                                    borderRadius: '4px',
                                    width: '100px',
                                    padding: '5px 15px',
                                    textAlign: 'center',
                                  }
                                : item.option.style
                            }
                            value={
                              !isEditMode && isImageUploadFile
                                ? '미리보기'
                                : !isEditMode
                                ? '미업로드'
                                : ''
                            }
                            type={isEditMode ? 'file' : 'button'}
                            onChange={onImageUpload}
                            onClick={!isEditMode ? onClickPreview : null}
                          />
                        ) : item.selectName ? (
                          <StyledSelect
                            name={item.selectName}
                            disabled={!isEditMode}
                            value={newDetailHospitalInfo[item.selectName]}
                            onChange={onChangeFranchisee}
                          >
                            {item.option?.map((item, idx) => (
                              <option key={idx} value={item.value}>
                                {item.label}
                              </option>
                            ))}
                          </StyledSelect>
                        ) : item.option.name === 'isBaroBillRegistered' ? (
                          newDetailHospitalInfo.isBaroBillRegistered ? (
                            item.option.value
                          ) : (
                            <>
                              <Button
                                size='sm'
                                style={{ width: '100px' }}
                                onClick={() =>
                                  setIsOpenBarobillSignUpModal(true)
                                }
                              >
                                가입하기
                              </Button>
                              <BarobillSignUpModal
                                isOpen={isOpenBarobillSignUpModal}
                                onClose={() =>
                                  setIsOpenBarobillSignUpModal(false)
                                }
                                hospitalId={hospitalIndex}
                              />
                            </>
                          )
                        ) : (
                          <FlexWrap flex={1}>
                            <StyledInput
                              disabled={!isEditMode}
                              value={newDetailHospitalInfo[item.option.name]}
                              onChange={onChangeFranchisee}
                              {...item.option}
                            />
                            {item.option.name === 'password' && (
                              <ResetButtonContainer>
                                <ResetButton onClick={onClickReset}>
                                  초기화
                                </ResetButton>
                              </ResetButtonContainer>
                            )}
                          </FlexWrap>
                        )}
                      </>
                    )}
                  </FlexWrap>
                </StyledTd>
              </Fragment>
            ))}
          </StyledTr>
        ))}
      </StyledTable>
      <SubTitle>가맹점 신청 정보</SubTitle>
      <StyledTable width='100%' align='center'>
        {formattedApplicantInfo.map((items, index) => (
          <StyledTr key={index}>
            {items.map((item, idx) => (
              <Fragment key={idx}>
                <StyledTh>{item.label}</StyledTh>
                <StyledTd
                  disabled={item.option?.disabled}
                  isEditMode={isEditMode}
                >
                  {!Boolean(
                    [
                      '자동이체 출금일',
                      '은행명',
                      '특례의료기관 지정번호',
                    ].includes(item.label),
                  ) ? (
                    <StyledInput
                      disabled={!isEditMode}
                      value={newDetailApplicantInfo[item.option.name]}
                      onChange={onChangeApplicant}
                      {...item.option}
                    />
                  ) : item?.option?.name === 'taxFreeNumber' ? (
                    <StyledInput
                      disabled={!isEditMode}
                      name={item.option.name}
                      value={newDetailApplicantInfo[item.option.name]}
                      onChange={onChangeApplicant}
                      maxLength={17}
                      placeholder='숫자만 입력하세요'
                    />
                  ) : (
                    <StyledSelect
                      name={item.selectName}
                      disabled={!isEditMode}
                      value={newDetailApplicantInfo[item.selectName]}
                      onChange={onChangeApplicant}
                    >
                      {item.optionList?.map((item) => (
                        <option key={item} value={item}>
                          {item}
                        </option>
                      ))}
                    </StyledSelect>
                  )}
                </StyledTd>
              </Fragment>
            ))}
          </StyledTr>
        ))}
      </StyledTable>
      <BtnWrapper>
        <ActionBtn
          title={isEditMode ? '수정완료' : '수정하기'}
          bgColor='#354EFF'
          onClick={onEdit}
        />
      </BtnWrapper>
      {isPreviewModal && isImageUploadFile && (
        <HospitalPreviewModal
          img={newDetailHospitalInfo['hospitalImagePath']}
          setIsPreviewModal={setIsPreviewModal}
        />
      )}
      {isOpenBanner && (
        <AdSetting
          hospitalId={hospitalIndex}
          onClose={() => setIsOpenBanner(false)}
        />
      )}
    </Container>
  );
}
const Container = styled.div`
  width: 100%;
`;
const Title = styled.h2`
  font-size: 28px;
  margin-bottom: 40px;
`;

const SubTitleWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;
`;

const SubTitle = styled.h3`
  font-size: 18px;
`;

const ButtonWrapper = styled.div`
  width: 100px;
`;

const StyledSelect = styled.select`
  padding: 8px;
  border: none;
  outline: none;
  color: black;
  ${(props) =>
    props.disabled &&
    css`
      color: black;
      background: #f2f2f2;
    `}
`;
const StyledInput = styled.input`
  color: black;
  border: none;
  outline: none;
  width: 100%;
  padding: 8px 0;
  -moz-appearance: textfield;
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  ${(props) =>
    props.disabled &&
    css`
      background-color: #f2f2f2;
    `}
`;
const borderStyles = css`
  border-collapse: collapse;
  border: 1px solid black;
`;
const StyledTable = styled.table`
  ${borderStyles}
  margin: 0 0 40px;
`;
const StyledTd = styled.td`
  ${borderStyles}
  padding: 0 16px;
  height: 40px;
  font-size: 14px;
  background-color: ${(props) =>
    props.isEditMode ? (props.disabled ? '#f2f2f2' : 'white') : '#f2f2f2'};
  width: 350px;
`;
const StyledTh = styled.td`
  ${borderStyles}
  padding-left: 16px;
  height: 40px;
  font-size: 14px;
  line-height: 40px;
  background-color: #d3d3d3;
  width: 190px;
  color: black;
`;

const StyledTr = styled.tr`
  ${borderStyles}
  padding: 8px 15px;
  font-size: 14px;
`;
const BtnWrapper = styled.div`
  width: 100%;
  text-align: right;
`;
const ResetButtonContainer = styled(FlexWrap)``;
const ResetButton = styled.button`
  width: 60px;
  padding: 5px 9px;
  font-size: 14px;
  line-height: 22px;
  font-weight: 500;
  color: #354eff;
  border: 2px solid #354eff;
  border-radius: 4px;
`;
export default DetailContent;
