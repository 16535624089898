import React from 'react';
import styled from 'styled-components';
import { format } from 'date-fns';
import { TableRow, Typography } from '@material-ui/core';

import { formattedpointStatus } from '../../../utils/ktp';
import CustomTableCell from '../../common/CustomTableCell';
import DetailBtn from '../../common/DetailBtn';

const ListItem = ({ data, onOpen, idx }) => {
  const {
    isRead,
    pointsIndex,
    pointStatus,
    requestedDate,
    businessNumber,
    sellerName,
    storeName,
    amount,
  } = data;
  const onClickOpen = () => {
    onOpen(data);
  };
  return (
    <>
      <StyledTableRow
        style={
          (idx + 1) % 5 === 0
            ? { borderBottom: '2px solid rgb(220, 220, 220)' }
            : { borderBottom: '1px solid rgb(220, 220, 220)' }
        }
      >
        <CustomTableCell width='90' idx={0}>
          {isRead === false && <Typography color='error'>New</Typography>}
        </CustomTableCell>
        <CustomTableCell width='40'>{pointsIndex}</CustomTableCell>
        <CustomTableCell width='170'>
          {formattedpointStatus(pointStatus)}
        </CustomTableCell>
        <CustomTableCell width='200'>
          {format(new Date(requestedDate), 'yyyy.MM.dd HH:mm')}
        </CustomTableCell>
        <CustomTableCell width='200'>{businessNumber}</CustomTableCell>
        <CustomTableCell width='200'>{sellerName}</CustomTableCell>
        <CustomTableCell width='200'>{storeName}</CustomTableCell>
        <CustomTableCell width='150'>
          {Number(amount).toLocaleString()}P
        </CustomTableCell>
        <CustomTableCell width='80'>
          <DetailBtn onClick={onClickOpen}>상세보기</DetailBtn>
        </CustomTableCell>
      </StyledTableRow>
    </>
  );
};
const StyledTableRow = styled(TableRow)`
  background-color: #ffffff;
  :hover {
    background-color: #f7f9fc;
  }
`;
export default ListItem;
