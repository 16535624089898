import { InputAdornment, TextField } from '@material-ui/core';
import { FormEventHandler, useRef } from 'react';
import { Search as SearchIcon } from '@material-ui/icons';
import styled, { css } from 'styled-components';
import { Autocomplete } from '@material-ui/lab';
import match from 'autosuggest-highlight/match';
import parse from 'autosuggest-highlight/parse';

type Props = {
  value: string;
  onChange: (val: string) => void;
  isEditMode: boolean;
  data?: { keyword: string }[];
};

function SalesSearch({ value, onChange, isEditMode, data }: Props) {
  const autoCompleteRef = useRef(null);

  const onSearch: FormEventHandler<HTMLFormElement | HTMLButtonElement> = (
    e,
  ) => {
    e.preventDefault();
    const current = autoCompleteRef.current as any;
    const inputCurrent = current.querySelector('input');
    inputCurrent?.blur();
  };

  const onChangeKeyword = (e: any, v: any) => {
    onChange(v?.keyword);
  };

  const onInputChange = (e: any, v: any) => {
    onChange(v);
  };

  return (
    <SearchWrapper>
      <Form onSubmit={onSearch}>
        <Autocomplete
          ref={autoCompleteRef}
          options={data || []}
          onChange={onChangeKeyword}
          onInputChange={onInputChange}
          getOptionLabel={(option) => option.keyword}
          disabled={!isEditMode}
          defaultValue={{ keyword: value }}
          renderInput={(params) => {
            return (
              <StyledTextField
                {...params}
                variant='standard'
                style={{
                  backgroundColor: 'transparent',
                }}
                placeholder='가맹점을 검색해주세요'
                InputProps={{
                  style: {
                    padding: '0',
                    margin: '0',
                  },
                  className: 'MuiAutocomplete-inputRoot',
                  ref: params.InputProps.ref,
                  endAdornment: (
                    <button onClick={onSearch}>
                      <InputAdornment position='start'>
                        <SearchIcon color='disabled' />
                      </InputAdornment>
                    </button>
                  ),
                  fullWidth: true,
                }}
                size='small'
              />
            );
          }}
          renderOption={(option, { inputValue }) => {
            const matches = match(option.keyword, inputValue);
            const parts = parse(option.keyword, matches);
            return (
              <div>
                {parts.map((part: any, index: number) => (
                  <span
                    key={index}
                    style={{
                      color: part.highlight ? '#27AE60' : 'black',
                    }}
                  >
                    {part.text}
                  </span>
                ))}
              </div>
            );
          }}
        />
      </Form>
    </SearchWrapper>
  );
}

const SearchWrapper = styled.div`
  display: flex;
  width: 100%;
`;

const Form = styled.form`
  width: 100%;
`;

const StyledTextField = styled(TextField)<{ disabled: boolean }>`
  .MuiAutocomplete-inputRoot {
    &::before,
    &::after {
      content: '';
      border: 0;
      display: none;
    }
  }

  input {
    font-size: 14px;
    font-weight: 500;
  }

  ${({ disabled }) =>
    disabled &&
    css`
      input {
        color: #3a3b3e;
      }
    `}
`;

export default SalesSearch;
