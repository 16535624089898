import { Fragment, useState } from 'react';
import styled, { css } from 'styled-components';
import { useMutation } from 'react-query';
import { Button, Link, TextField } from '@material-ui/core';
import { withStyles } from '@material-ui/styles';

import {
  applyComplete,
  resetPassword,
  updateFranchiseeInfo,
} from '../../../api/ktp';
import {
  formatFranchisee,
  formatGlobalFranchisee,
  formattedCMS,
  formattedStore,
} from '../../../utils/ktp';
import GlobalPayContent from './GlobalPayContent';
import ActionBtn from '@/components/common/ActionBtn';
import FlexWrap from '@/components/common/FlexWrap';
import RefundSection from './RefundSection';
import DetailContentPosInfo from './DetailContentPosInfo';

const styledBy = (property, mapping) => (props) => mapping[props[property]];

const styles = {
  root: {
    marginRight: '8px',
    backgroundColor: styledBy('btnStatus', {
      default: '#E0E0E0',
      active: 'white',
    }),
    color: styledBy('btnStatus', {
      default: '#757575',
      active: '#039be5',
    }),
    border: styledBy('btnStatus', {
      default: '1px solid #E0E0E0',
      active: '1px solid #039be5',
    }),
  },
};
const StyledBtn = withStyles(styles)(({ classes, color, ...other }) => (
  <Button className={classes.root} {...other} />
));

function DetailContent({
  data,
  franchiseeIndex,
  franchiseeStatus,
  setActive,
  globalPayOnly,
}) {
  const {
    detailFranchiseeInfo,
    detailApplicantInfo,
    detailBalanceInfo: { balancePercentage },
    globalPayInfo,
    employeeFindResponseList,
    posInfo,
  } = data;
  const [isEditMode, setIsEditMode] = useState(false);
  const [newDetailFranchiseeInfo, setNewDetailFranchiseeInfo] =
    useState(detailFranchiseeInfo);
  const [newGlobalPayInfo, setNewGlobalPayInfo] = useState({
    ...globalPayInfo,
    globalPayMethods: globalPayInfo?.globalPayMethods || [],
  });

  const [newDetailApplicantInfo, setNewDetailApplicantInfo] = useState({
    ...detailApplicantInfo,
    withdrawalDate: detailApplicantInfo.withdrawalDate + '일',
  });
  const disabledCheck =
    detailFranchiseeInfo.franchiseeStatus === 'INIT' || !isEditMode;
  const disableApplyCheck = ['WAIT', 'REAPPLIED'].includes(
    detailFranchiseeInfo.franchiseeStatus,
  );
  const formattedFranchiseeInfo = globalPayOnly
    ? formatGlobalFranchisee(newDetailFranchiseeInfo)
    : formatFranchisee(newDetailFranchiseeInfo);
  const formattedApplicantInfo = formattedStore(newDetailApplicantInfo);
  const formattedCMSInfo = formattedCMS(newDetailApplicantInfo);
  const formattedLeftEmployeeInfo = employeeFindResponseList.filter(
    (item, idx) => idx % 2 === 0,
  );
  const formattedRightEmployeeInfo = employeeFindResponseList.filter(
    (item, idx) => idx % 2 !== 0,
  );
  const { excelUrl } = detailApplicantInfo;
  const [pointInputValue, setPointInputValue] = useState(
    String(balancePercentage),
  );

  const updateMutation = useMutation(
    (payload) => updateFranchiseeInfo(payload, franchiseeIndex),
    {
      onSuccess: () => {
        setIsEditMode(false);
        alert('회원 정보 수정이 완료되었습니다.');
      },
      onError: (err) => {
        console.log(err?.response);
        alert('회원 정보 수정에 실패하였습니다.');
      },
      retry: false,
    },
  );
  const applyCompleteMutation = useMutation(
    () => applyComplete(franchiseeIndex),
    {
      onSuccess: () => {
        alert('가맹점 신청상태가 변경되었습니다.');
      },
      onError: (err) => {
        console.log(err?.response);
        alert('가맹점 신청을 실패했습니다.');
      },
      retry: false,
    },
  );
  const resetPasswordMutation = useMutation(
    () => resetPassword(franchiseeIndex),
    {
      onSuccess: () => {
        alert('비밀번호가 초기화 되었습니다.\n(초기화 된 비밀번호: 1234)');
      },
      onError: (err) => {
        console.log(err?.response);
        alert('비밀번호 초기화에 실패했습니다.');
      },
      retry: false,
    },
  );
  const onChangeBalance = (e) => {
    const { value } = e.target;
    const num = Number(value);
    if (num > 30 || num < 0) {
      return;
    }
    setPointInputValue(
      Number.isInteger(num) ? value === '' || num : num.toFixed(1),
    );
  };
  const checkFormData = () => {
    const {
      email,
      productCategory,
      sellerName,
      storeAddressBasic,
      storeAddressDetail,
      storeName,
      storeNumber,
      storeTel,
      isOwner,
      businessType,
    } = newDetailFranchiseeInfo;
    const { mid, globalPayMethods } = newGlobalPayInfo;
    const haveAllnecessaryInfo = globalPayOnly
      ? Boolean(
          email &&
            sellerName &&
            storeAddressBasic &&
            storeAddressDetail &&
            storeName &&
            storeNumber &&
            storeTel &&
            businessType &&
            mid &&
            globalPayMethods?.length > 1,
        )
      : Boolean(
          email &&
            productCategory &&
            sellerName &&
            storeAddressBasic &&
            storeAddressDetail &&
            storeName &&
            storeNumber &&
            storeTel &&
            isOwner !== null &&
            businessType,
        );
    if (!haveAllnecessaryInfo) {
      alert('필수 정보를 모두 입력해주세요.');
      return false;
    }
    if (newDetailFranchiseeInfo.businessType === '사업자 분류 선택') {
      alert('사업자 분류를 선택해주세요.');
      return false;
    }
    if (!email.match(/[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*\.[a-zA-Z]{2,3}$/i)) {
      alert('이메일 형식이 옳지 않습니다.');
      return false;
    }
    if (!globalPayOnly) {
      const haveOneGlobalPayInfo =
        Boolean(mid && globalPayMethods.length === 0) ||
        Boolean(globalPayMethods.length !== 0 && !mid);
      if (haveOneGlobalPayInfo) {
        alert('해외 간편결제를 활성화하려면 mid와 결제사를 모두 등록해주세요.');
        return false;
      }
      if (newDetailFranchiseeInfo.productCategory === '판매상품 종목 선택') {
        alert('판매상품 종목을 선택해주세요.');
        return false;
      }
      if (newDetailFranchiseeInfo.isOwner === '대표자와의 관계 선택') {
        alert('대표자와의 관계를 선택해주세요.');
        return false;
      }
      if (detailFranchiseeInfo.franchiseeStatus !== 'INIT') {
        const { bankAccount, bankName, withdrawalDate } =
          newDetailApplicantInfo;
        if (!Boolean(bankAccount && bankName && withdrawalDate)) {
          alert('필수 정보를 모두 입력해주세요.');
          return false;
        }
        if (newDetailApplicantInfo.bankName === '은행 선택') {
          alert('은행명을 선택해주세요.');
          return false;
        }
        if (newDetailApplicantInfo.withdrawalDate === '출금일 선택') {
          alert('자동이체 출금일을 선택해주세요.');
          return false;
        }
      }
    }
    return true;
  };

  const onEdit = () => {
    setIsEditMode(true);
    if (isEditMode && checkFormData()) {
      const franchiseeBankInfo = {
        accountNumber: newDetailApplicantInfo.bankAccount,
        bankName: newDetailApplicantInfo.bankName,
        withdrawalDate: newDetailApplicantInfo.withdrawalDate,
        sellerName: newDetailFranchiseeInfo.sellerName,
      };
      const franchiseeUpdateInfo = {
        balancePercentage: pointInputValue,
        ...newDetailFranchiseeInfo,
      };
      const franchiseeApplicantUpdateInfo = {
        taxFreeStoreNumber: newDetailApplicantInfo.taxFreeStoreNumber,
      };
      const globalPayInfo = {
        mid: newGlobalPayInfo.mid || null,
        globalPayMethods:
          newGlobalPayInfo.globalPayMethods.length === 0
            ? null
            : newGlobalPayInfo.globalPayMethods,
      };
      const payload = {
        franchiseeBankInfo,
        franchiseeUpdateInfo,
        franchiseeApplicantUpdateInfo,
        globalPayInfo,
      };

      updateMutation.mutate(payload);
    }

    franchiseeStatus === 'WAIT' && setActive((prev) => !prev);
  };
  const onChangeFranchisee = (e) => {
    const { name, value } = e.target;
    setNewDetailFranchiseeInfo({
      ...newDetailFranchiseeInfo,
      [name]: value,
    });
  };
  const onChangeApplicant = (e) => {
    const { name, value } = e.target;
    setNewDetailApplicantInfo({
      ...newDetailApplicantInfo,
      [name]: value,
    });
  };

  const onClickReset = () => {
    const result = window.confirm('비밀번호를 초기화 하시겠습니까?');
    if (result) {
      resetPasswordMutation.mutate();
    }
  };
  const onClickApplyComplete = () => {
    if (window.confirm('가맹점 신청상태를 변경하시겠습니까?')) {
      applyCompleteMutation.mutate();
    }
  };

  return (
    <>
      {!globalPayOnly && (
        <RefundSection
          franchiseeIndex={franchiseeIndex}
          franchiseeStatus={franchiseeStatus}
        />
      )}
      <Title>회원가입 정보</Title>
      <StyledTable width='100%' align='center'>
        {formattedFranchiseeInfo.map((items, idx) => (
          <StyledTr key={idx}>
            {items.map((item, index) => (
              <Fragment key={item.label + index}>
                <StyledTh>{item.label}</StyledTh>
                <StyledTd
                  disabled={item.option?.disabled}
                  isEditMode={isEditMode}
                >
                  {item?.selectName ? (
                    <StyledSelect
                      name={item.selectName}
                      disabled={!isEditMode}
                      onChange={onChangeFranchisee}
                      value={newDetailFranchiseeInfo[item.selectName]}
                    >
                      {item.optionList?.map((item, idx) => {
                        return (
                          <option
                            key={idx}
                            value={typeof item === 'string' ? item : item.value}
                          >
                            {typeof item === 'string' ? item : item.label}
                          </option>
                        );
                      })}
                      {item?.selectName === 'productCategory' &&
                        !item.optionList?.includes(
                          newDetailFranchiseeInfo[item.selectName],
                        ) && (
                          <option
                            key={item.optionList.length}
                            value={newDetailFranchiseeInfo[item.selectName]}
                          >
                            {newDetailFranchiseeInfo[item.selectName]}
                          </option>
                        )}
                    </StyledSelect>
                  ) : (
                    <FlexWrap>
                      <StyledInput
                        disabled={!isEditMode}
                        value={newDetailFranchiseeInfo[item.option.name]}
                        onChange={onChangeFranchisee}
                        {...item.option}
                      />
                      {item.option.name === 'password' && (
                        <ResetButtonContainer>
                          <ResetButton onClick={onClickReset}>
                            초기화
                          </ResetButton>
                        </ResetButtonContainer>
                      )}
                    </FlexWrap>
                  )}
                </StyledTd>
              </Fragment>
            ))}
          </StyledTr>
        ))}
      </StyledTable>

      <GlobalPayContent
        isEditMode={isEditMode}
        active={Boolean(globalPayInfo?.mid)}
        newGlobalPayInfo={newGlobalPayInfo}
        setNewGlobalPayInfo={setNewGlobalPayInfo}
      />

      {!globalPayOnly && (
        <>
          <Title>가맹점 신청 정보</Title>
          <StyledTable width='100%' align='center'>
            {formattedApplicantInfo.map((items, idx) => (
              <StyledTr key={idx}>
                {items.map((item, index) => (
                  <Fragment key={item.label + index}>
                    <StyledTh>{item.label}</StyledTh>
                    <StyledTd
                      disabled={
                        detailFranchiseeInfo.franchiseeStatus === 'INIT' ||
                        item.option?.disabled
                      }
                      isEditMode={isEditMode}
                    >
                      {!Boolean(['가맹점 신청 상태'].includes(item.label)) ? (
                        <>
                          {item.label === '대리신청 작성서류' ? (
                            <BtnContainer>
                              <StyledBtn
                                variant='outlined'
                                btnStatus={excelUrl ? 'active' : 'default'}
                                disabled={!excelUrl}
                              >
                                <Link
                                  href={excelUrl}
                                  download='사후면세판매장 지정 대리신청'
                                  underline='none'
                                  color='inherit'
                                >
                                  다운로드
                                </Link>
                              </StyledBtn>
                            </BtnContainer>
                          ) : (
                            <StyledInput
                              disabled={disabledCheck}
                              value={newDetailApplicantInfo[item.option.name]}
                              onChange={onChangeApplicant}
                              {...item.option}
                            />
                          )}
                        </>
                      ) : (
                        <BtnContainer
                          name={item.selectName}
                          disabled={!disableApplyCheck}
                          style={{ padding: '5px' }}
                          value={newDetailApplicantInfo[item.selectName]}
                          onChange={onChangeApplicant}
                        >
                          <StyledBtn
                            disabled={!disableApplyCheck}
                            variant='outlined'
                            style={{
                              border: disableApplyCheck && '1px solid #354EFF',
                              color: disableApplyCheck && '#354EFF',
                              padding: '5px 23px',
                            }}
                            onClick={onClickApplyComplete}
                          >
                            대리신청 완료
                          </StyledBtn>
                        </BtnContainer>
                      )}
                    </StyledTd>
                  </Fragment>
                ))}
              </StyledTr>
            ))}
          </StyledTable>
          <Title>CMS 정보</Title>
          <StyledTable width='100%' align='center'>
            {formattedCMSInfo.map((items, idx) => (
              <StyledTr key={idx}>
                {items.map((item, index) => (
                  <Fragment key={item.label + index}>
                    <StyledTh>{item.label}</StyledTh>
                    <StyledTd
                      disabled={
                        detailFranchiseeInfo.franchiseeStatus === 'INIT' ||
                        item.option?.disabled
                      }
                      isEditMode={isEditMode}
                    >
                      {!Boolean(
                        ['자동이체 출금일', '은행명'].includes(item.label),
                      ) ? (
                        <StyledInput
                          disabled={disabledCheck}
                          onChange={onChangeApplicant}
                          {...item.option}
                        />
                      ) : (
                        <StyledSelect
                          name={item.selectName}
                          disabled={disabledCheck}
                          value={newDetailApplicantInfo[item.selectName]}
                          onChange={onChangeApplicant}
                        >
                          {item.optionList?.map((item, idx) => (
                            <option key={idx} value={item}>
                              {item}
                            </option>
                          ))}
                        </StyledSelect>
                      )}
                    </StyledTd>
                  </Fragment>
                ))}
              </StyledTr>
            ))}
          </StyledTable>
          <FlexHorizontalStart>
            <Title style={{ margin: '8px 0' }}>
              포인트 적립 비율<StarText> *</StarText>
            </Title>
            <FlexVertical>
              <FlexHorizontalCenter>
                <TextField
                  disabled={!isEditMode}
                  variant='outlined'
                  size='small'
                  placeholder='숫자만 입력하세요.'
                  type='number'
                  value={pointInputValue}
                  style={{ marginRight: 8 }}
                  onChange={onChangeBalance}
                />
                %
              </FlexHorizontalCenter>
              <PointLabel>• 부가세 기준으로 몇 %인지 입력하세요.</PointLabel>
            </FlexVertical>
            <ActionBtn
              title={isEditMode ? '수정완료' : '수정하기'}
              bgColor='#757575'
              onClick={onEdit}
              right='0'
            />
          </FlexHorizontalStart>
        </>
      )}
      {globalPayOnly && (
        <ActionBtn
          title={isEditMode ? '수정완료' : '수정하기'}
          bgColor='#757575'
          onClick={onEdit}
          right='0'
          bottom='230px'
        />
      )}
      <Title>직원계정</Title>
      {employeeFindResponseList.length === 0 ? (
        <p style={{ marginBottom: 24 }}>연결된 직원 계정이 없습니다.</p>
      ) : (
        <StyledTable width='100%'>
          <StyledTh>이름</StyledTh>
          <StyledTd style={{ backgroundColor: '#d3d3d3' }}>아이디</StyledTd>
          <StyledTh>이름</StyledTh>
          <StyledTd style={{ backgroundColor: '#d3d3d3' }}>아이디</StyledTd>
          {formattedLeftEmployeeInfo.map((item, idx) => (
            <StyledTr key={idx}>
              <StyledTd>{item.name}</StyledTd>
              <StyledTd>{item.userId}</StyledTd>
              <StyledTd>{formattedRightEmployeeInfo[idx]?.name}</StyledTd>
              <StyledTd>{formattedRightEmployeeInfo[idx]?.userId}</StyledTd>
            </StyledTr>
          ))}
        </StyledTable>
      )}
      {posInfo && (
        <DetailContentPosInfo
          franchiseeIndex={franchiseeIndex}
          posInfo={posInfo}
        />
      )}
    </>
  );
}
const Title = styled.h3`
  font-size: 18px;
  margin-bottom: 16px;
`;
const StyledSelect = styled.select`
  padding: 8px;
  border: none;
  outline: none;
  color: black;
  ${(props) =>
    props.disabled &&
    css`
      color: black;
      background: #f2f2f2;
    `}
`;
const StyledInput = styled.input`
  color: black;
  border: none;
  outline: none;
  width: 100%;
  padding: 8px 0;
  -moz-appearance: textfield;
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  ${(props) =>
    props.disabled &&
    css`
      background-color: #f2f2f2;
    `}
`;
const PointLabel = styled.p`
  font-size: 14px;
  margin-top: 8px;
  margin-bottom: 20px;
`;
const StarText = styled.span`
  color: orange;
  font-weight: 400;
  margin-right: 40px;
`;
const FlexHorizontalStart = styled.div`
  display: flex;
  align-items: flex-start;
  position: relative;
`;
const FlexHorizontalCenter = styled.div`
  display: flex;
  align-items: center;
`;
const FlexVertical = styled.div`
  display: flex;
  flex-direction: column;
`;
const borderStyles = css`
  border-collapse: collapse;
  border: 1px solid black;
`;
const StyledTable = styled.table`
  ${borderStyles}
  margin: 0 0 40px;
`;
const StyledTd = styled.td`
  ${borderStyles}
  padding: 0 16px;
  height: 40px;
  font-size: 14px;
  background-color: ${(props) =>
    props.isEditMode ? (props.disabled ? '#f2f2f2' : 'white') : '#f2f2f2'};
  width: 350px;
`;
const StyledTh = styled.td`
  ${borderStyles}
  padding-left: 16px;
  height: 40px;
  font-size: 14px;
  line-height: 40px;
  background-color: #d3d3d3;
  width: 190px;
  color: black;
`;

const StyledTr = styled.tr`
  ${borderStyles}
  padding: 8px 15px;
  font-size: 14px;
`;

const BtnContainer = styled.div`
  display: flex;
`;
const ResetButtonContainer = styled(FlexWrap)``;
const ResetButton = styled.button`
  width: 60px;
  padding: 5px 9px;
  font-size: 14px;
  line-height: 22px;
  font-weight: 500;
  color: #354eff;
  border: 2px solid #354eff;
  border-radius: 4px;
`;

export default DetailContent;
