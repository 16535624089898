import { Toggle } from '@successmode-ktp/kui';
import { ChangeEvent, useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import AlertTalkTemplate from './AlertTalkTemplate';
import { useQuery } from 'react-query';
import { getAlertTalkTemplates } from '@/api/sales';

type Props = {
  onSubmit: ({
    code,
    phoneNumber,
  }: {
    code: string;
    phoneNumber: string;
  }) => void;
};

function AlertTalkSection({ onSubmit }: Props) {
  const [isActive, setIsActive] = useState(false);
  const [template, setTemplate] = useState<any>({});
  const [tel, setTel] = useState('');

  const { data: templateList } = useQuery(['alimtalk'], getAlertTalkTemplates, {
    enabled: isActive,
  });

  const onChangeTel = (e: ChangeEvent<HTMLInputElement>) => {
    const input = e.target.value;
    const numericValue = input.replace(/[^0-9]/g, '');
    setTel(numericValue);
  };

  const onSubmitAlimtalk = () => {
    onSubmit({ code: template.code, phoneNumber: tel });
    setTemplate(templateList?.details[0] || {});
    setTel('');
    setIsActive(false);
  };

  const isDisabledButton = useMemo(() => {
    return !isActive || tel === '';
  }, [isActive, tel]);

  useEffect(() => {
    if (templateList?.details) {
      setTemplate(templateList.details[0]);
    }
  }, [templateList]);

  return (
    <Wrapper>
      <Toggle
        name='알림톡'
        isActive={isActive}
        onToggle={() => setIsActive((prev) => !prev)}
      />
      <AlertTalkTemplate
        isActive={isActive}
        options={templateList?.details || []}
        selectedOption={template}
        setSelectedOption={setTemplate}
      />
      <AlertTalkTel
        value={tel}
        onChange={onChangeTel}
        placeholder='연락처를 입력하세요.'
        disabled={!isActive}
      />
      <Button
        color='blue'
        style={{ width: '80px' }}
        disabled={isDisabledButton}
        onClick={onSubmitAlimtalk}
      >
        발송
      </Button>
    </Wrapper>
  );
}

const Button = styled.button<{ color?: string }>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 44px;
  border-radius: 6px;
  font-size: 16px;
  font-weight: 600;
  color: ${({ color }) => (color === 'blue' ? '#ffffff' : '#3A3B3E')};
  background-color: ${({ color }) =>
    color === 'blue' ? '#246CF6' : '#e5e6e8'};

  &:disabled {
    background-color: #cbccce;
  }
`;

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
  height: 56px;
  margin-bottom: 12px;

  * {
    flex-shrink: 0;
  }
`;

const AlertTalkTel = styled.input`
  width: 100%;
  height: 56px;
  padding: 16px;
  border: 1px solid #e5e6e8;
  border-radius: 10px;
  font-size: 14px;
  color: #3a3b3e;
  flex-shrink: 1;
`;

export default AlertTalkSection;
