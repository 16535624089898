import { CSSProperties, ReactNode } from 'react';
import { TableCell } from '@material-ui/core';

interface IProps {
  children: ReactNode;
  width?: string;
  idx?: number;
  color?: string;
  isCenter?: boolean;
  className?: string;
  fontSize?: string;
  fontWeight?: number;
  isScheduledDeparture?: boolean;
  onMouseBackgroundChange?: () => void;
  onClick?: () => void;
  style?: CSSProperties;
}
function CustomTableCell({
  children,
  width,
  idx,
  color,
  isCenter,
  fontSize,
  fontWeight,
  className,
  style,
  onClick,
  onMouseBackgroundChange,
}: IProps) {
  return (
    <TableCell
      style={{
        padding: idx === 0 ? '4px 4px 4px 18px' : 4,
        fontSize: fontSize || 13,
        height: 35,
        color: color || '#212121',
        position: 'relative',
        fontWeight: fontWeight || 400,
        ...style,
      }}
      width={width}
      align={isCenter ? 'center' : 'left'}
      className={className}
      onMouseOver={onMouseBackgroundChange}
      onMouseOut={onMouseBackgroundChange}
      onClick={onClick}
    >
      {children}
    </TableCell>
  );
}

export default CustomTableCell;
