import { QueryClientProvider, QueryClient } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import { ThemeProvider } from 'styled-components';
import { GlobalStyle, theme } from '@/styles';
import MainRouter from './router';
import { useEffect } from 'react';

const queryClient = new QueryClient();

const App = () => {
  useEffect(() => {
    // 7일 후 자동 로그아웃
    const autoLogin = localStorage.getItem('autoLogin');
    if (autoLogin === 'true') {
      const EXPIRATION_DAYS = 7;

      const isExpired = () => {
        const expiredTime = Number(localStorage.getItem('loginExpires'));
        const now = new Date();
        const currentTime = now.getTime();
        const daysPassed = Math.round(
          (expiredTime - currentTime) / (1000 * 60 * 60 * 24), // 단위: ms -> day
        );
        return daysPassed >= EXPIRATION_DAYS;
      };

      if (isExpired()) {
        localStorage.removeItem('autoLogin');
        localStorage.removeItem('loginToken');
        localStorage.removeItem('loginExpires');
      }
    }
  }, []);

  return (
    <QueryClientProvider client={queryClient}>
      <ThemeProvider theme={theme}>
        <GlobalStyle />
        <MainRouter />
        <ReactQueryDevtools initialIsOpen={false} />
      </ThemeProvider>
    </QueryClientProvider>
  );
};

export default App;
