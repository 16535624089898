import { TableBody } from '@material-ui/core';
import styled from 'styled-components';

import store from '@/store';
import { KioskDetailErrorInfo } from '@/types/api/kiosk';
import { getKioskErrorLogInfo } from '@/utils/kiosk';

import FlexWrap from '@/components/common/FlexWrap';
import Typography from '@/components/common/Typography';
import KioskDataTable from './KioskDataTable';
import ErrorLogInfoItem from './ErrorLogInfoItem';

type WithdrawalInfoProps = {
  data: KioskDetailErrorInfo[];
  onClick: (value1: string, value2: string) => void;
};

function ErrorLogInfo({ data, onClick }: WithdrawalInfoProps) {
  const { columns } = getKioskErrorLogInfo();
  const serverState = store.getState().serverState;
  return (
    <Container>
      <FlexWrap alignItems='center' justifyContent='space-between'>
        <Typography fontSize='18px' fontWeight='600' lineHeight='26px'>
          키오스크 에러 로그
        </Typography>
        <MoreBtn
          onClick={() =>
            onClick(
              '키오스크 에러 로그',
              `/kiosk/${serverState}/home/errorlog-detail`,
            )
          }
        >
          + 더보기
        </MoreBtn>
      </FlexWrap>
      <KioskDataTable
        columns={columns}
        contentComponent={
          data.length < 1 ? (
            <Typography>데이터가 없습니다.</Typography>
          ) : (
            <TableBody>
              {data.map((item, idx) => (
                <ErrorLogInfoItem key={idx} idx={idx} data={item} />
              ))}
            </TableBody>
          )
        }
      />
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
`;
const MoreBtn = styled.button`
  color: #757575;
`;

export default ErrorLogInfo;
