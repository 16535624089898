import { useMutation } from 'react-query';

import { deleteRefund } from '@/api/ktp';

export const useRefundDelete = () => {
  return useMutation({
    mutationKey: ['refundDelete'],
    mutationFn: deleteRefund,
    retry: false,
    onSuccess: () => {
      alert('환급현황이 삭제되었습니다.');
    },
    onError: () => {
      alert('환급현황 삭제가 실패하였습니다.');
    },
  });
};
