import FlexWrap from '@/components/common/FlexWrap';
import React from 'react';
import { SubTitle, TransferContainer } from './DetailModalContent';
import { TextField } from '@material-ui/core';
import Typography from '@/components/common/Typography';
import { formatDatetimeForInput } from '@/utils/common';
import styled, { css } from 'styled-components';

type Props = {
  refundInfo: any;
  isEdit: boolean;
  setData: React.Dispatch<React.SetStateAction<unknown>>;
};

const STATUS_LIST = [
  {
    name: '미완료',
    value: 'PAYMENT_WAIT',
  },
  {
    name: '송금중',
    value: 'PAYMENT_IN_PROGRESS',
  },
  {
    name: '완료',
    value: 'PAYMENT_COMPLETE',
  },
];

function RemittanceStatusRadio({ refundInfo, isEdit, setData }: Props) {
  const onChangePaymentStatus = (status: string) => {
    setData((prev: any) => {
      return {
        ...prev,
        refundInfo: {
          ...prev.refundInfo,
          paymentStatus: status,
        },
      };
    });
  };

  const onChangePaymentDate = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    setData((prev: any) => {
      return {
        ...prev,
        refundInfo: {
          ...prev.refundInfo,
          paymentCompleteDate: e.target.value,
        },
      };
    });
  };

  return (
    <Wrapper>
      <FlexWrap>
        <SubTitle>송금 상태</SubTitle>
        <TransferContainer dir='row' gap='20px'>
          {STATUS_LIST.map((status) => {
            return (
              <FlexWrap>
                <TransferInput
                  type='radio'
                  checked={refundInfo.paymentStatus === status.value}
                  onChange={() => onChangePaymentStatus(status.value)}
                  disabled={!isEdit}
                  isRadio
                />
                <label>{status.name}</label>
              </FlexWrap>
            );
          })}
          <Typography
            fontSize='16px'
            lineHeight='150%'
            fontWeight='500'
            color='#A7A7A7'
          >
            (송금일)
          </Typography>
          <TextField
            id='datetime-local'
            disabled={
              !isEdit || refundInfo.paymentStatus !== 'PAYMENT_COMPLETE'
            }
            onChange={onChangePaymentDate}
            type='datetime-local'
            value={
              refundInfo.paymentCompleteDate
                ? formatDatetimeForInput(
                    new Date(refundInfo.paymentCompleteDate),
                  )
                : ''
            }
            InputLabelProps={{
              shrink: true,
            }}
          />
        </TransferContainer>
      </FlexWrap>
    </Wrapper>
  );
}

export default RemittanceStatusRadio;

export const Wrapper = styled(FlexWrap)`
  position: relative;
  flex: 1;
  align-items: center;
`;

export const TransferInput = styled.input<{ isRadio: boolean }>`
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  outline: none;
  padding-left: 21px;
  flex: 1;
  border: none;
  background-color: #ffffff;
  min-height: ${(props) => (props.isRadio ? 'auto' : '56px')};
  ${(props) =>
    props.disabled &&
    css`
      background-color: #f2f2f2;
    `}
`;
