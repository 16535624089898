import {
  FormControlLabel,
  Radio,
  RadioGroup,
  TextField,
} from '@material-ui/core';
import { format } from 'date-fns';
import React from 'react';
import styled from 'styled-components';
import FormItemLayout from './FormItemLayout';

function MainFormItem({
  state,
  onChangeInput,
  onChangeRadio,
  onChangeImage,
  isEditMode,
}) {
  return (
    <>
      <FormItemLayout label='공지기간' required>
        <RadioGroup
          value={state.isImmediate ? 'isImmediate' : 'isScheduled'}
          onChange={onChangeRadio}
        >
          <FormControlLabel
            value='isImmediate'
            control={
              <Radio
                checked={state.isImmediate}
                name='isImmediate'
                color='primary'
                size='small'
              />
            }
            label='즉시'
          />
          <FlexWrap>
            <FormControlLabel
              value='isScheduled'
              control={
                <Radio name='isScheduled' color='primary' size='small' />
              }
              label='예약 공지'
            />
            <TextField
              variant='outlined'
              disabled={state.isImmediate}
              name='scheduledDate'
              onChange={onChangeInput}
              size='small'
              id='datetime-local'
              type='datetime-local'
              value={
                format(
                  state.scheduledDate
                    ? new Date(state?.scheduledDate)
                    : new Date(),
                  'yyyy-MM-dd',
                ) +
                'T' +
                format(
                  state.scheduledDate
                    ? new Date(state?.scheduledDate)
                    : new Date(),
                  'HH:mm',
                )
              }
            />
          </FlexWrap>
        </RadioGroup>
      </FormItemLayout>
      <FormItemLayout label='공지사항 제목' required>
        <StyledInput
          name='title'
          defaultValue={state.title}
          onChange={onChangeInput}
          placeholder='공지사항 제목을 입력해주세요.'
        />
      </FormItemLayout>
      <FormItemLayout label='메인 이미지'>
        <StyledInput
          type='file'
          name='mainImg'
          accept='image/*'
          onChange={onChangeImage}
          disabled={isEditMode}
        />
      </FormItemLayout>
    </>
  );
}
const FlexWrap = styled.div`
  display: flex;
  align-items: center;
`;
const StyledInput = styled.input`
  padding: 8px 12px;
  border: 1px solid lightgray;
  border-radius: 4px;
  font-size: 14px;
  outline: none;
  flex: 1;
`;
export default MainFormItem;
