import React, { useState } from 'react';
import styled, { css } from 'styled-components';
import ICON_ARROW_DOWN from '@/assets/common/arrow_drop_down.png';

type Props = {
  title: string;
  children: React.ReactNode;
};

const CertificateSelector = ({ title, children }: Props) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <Wrapper>
      <ButtonWrapper type='button' onClick={() => setIsOpen(!isOpen)}>
        <span>{title}</span>
        <ImgArrow src={ICON_ARROW_DOWN} alt='' width={24} isOpen={isOpen} />
      </ButtonWrapper>
      {isOpen && children}
    </Wrapper>
  );
};

export default CertificateSelector;

const Wrapper = styled.div`
  width: 140px;
  min-width: 140px;
  padding: 6px 16px;
  border-radius: 6px;
  border: 1px solid #cbccce;
  background: #e5e6e8;
  z-index: 10;
`;

const ButtonWrapper = styled.button`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;

const ImgArrow = styled.img<{ isOpen: boolean }>`
  width: 24px;
  transform: ${({ isOpen }) => (isOpen ? 'rotateZ(-180deg)' : 'rotateZ(0deg)')};
  transition: all 0.15s;
`;
