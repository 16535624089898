import { Label } from './Field';
import { useFieldArray } from 'react-hook-form';
import styled, { css } from 'styled-components';
import { Icon } from '@successmode-ktp/kui';
import { MouseEvent, useEffect, useRef } from 'react';

type TelInputProps = {
  register: any;
  control: any;
  setValue: any;
  label: string;
  required?: boolean;
  error?: boolean;
};

const MAX_LENGTH = 5;

function Tel({
  register,
  control,
  setValue,
  label,
  required = false,
  error = false,
}: TelInputProps) {
  const userRef = useRef<HTMLInputElement | null>(null);

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'contactNumber',
  });

  const onAddContact = (e: MouseEvent) => {
    e.preventDefault();
    if (fields.length < MAX_LENGTH) {
      append({ user: '', tel: '' });
    }
  };

  const onRemoveContact = (e: MouseEvent, index: number) => {
    e.preventDefault();
    remove(index);
  };

  useEffect(() => {
    if (fields.length === 0) {
      append({ user: '', tel: '' });

      if (userRef.current) {
        userRef.current.blur();
      }
    }
  }, [fields, userRef]);

  return (
    <FieldContainer error={error}>
      <Header>
        <Label required={required}>{label}</Label>
        <AddButton
          onClick={onAddContact}
          disabled={fields.length >= MAX_LENGTH}
        >
          추가
        </AddButton>
      </Header>
      <Body>
        {fields.map((item, index) => (
          <Contact key={item.id}>
            <Name
              {...register(`contactNumber.${index}.user`, {
                onChange: (e: any) =>
                  setValue(`contactNumber.${index}.user`, e.target.value),
              })}
              defaultValue={(fields[index] as any).user}
              type='text'
              placeholder='직급명'
              ref={index === fields.length - 1 ? userRef : null}
            />
            <Number
              {...register(`contactNumber.${index}.tel`, {
                onChange: (e: any) =>
                  setValue(`contactNumber.${index}.tel`, e.target.value),
              })}
              defaultValue={(fields[index] as any).tel}
              type='text'
              placeholder='연락처를 입력해주세요.'
              ref={index === fields.length - 1 ? userRef : null}
            />
            {fields.length > 1 && (
              <RemoveButton onClick={(e) => onRemoveContact(e, index)}>
                <Icon.XFilled size={20} fillColor='#e5e6e8' />
              </RemoveButton>
            )}
          </Contact>
        ))}
      </Body>
    </FieldContainer>
  );
}

const FieldContainer = styled.div<{ error: boolean }>`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  gap: 12px;
  padding: 16px;
  border-radius: 10px;
  border-width: 1px;
  border-style: solid;
  border-color: ${({ error = false }) => (error ? 'red' : '#e5e6e8')};
  background: #ffffff;
`;

const Header = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const Body = styled.ul`
  margin: 0;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

const Contact = styled.li`
  display: flex;
  align-items: center;
  gap: 16px;
`;

const Input = styled.input`
  border: 0;
  border-bottom: 1px solid #cbccce;
  padding: 4px 0;
  outline: 0;
  font-size: 14px;
  font-weight: 600;

  &::placeholder {
    color: #cbccce;
  }
`;

const Name = styled(Input)`
  width: 100px;
`;

const Number = styled(Input)`
  width: 100%;
`;

const RemoveButton = styled.button`
  width: 20px;
  height: 20px;
`;

const AddButton = styled.button<{ disabled: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 4px 12px;
  border-radius: 8px;
  font-size: 14px;
  font-weight: 600;
  line-height: 21px;
  color: #3a3b3e;
  border: 1px solid #e5e6e8;
  background-color: #f5f6f7;

  ${({ disabled }) =>
    disabled &&
    css`
      color: #cbccce;
    `}
`;

export default Tel;
