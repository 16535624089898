import { addHistory, deleteHistory, modifyDetailHistory } from '@/api/sales';
import { useMutation } from 'react-query';

export const useHistoryAdd = () =>
  useMutation({
    mutationFn: addHistory,
    onSuccess: () => {
      alert('가맹점 정보를 추가하였습니다.');
    },
    onError: () => {
      alert('가맹점 정보를 추가하는데 실패하였습니다.');
    },
  });

export const useHistoryModify = () =>
  useMutation({
    mutationKey: ['detailHistory'],
    mutationFn: modifyDetailHistory,
    onSuccess: () => {
      alert('가맹점 정보를 수정하였습니다.');
    },
    onError: () => {
      alert('가맹점 정보를 수정하는데 실패하였습니다.');
    },
  });

export const useHistoryDelete = () =>
  useMutation({
    mutationKey: ['deleteHistory'],
    mutationFn: deleteHistory,
    onSuccess: () => alert('가맹 히스토리를 삭제하였습니다.'),
    onError: () => alert('가맹 히스토리를 삭제하는데 실패하였습니다.'),
  });
