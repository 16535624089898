import styled from 'styled-components';
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
} from 'recharts';
import { CHART_COLORS } from '@/constants/report';
import { countToPercent, nationCodeToName } from '@/utils/report';
import { ReportAgeGender } from '@/types/report';

type Props = {
  data?: ReportAgeGender;
};

function AgeGender({ data = {} }: Props) {
  const dataList = Object.entries(data);
  const nations = dataList.map(([nation, value]) => nationCodeToName(nation));
  let totalOfNation = nations.reduce((acc, nation) => {
    (acc as any)[nation.code] = 0;
    return acc;
  }, {});

  const chartData = dataList.map(([nationKey, nation]) => {
    const result = {
      name: nationCodeToName(nationKey).name || nationKey,
      FEMALE_10: 0,
      MALE_10: 0,
      FEMALE_20: 0,
      MALE_20: 0,
      FEMALE_30: 0,
      MALE_30: 0,
      FEMALE_40: 0,
      MALE_40: 0,
      FEMALE_50: 0,
      MALE_50: 0,
    };

    Object.entries(nation).forEach(([key, value]) => {
      if (!key.includes('ETC')) {
        (totalOfNation as any)[nationKey] += value;
        (result as any)[key] = value;
      }
    });

    const resultWithPercent = Object.entries(result).reduce(
      (acc, [key, value]) => {
        if (key === 'name') {
          (acc as any)[key] = value;
        } else {
          (acc as any)[key] = countToPercent(
            value as any,
            (totalOfNation as any)[nationKey],
          );
        }
        return acc;
      },
      {},
    );

    return resultWithPercent;
  });

  const ageGenderList = [
    { value: 'FEMALE_10', label: '10대 여자' },
    { value: 'MALE_10', label: '10대 남자' },
    { value: 'FEMALE_20', label: '20대 여자' },
    { value: 'MALE_20', label: '20대 남자' },
    { value: 'FEMALE_30', label: '30대 여자' },
    { value: 'MALE_30', label: '30대 남자' },
    { value: 'FEMALE_40', label: '40대 여자' },
    { value: 'MALE_40', label: '40대 남자' },
    { value: 'FEMALE_50', label: '50대 여자' },
    { value: 'MALE_50', label: '50대 남자' },
  ];

  const tableRow = ageGenderList.map(({ value, label }) => {
    let total = 0;
    const valueOfNation = nations.reduce((acc, { code, name }) => {
      const target = dataList.find(([key, value]) => key === code);
      if (target) {
        const [key, dataValue] = target;

        acc[key] = dataValue[value as any];
        total += acc[key];
      }
      return acc;
    }, {} as any);
    return {
      price: label,
      total,
      ...valueOfNation,
    };
  });

  return (
    <Section>
      <TableWrapper>
        <TableTitle>
          국적 · 연령 · 성별<span>(상위 5개국)</span>
        </TableTitle>
        <ChartWrapper>
          <BarChart
            width={690}
            height={200}
            data={chartData}
            margin={{
              top: 20,
              right: 15,
              left: 0,
              bottom: 5,
            }}
          >
            <CartesianGrid stroke='#f1f1f1' vertical={false} />
            <XAxis dataKey='name' fontSize={12} />
            <YAxis
              domain={[0, 100]}
              ticks={[0, 20, 40, 60, 80, 100]}
              unit='%'
              fontSize={12}
            />
            <Tooltip />
            <Legend
              wrapperStyle={{ fontSize: 12 }}
              layout='vertical'
              verticalAlign='top'
              align='right'
              formatter={(value) => {
                return ageGenderList.find((item) => item.value === value)
                  ?.label;
              }}
            />
            {ageGenderList.map(({ value }, index) => (
              <Bar
                key={value}
                dataKey={value}
                stackId='a'
                fill={CHART_COLORS[index + 1]}
                isAnimationActive={false}
                barSize={60}
              />
            ))}
          </BarChart>
        </ChartWrapper>
        <TableList>
          <table>
            <thead>
              <tr>
                <th>연령 성별</th>
                <th>합계</th>
                {nations.map((nation) => (
                  <th>{nation.name}</th>
                ))}
              </tr>
            </thead>
            <tbody>
              {tableRow.map((data) => (
                <tr>
                  <th>{data.price}</th>
                  <td style={{ width: '84px' }}>{data.total}</td>
                  {nations.map((nation) => (
                    <td style={{ width: '84px' }}>
                      {(data as any)[nation.code]}
                    </td>
                  ))}
                </tr>
              ))}
            </tbody>
          </table>
        </TableList>
      </TableWrapper>
    </Section>
  );
}

const Section = styled.section`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 24px;
`;

const TableWrapper = styled.div`
  width: 100%;
`;

const TableTitle = styled.h2`
  display: inline-flex;
  padding: 0 40px;
  align-items: center;
  justify-content: center;
  height: 20px;
  font-size: 14px;
  color: #404040;
  background-color: #bfbfbf;

  span {
    font-size: 12px;
    font-weight: 500;
    margin-left: 4px;
  }
`;

const ChartWrapper = styled.div`
  width: 100%;
  height: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #ffffff;
  border: 1px solid lightgray;
`;

const TableList = styled.div`
  width: 100%;
  display: flex;
  text-align: center;

  table {
    border-collapse: collapse;
    width: 100%;
  }

  tr {
    > :is(th, td):first-child {
      border-left: 0;
    }
    > :is(th, td):last-child {
      border-right: 0;
    }
  }

  th,
  td {
    font-size: 14px;
    height: 22px;
    border: 1px solid lightgray;
  }
`;

export default AgeGender;
