import styled from 'styled-components';
import { TableRow } from '@material-ui/core';
import { format } from 'date-fns';
import CustomTableCell from '../common/CustomTableCell';
import { QnaResponse } from '@/types/qna.type';

type Props = {
  id: number;
  data: QnaResponse;
  onClick: () => void;
  notAnswered?: boolean;
  selectedHidden?: boolean;
  setSelectedHiddenQna?: (id: number) => void;
};

const QnaItem = ({
  id,
  data,
  onClick,
  notAnswered = false,
  selectedHidden = false,
  setSelectedHiddenQna = () => {},
}: Props) => {
  const { questionId, name, createdDate, nation, content, isNew } = data;

  return (
    <StyledTableRow
      style={{
        borderBottom:
          (id + 1) % 5 === 0
            ? '2px solid rgb(220, 220, 220)'
            : '1px solid rgb(220, 220, 220)',
      }}
      onClick={onClick}
    >
      <CustomTableCell width='30'>{isNew && <Dot />}</CustomTableCell>
      {notAnswered && (
        <CustomTableCell width='40'>
          <CheckboxWrapper>
            <Checkbox
              type='checkbox'
              checked={selectedHidden}
              onClick={(e) => e.stopPropagation()}
              onChange={() => {
                setSelectedHiddenQna(id);
              }}
            />
          </CheckboxWrapper>
        </CustomTableCell>
      )}
      <CustomTableCell width='40'>{questionId}</CustomTableCell>
      <CustomTableCell width='120'>
        {format(new Date(createdDate), 'yyyy.MM.dd HH:mm')}
      </CustomTableCell>
      <CustomTableCell width='80'>{nation}</CustomTableCell>
      <CustomTableCell width='80'>{name}</CustomTableCell>
      <CustomTableCell width='400'>
        <Ellipsis>{content}</Ellipsis>
      </CustomTableCell>
    </StyledTableRow>
  );
};

export default QnaItem;

const StyledTableRow = styled(TableRow)`
  background-color: #ffffff;
  :hover {
    background-color: #f7f9fc;
  }
`;

const Dot = styled.div`
  width: 8px;
  height: 8px;
  margin: auto;
  background-color: #246cf6;
  border-radius: 100%;
`;

const Ellipsis = styled.span`
  display: block;
  width: 450px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`;

const CheckboxWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Checkbox = styled.input`
  width: 20px;
  height: 20px;
  margin: 0;
  cursor: pointer;
`;
