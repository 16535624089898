import { useState } from 'react';
import styled from 'styled-components';
import { Button, Modal } from '@successmode-ktp/kui';

type Props = {
  isOpen: boolean;
  onCancel: () => void;
  onDelete: () => void;
};

function DeleteSalesModal({ isOpen, onCancel, onDelete }: Props) {
  const [cancelCode, setCancelCode] = useState('');

  const isDisabledDeleteButton = cancelCode !== 'successmode';

  return (
    <Modal isOpen={isOpen} hasBackdrop>
      <Modal.Header>
        <Title>가맹 히스토리 삭제</Title>
      </Modal.Header>
      <Body>
        <p>
          삭제를 원하실 경우 취소 코드를 입력해주세요.
          <br />
          삭제된 히스토리는 복구할 수 없습니다.
        </p>
        <CancelCodeInput>
          <Label htmlFor='cancel-code'>취소 코드</Label>
          <Input
            id='cancel-code'
            placeholder='successmode'
            value={cancelCode}
            onChange={(e) => setCancelCode(e.target.value)}
          />
        </CancelCodeInput>
      </Body>
      <Modal.Footer>
        <Modal.Button onClick={onCancel} variant='cancel'>
          닫기
        </Modal.Button>
        <Button onClick={onDelete} disabled={isDisabledDeleteButton}>
          삭제
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

const Title = styled.h3`
  font-size: 21px;
  font-size: 700;
  color: #030303;
`;

const Body = styled.p`
  display: flex;
  flex-direction: column;
  gap: 24px;
  margin: 12px 0 24px 0;
  width: 436px;
  font-size: 14px;
  line-height: 21px;
  color: #80848a;
`;

const CancelCodeInput = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  width: 100%;
`;

const Label = styled.label`
  font-size: 14px;
  font-weight: 500;
  color: #5f6165;
`;

const Input = styled.input`
  padding: 12px;
  border-radius: 8px;
  font-size: 14px;
  background-color: #f5f6f7;
  border: 0;
  outline: 0;

  &::placeholder {
    color: #cbccce;
  }
`;

export default DeleteSalesModal;
