import ActionBtn from '@/components/common/ActionBtn';
import Text from '@/components/common/Text';
import styled from 'styled-components';
import KioskRadioInput from '../kioskInput/KioskRadioInput';
import { ChangeEvent, useState } from 'react';

export type StyledBtnProps = {
  flexDirection?: string;
  justifyContent?: string;
  gap?: string;
};

export type DividerProps = {
  color?: string;
  thickness?: string;
  width?: string;
  margin?: string;
};

export type KioskModalContentProps = {
  title?: string;
  color?: string;
  titleSize?: number;
  titleWeight?: number;
  isActive: boolean;
  onClose?: () => void;
  onSave?: () => void;
};

function KioskModalContent({
  title = '',
  color,
  titleSize = 15,
  titleWeight = 600,
  isActive,
  onClose,
  onSave,
}: KioskModalContentProps) {
  return (
    <>
      <Text text={title} size={titleSize} weight={titleWeight} />
      <StyledDivider width='100%' thickness='1px' color='#E5E6E8' />
      <Wrapper flexDirection='row' gap='16px'>
        <KioskRadioInput
          id='kiosk-active-true'
          name='kiosk-active'
          label='사용중'
          checked={isActive === true ? true : false}
        />
        <KioskRadioInput
          id='kiosk-active-false'
          name='kiosk-active'
          label='사용 불가'
          checked={isActive === false ? true : false}
        />
      </Wrapper>
      <StyledDivider width='100%' thickness='1px' color='#E5E6E8' />
      <Wrapper flexDirection='row' gap='16px'>
        <ActionBtn
          title='취소'
          bgColor='#E5E6E8'
          color='#3A3B3E'
          height='52px'
          style={{ flex: 1, borderRadius: '8px' }}
          onClick={(event) => {
            event.preventDefault();
            onClose && onClose();
          }}
        />
        <ActionBtn
          title='확인'
          bgColor='#246CF6'
          height='52px'
          style={{ flex: 1, borderRadius: '8px' }}
          onClick={(event) => {
            event.preventDefault();
            onSave && onSave();
          }}
        />
      </Wrapper>
    </>
  );
}

export default KioskModalContent;

const Wrapper = styled.div<StyledBtnProps>`
  display: flex;
  width: 100%;
  flex-direction: ${(props) => props.flexDirection || 'column'};
  justify-content: ${(props) => props.justifyContent || 'flex-start'};
  gap: ${(props) => props.gap || '50px'};
`;

const StyledDivider = styled.hr<DividerProps>`
  border: none;
  border-top: ${(props) => props.thickness} solid ${(props) => props.color};
  width: ${(props) => props.width};
  margin: ${(props) => props.margin};
`;
