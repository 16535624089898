import styled from 'styled-components';
import FlexWrap from '@/components/common/FlexWrap';
import { Button, Icon } from '@successmode-ktp/kui';
import { useState } from 'react';
import UploadModal from '@/components/adSetting/Upload.modal';
import { getAdList } from '@/api/ad';
import { useQuery } from 'react-query';
import ActivateTable from '@/components/adSetting/ActivateTable';
import InActivateTable from '@/components/adSetting/InActivateTable';
import LoadingView from '@/components/common/LoadingView';

type Props = {
  hospitalId: number;
  onClose: () => void;
};

const AdSetting = ({ hospitalId, onClose }: Props) => {
  const [isOpen, setIsOpen] = useState(false);
  const { data, isLoading } = useQuery(
    'getAdList',
    () => getAdList(hospitalId),
    {
      retry: 1,
    },
  );

  return (
    <BackDrop>
      <Container>
        <TitleWrapper>
          <Title>배너 광고 설정</Title>
          <button type='button' onClick={onClose}>
            <Icon.X size={20} />
          </button>
        </TitleWrapper>
        {isLoading ? (
          <LoadingView />
        ) : (
          <>
            <ActivateTable data={data?.activatedAds || []} />
            <InActivateTable data={data?.deActivatedAds || []} />
          </>
        )}
        <BtnContainer>
          <Button style={{ width: '160px' }} onClick={() => setIsOpen(true)}>
            <Icon.Upload
              size={20}
              fillColor='#fff'
              style={{ marginRight: '4px' }}
            />
            배너 광고 업로드
          </Button>
        </BtnContainer>
        {isOpen && (
          <UploadModal
            hospitalId={hospitalId}
            isOpen={isOpen}
            setIsOpen={setIsOpen}
          />
        )}
      </Container>
    </BackDrop>
  );
};
export default AdSetting;

const BackDrop = styled.div`
  position: fixed;
  inset: 0 0 0 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 100;
  background-color: rgba(33, 33, 33, 0.5);
`;

const Container = styled.div`
  position: fixed;
  display: flex;
  flex-direction: column;
  flex: 1;
  min-width: 1080px;
  padding-bottom: 20px;
  background-color: #f7f9fc;
`;

const TitleWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 40px 40px 24px;
`;

const Title = styled.h2`
  font-size: 18px;
  font-weight: 700;
  line-height: 26px;
  color: #3a3b3e;
`;

const BtnContainer = styled(FlexWrap)`
  gap: 20px;
  justify-content: flex-end;
  margin-right: 40px;
`;
