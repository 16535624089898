import { ReportDayTime } from '@/types/report';
import Time from '../section/Time';
import PageWrapper from './PageWrapper';

type Props = {
  dayTimeData?: ReportDayTime;
};

function Page3({ dayTimeData = {} }: Props) {
  return (
    <PageWrapper>
      <Time data={dayTimeData} />
    </PageWrapper>
  );
}

export default Page3;
