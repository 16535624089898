import { signUpBarobill } from '@/api/medical';
import { Icon, Modal } from '@successmode-ktp/kui';
import { useMutation } from 'react-query';
import styled from 'styled-components';

type Props = {
  isOpen: boolean;
  onClose: () => void;
  hospitalId: number;
};

function BarobillSignUpModal({ isOpen, onClose, hospitalId }: Props) {
  const { mutate: mutateSignUpBarobill } = useMutation(signUpBarobill);

  const onSignUp = () => {
    mutateSignUpBarobill(hospitalId, {
      onSuccess: () => {
        onClose();
      },
      onError: (error: any) => {
        if (
          error.code === 'H0001' ||
          error.code === 'B0002' ||
          error.code === 'B0001'
        ) {
          alert(error.message);
        }

        onClose();
      },
    });
  };

  return (
    <Modal isOpen={isOpen} hasBackdrop>
      <Modal.Header>
        <Title>
          <Icon.QuestionFilled fillColor='#cbccce' size={28} />
          바로빌 회원가입
        </Title>
      </Modal.Header>
      <Content>바로빌 서비스 회원가입하시겠습니까?</Content>
      <Modal.Footer>
        <Modal.Button variant='cancel' onClick={onClose}>
          닫기
        </Modal.Button>
        <Modal.Button variant='ok' onClick={onSignUp}>
          회원가입
        </Modal.Button>
      </Modal.Footer>
    </Modal>
  );
}

const Title = styled.h4`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 8px;
  font-size: 21px;
  font-weight: 700;
  color: #030303;
  margin: 0 0 16px 0;
`;

const Content = styled.p`
  font-size: 16px;
  font-weight: 500;
  color: #5f6165;
  width: 436px;
`;

export default BarobillSignUpModal;
