import { ChangeEvent, MouseEvent, useEffect, useState } from 'react';
import { FieldValues, UseFormRegister } from 'react-hook-form';
import Field from './Field';
import styled from 'styled-components';
import RemoveIcon from '@/assets/common/Subtract.png';
import useRefundReducer from '@/hooks/medical/useRefundReducer';
import PreviewModal from '@/components/ktp/refund-status/PreviewModal';

type Props<T extends FieldValues> = {
  register: ReturnType<UseFormRegister<T>>;
  label: string;
  watch: (name: keyof T) => any;
  placeholder?: string;
  imagePath?: string;
  imageName?: string;
  onChangeStatus?: (status: 'KEEP' | 'UPDATE' | 'DELETE') => void;
};

function Image<T extends FieldValues>({
  register,
  label,
  watch,
  placeholder = '',
  imagePath,
  imageName,
  onChangeStatus = () => {},
}: Props<T>) {
  const [image, setImage] = useState(imagePath || '');
  const [imageFile, setImageFile] = useState<File>();

  const [state, dispatch] = useRefundReducer();
  const { selected } = state;

  const parseImage = (file: File) => {
    return new Promise<string>((resolve) => {
      const reader = new FileReader();
      reader.onload = () => resolve(reader.result as string);
      reader.readAsDataURL(file);
    });
  };

  const onUploadImage = async (event: ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files;
    if (!files) return;
    const file = files[0];
    const { type } = file;
    if ('image'.includes(type)) {
      alert('이미지 파일은 이미지 형식만 업로드 가능합니다.');
      return;
    }
    setImageFile(file);
    const uploadedImage = await parseImage(file);
    setImage(uploadedImage);
    register.onChange({
      target: {
        value: file,
        name: register.name,
      },
    });
    event.target.value = '';
    onChangeStatus('UPDATE');
  };

  const onRemoveImage = (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    setImage('');
    setImageFile(undefined);
    register.onChange({
      target: {
        value: undefined,
        name: register.name,
      },
    });
    onChangeStatus('DELETE');
  };

  const onOpenPreview = (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    const payload = {
      imgUrl: image,
    };
    dispatch({
      type: 'SET_SELECTED',
      payload,
    });
  };

  const onClosePreview = () => {
    dispatch({ type: 'SET_SELECTED', selected: null });
  };

  const resetImageData = () => {
    setImage('');
    setImageFile(undefined);
  };

  useEffect(() => {
    if (imagePath) {
      setImage(imagePath);
    }
  }, [imagePath]);

  useEffect(() => {
    window.addEventListener('popstate', resetImageData);
    return () => {
      window.removeEventListener('popstate', resetImageData);
    };
  }, []);

  return (
    <>
      <Field label={label}>
        <ImageWrapper>
          <InputWrapper>
            <Input
              value={
                watch(register.name)?.name ||
                imageFile?.name ||
                (image && imageName) ||
                ''
              }
              placeholder={placeholder}
              disabled
            />
            {(watch(register.name) || image) && (
              <button onClick={onRemoveImage}>
                <img src={RemoveIcon} alt='이미지 지우기' width={20} />
              </button>
            )}
          </InputWrapper>
          {watch(register.name) || image ? (
            <PreviewButton onClick={onOpenPreview}>미리 보기</PreviewButton>
          ) : (
            <FileButton htmlFor='image-input'>
              <span>파일 선택</span>
              <ImageInput
                id='image-input'
                type='file'
                accept='image/*'
                onChange={onUploadImage}
              />
            </FileButton>
          )}
        </ImageWrapper>
      </Field>
      {selected?.imgUrl && (
        <PreviewModal
          onClose={onClosePreview}
          selected={selected}
          width='600px'
        />
      )}
    </>
  );
}

const ImageWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  gap: 16px;
`;

const InputWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const Input = styled.input`
  width: 100%;
  padding: 0;
  border: 0;
  font-size: 14px;
  font-weight: 600;
  line-height: 21px;
  color: #030303;

  ::placeholder {
    color: #cbccce;
  }

  :disabled {
    background-color: transparent;
  }
`;

const Button = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  white-space: nowrap;
  padding: 4px 12px;
  border-radius: 8px;
  font-size: 14px;
  font-weight: 600;
  line-height: 21px;
  color: #3a3b3e;
  border: 1px solid #e5e6e8;
  background-color: #f5f6f7;
  cursor: pointer;
`;

const FileButton = Button.withComponent('label');

const PreviewButton = styled(Button)``;

const ImageInput = styled.input`
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0;
`;

export default Image;
