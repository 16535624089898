import Modal from '@/components/common/Modal';
import React, { useState, useEffect, useCallback } from 'react';
import { Map, MapMarker } from 'react-kakao-maps-sdk';
import styled from 'styled-components';

type Props = {
  onClose: () => void;
  address: string;
};

const MapModal = ({ onClose, address = '서울 서초구 사임당로 173' }: Props) => {
  const [coordinates, setCoordinates] = useState<{
    lat: number;
    lng: number;
  }>({ lat: 0, lng: 0 });

  const getCoordinates = useCallback(() => {
    const geocoder = new window.kakao.maps.services.Geocoder();
    geocoder.addressSearch(address, (result: any[], status: string) => {
      if (
        status === window.kakao.maps.services.Status.OK &&
        result.length > 0
      ) {
        const { y: lat, x: lng } = result[0];
        setCoordinates({ lat: parseFloat(lat), lng: parseFloat(lng) });
      } else {
        alert('존재하지 않는 주소입니다.');
        onClose();
      }
    });
  }, [address]);

  useEffect(() => {
    getCoordinates();
  }, [address]);

  return coordinates.lat === 0 && coordinates.lng === 0 ? null : (
    <Modal onClose={onClose}>
      <StyledMapContainer>
        <Map
          center={coordinates}
          style={{ width: '100%', height: '100%' }}
          level={3}
        >
          <MapMarker position={coordinates} />
        </Map>
      </StyledMapContainer>
    </Modal>
  );
};

const StyledMapContainer = styled.div`
  width: 100%;
  height: 600px;
`;

export default MapModal;
