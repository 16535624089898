import { Button, Modal } from '@successmode-ktp/kui';
import { ReactNode, useState } from 'react';
import styled from 'styled-components';
import DropdownSection from './DropdownSection';
import DateSelection from './DateSelection';
import { format } from 'date-fns';
import { comma } from '@/utils/ktp';
import { changeNonDepartureStatus } from '@/api/medical';
import { useMutation, useQueryClient } from 'react-query';
import { NonDepartureStatusOptions } from '@/constants/medical';

type Props = {
  children: ReactNode | ReactNode[];
  ids: number[];
  refunded: number;
  onUnCheck: () => void;
};

export type StatusType = {
  label: string;
  value: 'RETURN_IN_PROGRESS' | 'RETURN_COMPLETED';
};

const ChangeStatusSection = ({ children, ids, refunded, onUnCheck }: Props) => {
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [isOpenComplete, setIsOpenComplete] = useState(false);
  const [isOpenFail, setIsOpenFail] = useState(false);
  const [status, setStatus] = useState<StatusType>(
    NonDepartureStatusOptions[0] as StatusType,
  );
  const [date, setDate] = useState(format(new Date(), 'yyyy-MM-dd'));

  const queryClient = useQueryClient();
  const { mutate: mutateModify } = useMutation(changeNonDepartureStatus);
  const onModify = () => {
    mutateModify(
      { refundIds: ids, returnStatus: status.value, returnDate: date },
      {
        onSuccess: () => {
          setIsOpenComplete(true);
          onUnCheck();
          queryClient.invalidateQueries({
            queryKey: 'nonDepartureDetail',
          });
        },
        onError: () => {
          setIsOpenFail(true);
        },
      },
    );
  };

  return (
    <>
      <Button
        type='button'
        variant='line'
        onClick={() => setIsOpenModal(true)}
        disabled={refunded === 0}
      >
        {children}
      </Button>
      {isOpenModal && (
        <Modal isOpen={isOpenModal} hasBackdrop order={1}>
          <Modal.Header>
            <Title>상태 변경</Title>
          </Modal.Header>
          <Content>
            <DataList>
              <DataItem>
                <DataTitle>선택 :</DataTitle>
                <Data>{comma(ids.length)}건</Data>
              </DataItem>
              <DataItem>
                <DataTitle>환불 금액 : </DataTitle>
                <DataItem>{comma(refunded)}원</DataItem>
              </DataItem>
            </DataList>
            <Selection>
              <DropdownSection
                label='상태 선택'
                status={status}
                onChange={(val) =>
                  setStatus(
                    NonDepartureStatusOptions.find(
                      (o) => o.value === val,
                    ) as StatusType,
                  )
                }
              />
              <DateSelection
                label='날짜 선택'
                value={date}
                onChange={setDate}
              />
            </Selection>
          </Content>
          <Modal.Footer>
            <Modal.Button
              variant='cancel'
              onClick={() => setIsOpenModal(false)}
            >
              닫기
            </Modal.Button>
            <Button onClick={onModify}>변경</Button>
          </Modal.Footer>
        </Modal>
      )}

      <Modal.Confirm
        isOpen={isOpenComplete}
        state='success'
        title='변경 완료'
        message='상태값이 변경 완료되었습니다.'
        onClick={() => {
          setIsOpenComplete(false);
          setIsOpenModal(false);
        }}
        order={2}
      />
      <Modal.Confirm
        isOpen={isOpenFail}
        state='fail'
        title='변경 실패'
        message='상태값을 변경하는 데 실패하였습니다.'
        onClick={() => {
          setIsOpenFail(false);
        }}
        order={2}
      />
    </>
  );
};

export default ChangeStatusSection;

const Title = styled.h3`
  font-size: 21px;
  font-weight: 700;
  margin-bottom: 24px;
`;

const Content = styled.div`
  width: 436px;
  padding-bottom: 16px;
`;

const DataList = styled.dl`
  margin: 0 0 24px 0;
  padding: 12px;
  background-color: #f5f6f7;
  border-radius: 4px;
  font-size: 12px;
  line-height: 18px;
  color: #5f6165;
`;

const DataItem = styled.div`
  display: flex;
`;

const DataTitle = styled.dt`
  margin-right: 4px;
`;

const Data = styled.dd`
  margin: 0;
`;

const Selection = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

const CompleteTitle = styled.title`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 12px;
`;

const CompleteMessage = styled.p`
  margin: 12px 0 0 0;
  font-size: 14px;
  line-height: 21px;
  color: var(--Mono-65);
  width: 336px;
`;
