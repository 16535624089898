import { format } from 'date-fns';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import AutoResizeDiv from '../../common/AutoResizeDiv';
import Text from '../../common/Text';

function Preview({
  state,
  mainImg,
  subImg1,
  subImg2,
  subImg3,
  isEditMode = false,
}) {
  const {
    title,
    subTitle1,
    subTitle2,
    subTitle3,
    content1,
    content2,
    content3,
    link,
  } = state;
  const [imgSrcList, setImgSrcList] = useState({
    mainImgSrc: null,
    subImg1Src: null,
    subImg2Src: null,
    subImg3Src: null,
  });
  const encodeFileToBase64 = (fileBlob, stateName) => {
    const reader = new FileReader();
    reader.onload = () => {
      setImgSrcList({ ...imgSrcList, [stateName]: reader.result });
    };
    reader.readAsDataURL(fileBlob);
  };

  const encodeFileIfNeeded = (file, name) => {
    if (!file) {
      setImgSrcList({ ...imgSrcList, [name]: null });
      return;
    }
    encodeFileToBase64(file, name);
  };

  useEffect(() => {
    typeof mainImg === 'object' && encodeFileIfNeeded(mainImg, 'mainImgSrc');
  }, [mainImg]);
  useEffect(() => {
    typeof subImg1 === 'object' && encodeFileIfNeeded(subImg1, 'subImg1Src');
  }, [subImg1]);
  useEffect(() => {
    typeof subImg2 === 'object' && encodeFileIfNeeded(subImg2, 'subImg2Src');
  }, [subImg2]);
  useEffect(() => {
    typeof subImg3 === 'object' && encodeFileIfNeeded(subImg3, 'subImg3Src');
  }, [subImg3]);

  useEffect(() => {
    isEditMode &&
      setImgSrcList({
        mainImgSrc: mainImg,
        subImg1Src: subImg1,
        subImg2Src: subImg2,
        subImg3Src: subImg3,
      });
  }, [mainImg, subImg1, subImg2, subImg3]);

  return (
    <PreviewContainer>
      <PhoneLayout>
        {title && (
          <Text text={title} size={18} lineHeight={26} margin='0 0 4px' />
        )}
        {title && (
          <Text
            size={14}
            lineHeight={32}
            text={format(new Date(), 'yyyy.MM.dd')}
            color='#9FA1A7'
            margin='0 0 20px'
            style={{
              borderBottom: '1px solid #EDEEEF',
            }}
          />
        )}
        {imgSrcList.mainImgSrc && (
          <Image src={imgSrcList.mainImgSrc} alt='main-img-preview' />
        )}
        {subTitle1 && <SubTitle>{subTitle1}</SubTitle>}
        {imgSrcList.subImg1Src && (
          <Image src={imgSrcList.subImg1Src} alt='sub-img1-preview' />
        )}
        {content1 && <AutoResizeDiv content={content1} />}
        {subTitle2 && <SubTitle>{subTitle2}</SubTitle>}
        {imgSrcList.subImg2Src && (
          <Image src={imgSrcList.subImg2Src} alt='sub-img2-preview' />
        )}
        {content2 && <AutoResizeDiv content={content2} />}
        {subTitle3 && <SubTitle>{subTitle3}</SubTitle>}
        {imgSrcList.subImg3Src && (
          <Image src={imgSrcList.subImg3Src} alt='sub-img3-preview' />
        )}
        {content3 && <AutoResizeDiv content={content3} />}
        {link !== 'NONE' && (
          <LinkBox>
            <LinkText>
              [현행{' '}
              {link === 'PERSONAL_TYPE'
                ? '개인정보 처리방침'
                : link === 'SERVICE_TYPE'
                  ? '서비스 이용 약관'
                  : link === 'POINT_TYPE'
                    ? 'T.POINT 이용 약관'
                    : 'CMS 출금 약관'}{' '}
              보기]
            </LinkText>
            <LinkText>
              [개정{' '}
              {link === 'PERSONAL_TYPE'
                ? '개인정보 처리방침'
                : link === 'SERVICE_TYPE'
                  ? '서비스 이용 약관'
                  : link === 'POINT_TYPE'
                    ? 'T.POINT 이용 약관'
                    : 'CMS 출금 약관'}{' '}
              보기]
            </LinkText>
          </LinkBox>
        )}
      </PhoneLayout>
    </PreviewContainer>
  );
}
const PreviewContainer = styled.div`
  display: flex;
  flex: 2;
  flex-direction: column;
  background-color: lightgray;
  height: 690px;
`;
const PhoneLayout = styled.div`
  border-radius: 20px;
  margin: 30px auto;
  padding: 30px 20px;
  width: 350px;
  background-color: white;
  height: 100%;
  overflow-y: scroll;
`;
const Image = styled.img`
  width: 100%;
  height: 250px;
  border-radius: 12px;
  margin-bottom: 20px;
`;
const SubTitle = styled.p`
  font-size: 16px;
  font-weight: 500;
  margin: 0 0 20px;
`;
const LinkBox = styled.div`
  margin-bottom: 50px;
`;
const LinkText = styled.p`
  font-size: 14px;
  margin-bottom: 12px;
  color: #005f83;
  text-decoration: underline;
  cursor: pointer;
`;
export default Preview;
