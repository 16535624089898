import { useEffect, useState } from 'react';
import styled from 'styled-components';
import PreviewModal from './Preview.modal';
import DeleteModal from './Delete.modal';
import { useMutation, useQueryClient } from 'react-query';
import { activeAd } from '@/api/ad';
import { Ad } from '@/types/ad.type';
import { Icon } from '@successmode-ktp/kui';

type Props = {
  index: number;
  data: Ad;
  isActiveList?: boolean;
};

const BannerItem = ({
  index,
  data,
  isActiveList = true,
  ...props
}: Props & unknown) => {
  const {
    activated,
    advertisementId,
    popUpUrl,
    footerUrl,
    webPageUrl,
    createdDate,
  } = data;
  const [isActive, setIsActive] = useState(false);
  const [isOpenDeleteModal, setIsOpenDeleteModal] = useState(false);
  const [isOpenPreview, setIsOpenPreview] = useState(false);
  const queryClient = useQueryClient();

  useEffect(() => {
    setIsActive(activated);
  }, [activated, data]);

  const { mutate: changeToggleAd } = useMutation(
    () => activeAd({ advertisementId, activate: !isActive }),
    {
      onSuccess: () => {
        setIsActive(!isActive);
        setTimeout(() => {
          queryClient.invalidateQueries('getAdList');
        }, 400);
      },
      onError: (error: any) => {
        console.error(error);
      },
    },
  );

  const onTogglePreviewModal = () => {
    setIsOpenPreview((prev) => !prev);
  };

  const onToggleDeleteModal = () => {
    setIsOpenDeleteModal((prev) => !prev);
  };

  const cutFileName = (fileName: string) => {
    if (!fileName) return '';
    const arr = fileName.split('/');
    return arr[arr.length - 1];
  };

  return (
    <>
      <Row>
        <Cell isNew={false}>{index + 1}</Cell>
        <Cell flex='5' leftArrange>
          {cutFileName(footerUrl)}
          <br />
          {cutFileName(popUpUrl) || '팝업 배너 없음'}
          <br />
          {webPageUrl || '연결 링크 없음'}
        </Cell>
        <Cell>{createdDate.split('T')[0]}</Cell>
        <Cell flex='2'>
          <Button onClick={onTogglePreviewModal}>
            <Icon.Video size={16} fillColor='#246cf6' /> 미리보기
          </Button>
        </Cell>
        <Cell>
          <ToggleBtn active={isActive} onClick={() => changeToggleAd()}>
            {isActive ? (
              <Icon.Play
                size={24}
                fillColor='#7CA7FA'
                style={{
                  padding: '3px',
                  background: '#fff',
                  borderRadius: '50%',
                  transform: 'translateX(0px)',
                }}
              />
            ) : (
              <Icon.Pause
                size={24}
                fillColor='#E5E6E8'
                style={{
                  padding: '3px',
                  background: '#fff',
                  borderRadius: '50%',
                  transform: 'translateX(24px)',
                  transition: '300ms all ease-in',
                }}
              />
            )}
          </ToggleBtn>
        </Cell>
        <Cell>
          {!isActive && (
            <Button onClick={onToggleDeleteModal}>
              <Icon.Trash size={16} fillColor='#CBCCCE' />
            </Button>
          )}
        </Cell>
        <Cell {...props}>{isActiveList && <Icon.Menu size={20} />}</Cell>
      </Row>
      {isOpenPreview && (
        <PreviewModal
          popUpUrl={popUpUrl}
          footerUrl={footerUrl}
          webPageUrl={webPageUrl}
          onClose={onTogglePreviewModal}
        />
      )}
      {isOpenDeleteModal && (
        <DeleteModal
          id={advertisementId}
          popUpName={cutFileName(popUpUrl)}
          footerName={cutFileName(footerUrl)}
          webPageUrl={webPageUrl}
          onClose={onToggleDeleteModal}
        />
      )}
    </>
  );
};

export default BannerItem;

const Row = styled.div`
  width: 100%;
  display: flex;
  background-color: #ffffff;
  border-bottom: 1px solid #e5e6e8;
`;

const Cell = styled.div<{
  flex?: string;
  leftArrange?: boolean;
  isNew?: boolean;
}>`
  display: flex;
  flex: ${(props) => props.flex || 1};
  align-items: center;
  padding: 0 20px;
  height: 70px;
  justify-content: ${(props) => (props.leftArrange ? 'flex-start' : 'center')};
  color: ${(props) => (props.isNew ? '#FF2D55' : '#3A3B3E')};
  font-weight: 500;
  white-space: nowrap;
`;

const Button = styled.button`
  font-size: 14px;
  color: #246cf6;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 4px;
`;

const ToggleBtn = styled.button<{ active: boolean }>`
  width: 56px;
  height: 32px;
  border-radius: 100px;
  background-color: ${(props) => (props.active ? '#5089F8' : '#E5E6E8')};
  padding: 4px;
  display: flex;
  margin: 0 auto;
`;

const IconStyle = styled.img<{ disabled?: boolean }>`
  width: 16px;
  height: 16px;
  margin-bottom: 2px;
  opacity: ${(props) => (props.disabled ? 0.2 : 1)};
`;

const Controller = styled.img<{ active: boolean }>`
  background-color: #ffff;
  width: 24px;
  height: 24px;
  padding: 3px;
  border-radius: 50%;
  transform: ${(props) =>
    props.active ? 'translateX(24px)' : 'translateX(0)'};
  transition: 300ms all ease-in;
`;
