import React, { useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import ChevronIcon from '../../assets/common/chevron.png';
import ChevronDisabledIcon from '../../assets/common/chevron_disabled.png';
import ChevronDoubleIcon from '../../assets/common/chevron_double.png';
import ChevronDoubleDisabledIcon from '../../assets/common/chevron_double_disabled.png';
import useUpdateUrlParams from '@/hooks/sales/useUpdateUrlParams';

type Props = {
  initialPage: string;
  totalPage: number;
  isLastPage: boolean;
  isFirstPage: boolean;
  displaySize?: number;
};

function Pagination({
  initialPage,
  totalPage,
  isLastPage,
  isFirstPage,
  displaySize = 5,
}: Props) {
  const updateUrlParams = useUpdateUrlParams();
  const [currentPage, setCurrentPage] = useState(
    initialPage === '0' ? 1 : Number(initialPage) + 1,
  );

  const onMove = (page: number) => {
    updateUrlParams({ page: (page - 1).toString() });
  };

  const startPage = useMemo(() => {
    const currentBlock = Math.floor((currentPage - 1) / displaySize);
    return currentBlock * displaySize + 1;
  }, [currentPage, displaySize]);
  const endPage = useMemo(
    () => Math.min(startPage + displaySize - 1, totalPage),
    [startPage, totalPage, displaySize],
  );

  useEffect(
    () => setCurrentPage(initialPage === '0' ? 1 : Number(initialPage) + 1),
    [initialPage],
  );

  return (
    <PageButtons>
      <PageButton
        onClick={() => {
          const page = 1;
          setCurrentPage(page);
          onMove(page);
        }}
        disabled={isFirstPage}
      >
        {!isFirstPage ? (
          <ChevronButton
            src={ChevronDoubleIcon}
            alt='첫 번째 페이지로'
            style={{ transform: 'rotate(180deg)' }}
          />
        ) : (
          <ChevronButton src={ChevronDoubleDisabledIcon} alt='' aria-hidden />
        )}
      </PageButton>
      <PageButton
        onClick={() => {
          const page = currentPage - 1;
          setCurrentPage(page);
          onMove(page);
        }}
        disabled={isFirstPage}
      >
        {!isFirstPage ? (
          <ChevronButton
            src={ChevronIcon}
            alt='페이지 이전으로'
            style={{ transform: 'rotate(180deg)' }}
          />
        ) : (
          <ChevronButton src={ChevronDisabledIcon} alt='' aria-hidden />
        )}
      </PageButton>

      <NumberButton>
        {Array.from({ length: endPage - startPage + 1 }, (_, index) => {
          const page = startPage + index;
          return (
            <PageButton
              key={page}
              isCurrentPage={page === currentPage}
              onClick={() => {
                setCurrentPage(page);
                onMove(page);
              }}
              disabled={page === currentPage}
            >
              {page}
            </PageButton>
          );
        })}
      </NumberButton>

      <PageButton
        onClick={() => {
          const page = currentPage + 1;
          setCurrentPage(page);
          onMove(page);
        }}
        disabled={isLastPage}
      >
        {!isLastPage ? (
          <ChevronButton src={ChevronIcon} alt='페이지 다음으로' />
        ) : (
          <ChevronButton
            src={ChevronDisabledIcon}
            alt=''
            aria-hidden
            style={{ transform: 'rotate(180deg)' }}
          />
        )}
      </PageButton>
      <PageButton
        onClick={() => {
          const page = totalPage;
          setCurrentPage(page);
          onMove(page);
        }}
        disabled={isLastPage}
      >
        {!isLastPage ? (
          <ChevronButton src={ChevronDoubleIcon} alt='마지막 페이지로' />
        ) : (
          <ChevronButton
            src={ChevronDoubleDisabledIcon}
            alt=''
            aria-hidden
            style={{ transform: 'rotate(180deg)' }}
          />
        )}
      </PageButton>
    </PageButtons>
  );
}

const PageButtons = styled.footer`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 6px;
`;

const PageButton = styled.button<{ isCurrentPage?: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 26px;
  height: 26px;
  font-size: 14px;
  font-weight: 500;
  color: ${({ isCurrentPage = false }) =>
    isCurrentPage ? '#212121' : '#80848A'};
`;

const NumberButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 36px;
`;

const ChevronButton = styled.img`
  width: 18px;
  height: 18px;
`;

export default Pagination;
