import Portal from '@/components/common/Portal';
import React from 'react';
import styled from 'styled-components';
import WarningIcon from '@/assets/common/badge_warning.png';
import SuccessIcon from '@/assets/common/badge_success.png';

type Props = {
  onClose: () => void;
  isError?: boolean;
};

function RequestTicketModal({ onClose, isError = false }: Props) {
  return (
    <Portal isOpaqueBackdrop zIndex={150}>
      <Popover>
        <Header>
          <img src={isError ? WarningIcon : SuccessIcon} alt='' width={24} />
          <Title>{isError ? '메일 전송 실패' : '메일 전송 완료'}</Title>
        </Header>
        <Content>
          {isError
            ? '네트워크 오류로 메일 전송에 실패하였습니다.'
            : '항공권 재확인 요청 메일 전송되었습니다.'}
        </Content>
        <Button onClick={onClose}>확인</Button>
      </Popover>
    </Portal>
  );
}

const Popover = styled.aside`
  display: flex;
  flex-direction: column;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 32px;
  width: 400px;
  background-color: #ffffff;
  border-radius: 16px;
  box-shadow: 0px 0px 30px 0px #00000014;
  z-index: 150;
`;

const Header = styled.header`
  display: flex;
  width: 100%;
  justify-content: flex-start;
  gap: 12px;
  align-items: center;
  margin-bottom: 12px;
`;

const Title = styled.h3`
  font-size: 21px;
  font-weight: 700;
  color: #030303;
`;

const Button = styled.button`
  width: 100%;
  height: 52px;
  border-radius: 8px;
  font-size: 16px;
  font-weight: 600;
  background-color: #246cf6;
  color: #ffffff;
`;

const Content = styled.p`
  font-size: 14px;
  font-weight: 500;
  color: #5f6165;
  margin-bottom: 24px;
`;

export default RequestTicketModal;
