import CustomTableCell from '@/components/common/CustomTableCell';
import { ILatestRefundInfoItem } from '@/types/api/medical';
import { comma } from '@/utils/ktp';
import { TableRow } from '@material-ui/core';
import { format } from 'date-fns';
import styled from 'styled-components';

interface IProps {
  data: ILatestRefundInfoItem;
}
function LatestRefundList({ data }: IProps) {
  const {
    refundIndex,
    createdDate,
    nation,
    hospitalName,
    totalPrice,
    refund,
    refundStatus,
  } = data;
  return (
    <StyledTableRow>
      <CustomTableCell idx={0} width='90'>
        {refundIndex}
      </CustomTableCell>
      <CustomTableCell width='170'>
        {format(new Date(createdDate), 'yyyy.MM.dd HH:mm')}
      </CustomTableCell>
      <CustomTableCell width='100'>{nation}</CustomTableCell>
      <CustomTableCell width='170'>{hospitalName}</CustomTableCell>
      <CustomTableCell width='240'>{comma(totalPrice)}원</CustomTableCell>
      <CustomTableCell width='240'>{comma(refund)}원</CustomTableCell>
      <CustomTableCell width='240'>
        {refundStatus === 'APPROVE_CONFIRM'
          ? '환급 완료'
          : refundStatus === 'CANCEL'
            ? '환급 취소'
            : '환급 미완료'}
      </CustomTableCell>
    </StyledTableRow>
  );
}
const StyledTableRow = styled(TableRow)`
  background-color: #ffffff;
  :hover {
    background-color: #f7f9fc;
  }
`;
export default LatestRefundList;
