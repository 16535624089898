import {
  BillType,
  KioskDetailRemainingInfo,
  KioskErrorStatusType,
  KioskLackStatusType,
  KioskStatusType,
  KioskTrendInfo,
  WidrawalDetailRefundInfoType,
} from '@/types/api/kiosk';
import {
  formatKioskCustomerInfoResponse,
  formatKioskCustomerInfoRaw,
} from '@/types/kiosk.type';

export const comma = (x: any) => {
  return String(x).replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};
// 키오스크 에러상태 처리
// 에러처리 우선순위: 현금보충 > 에러코드 > 정상
export const determineKioskStatus = (
  billType: BillType,
  kioskStatus: KioskStatusType,
  lackStatus: KioskLackStatusType,
) => {
  const { bd1Status, bd2Status, bd3Status, bd4Status, hp1Status, rtStatus } =
    kioskStatus;
  const { bd1Lack, bd2Lack, bd3Lack, bd4Lack, hp1Lack, rtLack } = lackStatus;

  const result = { cashAlert: '', message: '정상', color: '' };
  // 현금보충 확인
  if (
    (billType.bd1 && bd1Lack) ||
    (billType.bd2 && bd2Lack) ||
    (billType.bd3 && bd3Lack) ||
    (billType.bd4 && bd4Lack) ||
    (billType.hp1 && hp1Lack) ||
    (billType.rt && rtLack)
  ) {
    result.cashAlert = '현금 보충 필요';
    result.color = '#FF2D55';
  }

  // // 에러코드 확인(지폐)
  const bill = formatBillType(
    billType[bd1Status ? 'bd1' : bd2Status ? 'bd2' : 'bd3'],
  );
  const commonErrorMessages: Record<number | string, string> = {
    a0: 'ER14 접힌지폐',
    20: 'ER02 통로걸림',
    30: 'ER03 지폐겹쳐/미방출',
    40: 'ER03 지폐겹쳐/미방출',
    50: 'ER04 길이비정상',
    60: 'ER04 길이비정상',
    70: 'ER05 적재불량',
    10: 'ER06 지폐부족',
    80: 'ER07 기기고장-업체연락필요',
    90: 'ER07 기기고장-업체연락필요',
  };

  const getErrorMessage = (statusCode: number | string | null) => {
    if (!statusCode) return '';
    const errorText = commonErrorMessages[statusCode.toString(16)];
    return errorText ? `에러 발생(${bill} ${errorText})` : '';
  };
  const bd1ErrorMessage = getErrorMessage(bd1Status);
  const bd2ErrorMessage = getErrorMessage(bd2Status);
  const bd3ErrorMessage = getErrorMessage(bd3Status);
  const bd4ErrorMessage = getErrorMessage(bd4Status);
  if (
    bd1ErrorMessage ||
    bd2ErrorMessage ||
    bd3ErrorMessage ||
    bd4ErrorMessage
  ) {
    const errorMessage =
      bd1ErrorMessage || bd2ErrorMessage || bd3ErrorMessage || bd4ErrorMessage;
    result.message = errorMessage;
    result.color = '#FF2D55';
  }
  // 에러코드 확인(동전)
  if (hp1Status) {
    result.message = '에러 발생(ER10 동전 방출기에러)';
    result.color = '#FF2D55';
  }
  // 에러코드 확인(불량지폐)
  if (rtStatus) {
    result.message = '에러 발생(불량지폐 적재불가)';
    result.color = '#FF2D55';
  }
  // 정상
  return result;
};
export const formatBillType = (price: number | null) => {
  switch (price) {
    case 50000:
      return '5만원권';
    case 10000:
      return '만원권';
    case 5000:
      return '5천원권';
    case 1000:
      return '천원권';
    case 500:
      return '오백원';
    default:
      return '미사용';
  }
};
export const getKioskDetailCashBalance = (billType: BillType) => {
  return {
    columns: [
      { type: 'createdDate', title: '수정 일자' },
      { type: 'managerName', title: '담당자' },
      { type: 'count', title: `BD1-${formatBillType(billType.bd1)}` },
      { type: 'count', title: `BD2-${formatBillType(billType.bd2)}` },
      { type: 'count', title: `BD3-${formatBillType(billType.bd3)}` },
      { type: 'count', title: `BD4-${formatBillType(billType.bd4)}` },
      { type: 'count', title: `HP1-${formatBillType(billType.hp1)}` },
      { type: 'count', title: `RT-${billType.rt ? '불량지폐' : '미사용'}` },
      { type: 'delete', title: `` },
    ],
  };
};
export const getKioskDetailWithdrawalInfo = (billType: BillType) => {
  return {
    columns: [
      { type: 'index', title: 'No.' },
      { type: 'createdDate', title: '환급일자' },
      { type: 'refund', title: '환급액' },
      { type: 'count', title: `BD1-${formatBillType(billType?.bd1)}` },
      { type: 'count', title: `BD2-${formatBillType(billType?.bd2)}` },
      { type: 'count', title: `BD3-${formatBillType(billType?.bd3)}` },
      { type: 'count', title: `BD4-${formatBillType(billType?.bd4)}` },
      { type: 'count', title: `HP1-${formatBillType(billType?.hp1)}` },
      { type: 'count', title: `RT-${billType.rt ? '불량지폐' : '미사용'}` },
    ],
  };
};
export const getKioskErrorLogInfo = () => {
  return {
    columns: [
      { type: 'createdDate', title: '에러 발생일' },
      { type: 'errorCode', title: '에러 코드' },
      { type: 'errorDescription', title: '에러 내용' },
      { type: 'errorStatus', title: '상태' },
    ],
  };
};

export const formatKioskErrorStatus = (
  status: KioskErrorStatusType,
): string => {
  const errorStatus = {
    COMPLETED_KTP: '조치 완료',
    COMPLETED_HOSPITAL: '조치 완료',
    COMPLETED_FRANCHISEE: '조치 완료',
    NOT_COMPLETED: '미조치',
    OTHERS: '기타 에러',
  };

  if (!status) return '-';

  return errorStatus[status];
};

export const formatKioskErrorCode = (
  errorCode: [number, number, number, boolean],
) => {
  const result: string[] = [];
  const commonErrorMessages: Record<number | string, string> = {
    a0: 'ER14',
    20: 'ER02',
    30: 'ER03',
    40: 'ER03',
    50: 'ER04',
    60: 'ER04',
    70: 'ER05',
    10: 'ER06',
    80: 'ER07',
    90: 'ER07',
  };

  for (const code of errorCode) {
    if (code === true) {
      result.push('ER10');
    } else if (typeof code === 'number' && code !== 0) {
      const hexCode = code.toString(16);
      result.push(commonErrorMessages[hexCode] || '-');
    }
  }

  return result.join(', ') || '-';
};

export const formatKioskErrorMessage = (
  errorCode: [number, number, number, boolean],
) => {
  const result: string[] = [];
  const commonErrorMessages: Record<number | string, string> = {
    a0: '접힌지폐',
    20: '통로걸림',
    30: '지폐겹쳐/미방출',
    40: '지폐겹쳐/미방출',
    50: '길이비정상',
    60: '길이비정상',
    70: '적재불량',
    10: '지폐부족',
    80: '기기고장-업체연락필요',
    90: '기기고장-업체연락필요',
  };

  for (const code of errorCode) {
    if (code === true) {
      result.push('동전 방출기에러');
    } else if (typeof code === 'number' && code !== 0) {
      const hexCode = code.toString(16);
      result.push(commonErrorMessages[hexCode] || '-');
    }
  }

  return result.join(', ') || '-';
};

export const getServiceType = (num: number) => {
  return [
    '쌍커풀 수술',
    '코 성형 수술',
    '유방 수술',
    '지방 흡입술',
    '주름살 제거술',
    '안면 윤곽술',
    '치아 성형술',
    '악안면 교정술',
    '색소 모반/주근깨/흑색점/기미 치료술',
    '여드름 치료술',
    '제모술',
    '탈모 치료/모발이식술',
    '문신/문신 제거/피어싱',
    '지방융해술',
    '피부재생/미백/항노화치료/모공 축소술',
    '기타',
  ][num];
};
export const getFormattedTouristStatus = (type: string) => {
  switch (type) {
    case 'DEPARTURE_WAIT':
      return '출국 미확인';
    case 'DEPARTURE_COMPLETE':
      return '출국 확인';
    default:
      return '-';
  }
};
export const formatKioskCustomerInfo = (
  raw: formatKioskCustomerInfoRaw,
): formatKioskCustomerInfoResponse[] => {
  const { name, nation, passportNumber, birthDate } = raw;
  return [
    {
      label: '이름',
      option: {
        name: 'name',
        value: name,
      },
    },
    {
      label: '여권번호',
      option: {
        name: 'passportNumber',
        value: passportNumber,
      },
    },
    {
      label: '국적',
      option: {
        name: 'nation',
        value: nation,
      },
    },
    {
      label: '생년월일',
      option: {
        name: 'birthDate',
        value: birthDate,
      },
    },
  ];
};
export const formatKioskRefundInfo = (
  items: WidrawalDetailRefundInfoType[],
) => {
  return items.map(
    ({ refundIndex, storeName, createdDate, totalPrice, refund }) => [
      {
        label: 'No',
        option: {
          name: 'refundIndex',
          value: refundIndex.toString(),
        },
      },
      {
        label: '상호명',
        option: {
          name: 'storeName',
          value: storeName,
        },
      },
      {
        label: '판매금액',
        option: {
          name: 'totalPrice',
          value: totalPrice,
        },
      },
      {
        label: '환급액',
        option: {
          name: 'refund',
          value: refund,
        },
      },
      {
        label: '환급일',
        option: {
          name: 'createdDate',
          value: createdDate,
        },
      },
    ],
  );
};

export const formatKioskFranchiseeStatus = (type: string) => {
  switch (type) {
    case 'K':
      return '매장';
    case 'M':
      return '의료';
    case 'USER':
      return '';
    case 'OWNER':
      return '내부';
    default:
      return '-';
  }
};

/** totalCount format 함수 */
export function formatKioskTotalCount(
  type: string,
  remainingInfo: KioskDetailRemainingInfo,
): number {
  switch (type) {
    case 'bd1':
      return remainingInfo.bd1TotalCount;
    case 'bd2':
      return remainingInfo.bd2TotalCount;
    case 'bd3':
      return remainingInfo.bd3TotalCount;
    case 'hp1':
      return remainingInfo.hp1TotalCount;
    default:
      return 0;
  }
}

/** trendInfo format 함수 */
export function formatKioskTrendInfo(type: string, trendInfo?: KioskTrendInfo) {
  switch (type) {
    case 'bd1':
      return trendInfo?.bd1TrendInfo;
    case 'bd2':
      return trendInfo?.bd2TrendInfo;
    case 'bd3':
      return trendInfo?.bd3TrendInfo;
    case 'bd4':
      return trendInfo?.bd4TrendInfo;
    case 'hp1':
      return trendInfo?.hp1TrendInfo;
    default:
      return null;
  }
}
