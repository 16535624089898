import React from 'react';
import styled from 'styled-components';
import DaumPostcodeEmbed from 'react-daum-postcode';
import Modal from '@/components/common/Modal';

type Props = {
  onChange: (value: string) => void;
  onClose: () => void;
};

function AddressSearchModal({ onChange, onClose }: Props) {
  const handleComplete = (data: any) => {
    onChange(data.address);
    onClose();
  };

  return (
    <Modal onClose={onClose}>
      <AddressModal onComplete={handleComplete} />
    </Modal>
  );
}

const AddressModal = styled(DaumPostcodeEmbed)`
  height: 100%;
`;

export default AddressSearchModal;
